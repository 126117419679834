import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

const SelectComponent = (props) => {
   /*
    const [select, setSelect] = React.useState(props.default? props.default:'');

    const handleChange = (event) => {
        setSelect(event.target.value);
    };
    */
    const handleChange = (e) => {
        props.setEstado({...props.estado, valor: e.target.value})
    }

    const handleClearClick = () => {
        props.setEstado({...props.estado, valor: ""})
    };

    return (
        <Box sx={props.sx}>
            <FormControl>
                <InputLabel id="demo-simple-select-label" required={props.required}>{props.label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.estado.valor}
                    label={props.label}
                    onChange={handleChange}
                    disabled={props.disabled ? props.disabled : false}
                    sx={{"& .MuiSelect-iconOutlined": {display: props.estado.valor? 'none': ''}, "&.Mui-focused .MuiIconButton-root": {color: 'primary.main'}}}
                    endAdornment={<IconButton sx={{visibility: props.estado.valor? "visible": "hidden"}} disabled={props.disabled ? props.disabled : false} onClick={handleClearClick}><ClearIcon fontSize={"small"}/></IconButton>}
                >

                    {props.items.map(function(object, i){
                        return <MenuItem key={i} value={object.id}> {object.descripcion} </MenuItem>;
                     })
                    }

                </Select>
            </FormControl>
        </Box>
    );
};

export default SelectComponent;