import {configureStore} from "@reduxjs/toolkit";
import clientes from "./slices/cliente.slice";
import autenticacion from "./slices/autenticacion.slice";
import mensaje from "./slices/snackbar.slice";
import genericos from "./slices/generico.slice"
import prestamos from "./slices/prestamo.slice"
import pagos from "./slices/pago.slice"
import buscar from "./slices/buscar.slice"
import dashboard from "./slices/dashboard.slice"
import formasPagos from "./slices/forma.pago.slice"
import metodosPagos from "./slices/metodo.pago.slice"
import comunicacion from "./slices/comunicacion.slice"
import reportes from "./slices/reporte.slice"
export default configureStore({
    reducer:{
        clientes,
        autenticacion,
        mensaje,
        genericos,
        prestamos,
        pagos,
        buscar,
        dashboard,
        formasPagos,
        metodosPagos,
        comunicacion,
        reportes,
    }
});

