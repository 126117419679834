import axiosPrivate, {axiosError} from "../api/axios";
import authHeader from "./auth-header";

const fetchAllClientes = () => {
    const path = "/clientes/obtenerTodos"
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path,config )
        .then((response) => {
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

const addClientePersona = (clienteNuevo) =>{
    const path = "/clientes/personas/crear"
    const config = {
        headers: authHeader()
    };

    return axiosPrivate.post(path,clienteNuevo, config)
        .then((response) => {
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
};

const modificarClientePersona = (cliente) =>{
    const path = "/clientes/personas/modificar"
    const config = {
        headers: authHeader()
    };

    return axiosPrivate.put(path,cliente, config)
        .then((response) => {
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
};

const fetchClientePersona = (id) => {
    const path = "/clientes/obtenerFull";
    const params= "?idCliente="+id;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

const bloquearCliente = (clienteBloquear) => {
    const path = "/clientes/bloquear";
    const params= "?idCliente="+clienteBloquear.idCliente+"&descripcion";
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.put(path+params,clienteBloquear,config)
        .then((response) => {
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

const desbloquearCliente = (clienteDesbloquear) => {
    const path = "/clientes/desbloquear";
    const params= "?idCliente="+clienteDesbloquear.idCliente+"&descripcion";
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.put(path+params, clienteDesbloquear,config )
        .then((response) => {
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

const fetchClientePrestamos = (idCliente) => {
    const path = "/clientes/prestamos/obtener"
    const params= "?idCliente="+idCliente;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
const fetchAllClientesPrestamosActivos = () => {
    const path = "/clientes/con_prestamos_activos"
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path,config )
        .then((response) => {
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

export default {
    fetchAllClientes,
    addClientePersona,
    fetchClientePersona,
    bloquearCliente,
    desbloquearCliente,
    fetchClientePrestamos,
    modificarClientePersona,
    fetchAllClientesPrestamosActivos
};

