// material
import { alpha, useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------

export function BaseOptionChartStyle() {
    const theme = useTheme();

    const background = {
        backdropFilter: 'blur(6px)',
        WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
        backgroundColor: alpha(theme.palette.background.default, 0.72)
    };

    return (
        <GlobalStyles
            styles={{
                '&.apexcharts-canvas': {
                    // Tooltip
                    '.apexcharts-xaxistooltip': {
                        ...background,
                        border: 0,
                        boxShadow: theme.customShadows.z24,
                        color: theme.palette.text.primary,
                        borderRadius: Number(theme.shape.borderRadius) * 1.5,
                        '&:before': { borderBottomColor: 'transparent' },
                        '&:after': { borderBottomColor: alpha(theme.palette.background.default, 0.72) }
                    },
                    '.apexcharts-tooltip.apexcharts-theme-light': {
                        ...background,
                        border: 0,
                        boxShadow: theme.customShadows.z24,
                        borderRadius: Number(theme.shape.borderRadius) * 1.5,
                        '& .apexcharts-tooltip-title': {
                            border: 0,
                            textAlign: 'center',
                            fontWeight: theme.typography.fontWeightBold,
                            backgroundColor: theme.palette.grey[500_16],
                            color: theme.palette.text[theme.palette.mode === 'light' ? 'secondary' : 'primary']
                        }
                    },
                    // Legend
                    '.apexcharts-legend': {
                        padding: 0
                    },
                    '.apexcharts-legend-series': {
                        display: 'flex !important',
                        alignItems: 'center'
                    },
                    '.apexcharts-legend-marker': {
                        marginRight: 8
                    },
                    '.apexcharts-legend-text': {
                        lineHeight: '18px',
                        textTransform: 'capitalize'
                    }
                }
            }}
        />
    );
}

export default function BaseOptionChart() {
    const theme = useTheme();

    const LABEL_TOTAL = {
        show: true,
        label: 'Total',
        color: palette.text.secondary,
        ...theme.typography.subtitle2
    };

    const LABEL_VALUE = {
        offsetY: 8,
        color: palette.text.primary,
        ...theme.typography.h3
    };

    return {
        // Colors
        colors: [
            palette.primary.main,
            palette.chart.yellow[0],
            palette.chart.blue[0],
            palette.chart.violet[0],
            palette.chart.green[0],
            palette.chart.red[0]
        ],

        // Chart
        chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            // animations: { enabled: false },
            foreColor: palette.text.disabled,
            fontFamily: theme.typography.fontFamily
        },

        // States
        states: {
            hover: {
                filter: {
                    type: 'lighten',
                    value: 0.04
                }
            },
            active: {
                filter: {
                    type: 'darken',
                    value: 0.88
                }
            }
        },

        // Fill
        fill: {
            opacity: 1,
            gradient: {
                type: 'vertical',
                shadeIntensity: 0,
                opacityFrom: 0.4,
                opacityTo: 0,
                stops: [0, 100]
            }
        },

        // Datalabels
        dataLabels: { enabled: false },

        // Stroke
        stroke: {
            width: 3,
            curve: 'smooth',
            lineCap: 'round'
        },

        // Grid
        grid: {
            strokeDashArray: 3,
            borderColor: palette.divider
        },

        // Xaxis
        xaxis: {
            axisBorder: { show: false },
            axisTicks: { show: false }
        },

        // Markers
        markers: {
            size: 0,
            strokeColors: palette.background.paper
        },

        // Tooltip
        tooltip: {
            x: {
                show: false
            }
        },

        // Legend
        legend: {
            show: true,
            fontSize: 13,
            position: 'top',
            horizontalAlign: 'right',
            markers: {
                radius: 12
            },
            fontWeight: 500,
            itemMargin: { horizontal: 12 },
            labels: {
                colors: palette.text.primary
            }
        },

        // plotOptions
        plotOptions: {
            // Bar
            bar: {
                columnWidth: '28%',
                borderRadius: 4
            },
            // Pie + Donut
            pie: {
                donut: {
                    labels: {
                        show: true,
                        value: LABEL_VALUE,
                        total: LABEL_TOTAL
                    }
                }
            },
            // Radialbar
            radialBar: {
                track: {
                    strokeWidth: '100%',
                    background: palette.grey[500_16]
                },
                dataLabels: {
                    value: LABEL_VALUE,
                    total: LABEL_TOTAL
                }
            },
            // Radar
            radar: {
                polygons: {
                    fill: { colors: ['transparent'] },
                    strokeColors: palette.divider,
                    connectorColors: palette.divider
                }
            },
            // polarArea
            polarArea: {
                rings: {
                    strokeColor: palette.divider
                },
                spokes: {
                    connectorColors: palette.divider
                }
            }
        },

        // Responsive
        responsive: [
            {
                // sm
                breakpoint: theme.breakpoints.values.sm,
                options: {
                    plotOptions: { bar: { columnWidth: '40%' } }
                }
            },
            {
                // md
                breakpoint: theme.breakpoints.values.md,
                options: {
                    plotOptions: { bar: { columnWidth: '32%' } }
                }
            }
        ]
    };
}

function createGradient(color1, color2) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = {
    lighter: '#D1E9FC',
    light: '#76B0F1',
    main: '#2065D1',
    dark: '#103996',
    darker: '#061B64',
    contrastText: '#fff',
};

const SECONDARY = {
    lighter: '#D6E4FF',
    light: '#84A9FF',
    main: '#3366FF',
    dark: '#1939B7',
    darker: '#091A7A',
    contrastText: '#fff',
};

const INFO = {
    lighter: '#D0F2FF',
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    darker: '#04297A',
    contrastText: '#fff',
};

const SUCCESS = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    darker: '#08660D',
    contrastText: GREY[800],
};

const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
    contrastText: GREY[800],
};

const ERROR = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#7A0C2E',
    contrastText: '#fff',
};

const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
    violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
    blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
    green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
    yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
    red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const palette = {
    common: { black: '#000', white: '#fff' },
    primary: { ...PRIMARY },
    secondary: { ...SECONDARY },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    grey: GREY,
    gradients: GRADIENTS,
    chart: CHART_COLORS,
    divider: GREY[500_24],
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: GREY[100], neutral: GREY[200] },
    action: {
        active: GREY[600],
        hover: GREY[500_8],
        selected: GREY[500_16],
        disabled: GREY[500_80],
        disabledBackground: GREY[500_24],
        focus: GREY[500_24],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
};
