import {createSlice} from "@reduxjs/toolkit";
import genericoService from "../../services/generico.service";
import {clearMessage, setearMensaje, setMessage} from "./snackbar.slice";
import {errores} from "../../common/errores";
import {logout} from "./autenticacion.slice";

const initialState = {
    tiposDocList: [],
    paisesList: [],
    departamentosList: [],
    ciudadesList: [],
    monedasList: [],

}
export const genericoSlice = createSlice({
   name: "genericos",
   initialState,
    reducers: {
       setTiposDocList: (state, action) => {
           console.log ("setTiposDocList - State: "+state.tiposDocList)
           console.log("setTiposDocList - payload: "+action.payload)
           state.tiposDocList = action.payload;
       },
        tiposDocError: (state) => {
           state.tiposDocList = [];
        },
        setPaisesList: (state, action) => {
           state.paisesList = action.payload;
        },
        paisesError: (state) => {
           state.paisesList = [];
        },
        setDepartamentosList: (state, action) => {
            state.departamentosList = action.payload;
        },
        departamentosError: (state) => {
            state.departamentosList = [];
        },
        setCiudadesList: (state, action) => {
            state.ciudadesList = action.payload;
        },
        ciudadesError: (state) => {
            state.ciudadesList = [];
        },
        setMonedasList: (state, action) => {
           state.monedasList = action.payload;
        },
        monedasError:(state, action) => {
            state.monedasList = [];
        }
    }
});

const { setTiposDocList, tiposDocError, setPaisesList, paisesError, setDepartamentosList, departamentosError, setCiudadesList, ciudadesError, setMonedasList, monedasError } = genericoSlice.actions;
export default genericoSlice.reducer;

export const getAllTiposDoc = () => (dispatch) => {
    return genericoService.fetchAllTiposDoc().then(
        (data) => {
            dispatch(setTiposDocList(data));
            dispatch(clearMessage());
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("Error en el slice");
            dispatch(tiposDocError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};

export const getAllPaises = () => (dispatch) => {
    return genericoService.fetchAllPaises().then(
        (data) => {
            dispatch(setPaisesList(data));
            dispatch(clearMessage());
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("Error en el slice");
            dispatch(paisesError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const getAllDepartamentos = (idPais) => (dispatch) => {

    return genericoService.fetchAllDepartamentos(idPais).then(
        (data) => {
            dispatch(setDepartamentosList(data));
            dispatch(clearMessage());
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("Error en el slice");
            dispatch(departamentosError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const getAllCiudades = (idDepartamento) => (dispatch) => {
    return genericoService.fetchAllCiudades(idDepartamento).then(
        (data) => {
            dispatch(setCiudadesList(data));
            dispatch(clearMessage());
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("Error en el slice");
            dispatch(ciudadesError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};

export const getAllMonedas = () => (dispatch) => {
    return genericoService.fetchAllMonedas().then(
        (data) => {
            dispatch(setMonedasList(data));
            dispatch(clearMessage());
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("Error en el slice");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};