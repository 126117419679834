import axios from 'axios';
import {errores} from "../common/errores";

const BASE_URL = process.env.REACT_APP_API_URL;

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content_Type": "application/json"
    },
});

export const axiosError = (error) => {
    let err ={
        tipo: null,
        message: "",
    };
    if(error.response){
        //console.log("error en response: "+ error.response.data.mensaje);
        if(error.response.status == 401){
            err.tipo = errores.SESION_EXPIRADA;
            err.message = errores.SESION_EXPIRADA;
        }
        else if (error.response.status == 400){
            err.tipo = errores.BAD_REQUEST;
            err.message = error.response.data.message;
        }
        else if (error.response.status == 403){
            err.tipo = errores.FORBIDDEN;
            err.message = errores.FORBIDDEN;
        }
        else if (error.response.status == 406){
            err.tipo = errores.NOT_ACCEPTABLE;
            err.message = error.response.data.mensaje;
        }
        else if (error.response.status == 405){
            err.tipo = errores.METHOD_NOT_ALLOWED;
            err.message = errores.METHOD_NOT_ALLOWED;
        }
        else{
            err.tipo = errores.ERROR_SERVIDOR;
            err.message = errores.ERROR_SERVIDOR;
        }
    }
    else {
        //console.log("error else: " + error.toJSON());
        err.tipo = errores.ERROR_RED
        err.message = errores.ERROR_RED;
    }

    return err;
};