import React, {useCallback, useEffect, useState} from 'react'
import {Link, Navigate, useHref, useNavigate} from "react-router-dom";
import GridComponent from "../../../../components/grid.component";
import {GridActionsCellItem, GridColDef} from "@mui/x-data-grid";
import PrintIcon from '@mui/icons-material/Print';
import {useDispatch, useSelector} from "react-redux";
import {getAllPagosPrestamo} from "../../../../store/slices/prestamo.slice";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Chip, Fab, Switch} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DatePickerComponent from "../../../../components/date.picker.component";
import SearchIcon from "@mui/icons-material/Search";
import {formatDDMMYYYY, formatHHMMSS} from "../../../../common/fechaFormat";
import {emitirRecibo} from "../../../../store/slices/pago.slice";
import {downloadFile} from "../../../../common/downloadFile";



const PagosPrestamoComponent = (props) => {
    const navigate = useNavigate();
    const {idPrestamo} = props;
    const dispatch = useDispatch();
    const {pagosList} = useSelector(state => state.prestamos);
    const [pdf, setPdf] = useState();

    const handleChange = (event) => {
        props.setCheckedFil(event.target.checked);
    };
    useEffect(() => {

        dispatch(getAllPagosPrestamo(idPrestamo)).then(()=>{

        }).catch(()=>{

        });

    },[dispatch]);
    const reemprimirPago = useCallback(
        (id) => () => {
            dispatch(emitirRecibo(id)).then((reporte) => {

                downloadFile(reporte.data, reporte.type, reporte.filename)

            }).catch(() => {})
        }
    );


    const columns = [
        {
            field: 'Acciones',
            type: 'actions',
            width: 100,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<PrintIcon />}
                    label="Ver"
                    onClick={reemprimirPago(params.id)}

                />,
            ],
        },
        {
            field: 'id',
            headerName: 'Recibo Nº',
            type: "number",
            width: 100,
            editable: false,
            valueGetter: (param) => param.value ? "#" + param.value.toLocaleString("es-UY") : null
        },
        {
            field: 'fechaPago',
            headerName: 'Fecha',
            type: 'date',
            width: 120,
            editable: false,
            //valueGetter: ({ value }) => value && new Date(value+"T00:00:00")
            renderCell: (param) => (
                fechaPago(param.value)
            ),
        },
        {
            field: 'horaPago',
            headerName: 'Hora',
            type: 'dateTime',
            width: 120,
            editable: false,
            //valueGetter: ({ value }) => value && new Date("2000-01-01"+"T"+value)
            renderCell: (param) => (
                horaPago(param.value)
            ),
        },
        {
            field: 'anulado',
            headerName: '¿Anulado?',
            width: 90,
            editable: false,
            //valueGetter: ({ value }) => value && new Date(value+"T00:00:00")
            renderCell: (param) => (
                param.value ? "SI" : null
            ),
        },
        {
            field: 'fechaAnulacion',
            headerName: 'Fecha anulación',
            type: 'dateTime',
            width: 130,
            editable: false,
            //valueGetter: ({ value }) => value && new Date("2000-01-01"+"T"+value)
            renderCell: (param) => (
                param.row.anulado ? fechaPagoAnulado(param.value) : null
            ),
        },
        {
            field: 'simboloMoneda',
            headerName: 'Moneda',
            width: 90,
            editable: false,
            renderCell: (param) => (
                moneda(param.value)
            ),
        },
        {
            field: 'importeTotal',
            headerName: 'Importe',
            type: 'number',
            width: 100,
            editable: false,
            valueGetter: (param) => param.value ? "$ "+param.value.toLocaleString("es-UY") : null
        },
    ];
    const fechaPago = (fecha) => {
        const fechaPago = new Date(fecha +"T00:00:00")
        const dato = {
            value: formatDDMMYYYY(fechaPago),
            color: "success",
            variant: "outlined",
            disabled: false
        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
            />)
    };
    const horaPago = (hora) => {
        const horaPago = new Date("2000-01-01"+"T"+hora)
        const dato = {
            value: formatHHMMSS(horaPago),
            color: "warning",
            variant: "outlined",
            disabled: false
        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
            />)
    };
    const fechaPagoAnulado = (fecha) => {
        const fechaPago = new Date(fecha)
        const dato = {
            value: formatDDMMYYYY(fechaPago),
            color: "error",
            variant: "outlined",
            disabled: false
        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
            />)
    };
    const moneda = (moneda) => {
        const dato = {
            value: moneda,
            color: "secondary",
            variant: "outlined"
        }
        if (moneda === "USD") {
            dato.color = "info"
            dato.variant = "outlined"

        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
            />)
    };



    return (
        <>
            {/* // AC 04/10/22 - Se comenta porque no funciona el filtro y tapmoco creemos que sea tan necesario.
            <Grid container spacing={2} style={{paddingBottom:"1%"}}>
                <Grid item xs={12}  sm={1.5} >
                    <Typography
                        component="h6"
                        variant="h6"
                        color="inherit"
                        noWrap
                        marginTop="15%"
                        marginLeft="20%"
                    >
                        Filtros
                    </Typography>
                </Grid>
                <Grid item xs={12} md={1} >
                    <Switch
                        checked={props.checkedFil}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{marginTop: "20%"}}
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <DatePickerComponent
                        label="Fecha desde"
                        formato="dd/MM/yyyy"
                        estado={props.fechaDesde}
                        setEstado={props.setFechaDesde}
                        disabled={!props.checkedFil}
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <DatePickerComponent
                        label="Fecha hasta"
                        formato="dd/MM/yyyy"
                        estado={props.fechaHasta}
                        setEstado={props.setFechaHasta}
                        disabled={!props.checkedFil}
                    />
                </Grid>
                <Grid item xs={12}  sm={1} >
                    <Fab sx={{mb: 2, ml: 0}} size = "medium" color="primary" aria-label="add" onClick={null} disabled={!props.checkedFil} >
                        <SearchIcon   />
                    </Fab>
                </Grid>
            </Grid>
            */}
            <GridComponent rows={pagosList} columns={columns} rowHeight={30} pageSize={12}/>
        </>
    );
}
export default PagosPrestamoComponent