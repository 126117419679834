import authHeader from "./auth-header";
import {axiosError, axiosPrivate} from "../api/axios";


const fetchAllTiposDoc = () => {
    const path = "/tiposdocumentos/listar"
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path, config)
        .then((response) => {
        return response.data;
    })
        .catch((error) => {
            console.log("error bloqueo")
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

const fetchAllPaises = () => {
    const path = "/paises/listar"
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let message = axiosError(error);
            return Promise.reject(message);
        });
}
const fetchAllDepartamentos = (idPais) => {
    const path = "/departamentos/listar"
    const params= "?idPais="+idPais;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let message = axiosError(error);
            return Promise.reject(message);
        });
}
const fetchAllCiudades = (idDepartamento) => {
    const path = "/ciudades/listar"
    const params= "?idDepartamento="+idDepartamento;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let message = axiosError(error);
            return Promise.reject(message);
        });
}

const fetchAllMonedas = () => {
    const path = "monedas/obtenerTodas"
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("error bloqueo")
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

export default {
    fetchAllTiposDoc,
    fetchAllPaises,
    fetchAllDepartamentos,
    fetchAllCiudades,
    fetchAllMonedas,
};