import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import {useStateContext} from "../../../../contexts/ContextProvider";
import {Fab,} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import AddCommentIcon from '@mui/icons-material/AddComment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuDeplegableComponent from "../../../../components/menu.deplegable.component";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../../../components/tabpanel.component";
import PropTypes from "prop-types";
import CuotasPrestamoComponent from "./cuotas.prestamo.tab";
import CuotasAnualesPrestamoComponent from "./anualidades.prestamo.tab";
import MovimientosPrestamoComponent from "./movimientos.prestamo.tab";
import PagosPrestamoComponent from "./pagos.prestamo.tab";
import {
    cambioEstadoPrestamo,
    getPrestamo
} from "../../../../store/slices/prestamo.slice";
import PersonIcon from '@mui/icons-material/PersonOutline';
import {useCallback} from "react";
import {setearMensaje} from "../../../../store/slices/snackbar.slice";
import TextboxComponent from "../../../../components/textbox.component";
import {formatDDMMYYYY} from "../../../../common/fechaFormat";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import {CurrencyExchangeOutlined, Payment} from "@mui/icons-material";
import ResumenGraficaComponent from "./resumen.grafica.component.tab";
import {EstadoPrestamo} from "../../../../common/enum/estadoPrestamo";
import PopupCambioestadoPrestamoComponent from "./popup/popup.cambioestado.prestamo.component"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ComunicacionesPrestamoComponent from "./comunicaciones.prestamo.tab";
import {addComunicacion} from "../../../../store/slices/comunicacion.slice";
import {TipoComunicacion} from "../../../../common/enum/tipoComunicacion";
import PopupNuevaComunicacionPrestamoComponent from "./popup/popup.nueva.comunicacion.prestamo.component";

const PrestamoViewComponent = (props) => {

    let {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {open, setOpen, title, setTitle} = useStateContext();
    const [value, setValue] = React.useState(0);
    const {prestamo} = useSelector(state => state.prestamos)
    const [itemsMenu, setItemsMenu] = React.useState([]);
    const [openCambioEstado, setOpenCambioEstado] = React.useState(false)
    const [estadoNuevo, setEstadoNuevo] = React.useState({valor: ""});
    const [observacionEstado, setObservacionEstado] = React.useState({valor: "", valido: true});
    const [openNuevaComunicacion, setOpenNuevaComunicacion] = React.useState(false)
    const [mensajeComunicacion, setMensajeComunicacion] = React.useState({valor: "",  valido: true})

    const prestamosNoActivos = ['CANCELADO', 'FINALIZADO', 'REFINANCIADO'];

    useEffect(() => {
        setTitle("Préstamo");

        dispatch(getPrestamo(id)).then(()=>{

        }).catch(()=>{

        });

    },[dispatch]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const handleRefinanciar = useCallback(
        () => {
            const params = {
                idPrestamo: prestamo.id,
            };
            navigate("/prestamos/refinanciar", {state: params});
        }
    );
    const handleVerCliente = useCallback(
        (id) => () => {
            //console.log("El id es: "+ id);
            navigate("/clientes/"+id);
        }
    );
    const handleEditarPrestamo = useCallback(
        (id) => () => {
            //console.log("El id es: "+ id);
            navigate("/prestamos/modificar/"+id);
        }
    );
    const openPopupCambioEstado = () => {
        setOpenCambioEstado(true)
    }
    const openPopupNuevaComunicacion = () => {
        setOpenNuevaComunicacion(true)
    }
    const handlePagar = useCallback(
         () => {
            const params = {
                idCliente: prestamo.idCliente,
                idPrestamo: prestamo.id,
                tipoPago:"CUOTA"
            };
            navigate("/pagos/nuevo", {state: params});
        }
    );
    const handleAdelantar = useCallback(
         () => {
             const params = {
                idCliente: prestamo.idCliente,
                idPrestamo: prestamo.id,
                tipoPago:"ADELANTO"
            };
            navigate("/pagos/nuevo", {state: params});
        }
    )
    const handleCambiarEstado = useCallback( () => {
        const nuevoEstado = {
            prestamoId: prestamo.id,
            estadoPrestamo: estadoNuevo.valor,
            mensaje: observacionEstado ? observacionEstado.valor : "",
        }
        dispatch(cambioEstadoPrestamo(nuevoEstado)).then(() => {
            const mensaje = {
                message: "Cambio de estado realizado con éxito",
                severity: "success"
            }
            dispatch(setearMensaje(mensaje));
            setOpenCambioEstado(false)
            setObservacionEstado({valor:"", valido: true})
            setEstadoNuevo({valor: ""})

            dispatch(getPrestamo(id)).then(()=>{}).catch(()=>{});
            setUsuarioComunicacion({valor:"", valido:true})
        }).catch(() => {});
    })

    const handleNuevaComunicacion = useCallback( () => {
        const nuevaComunicacion = {
            mensaje: mensajeComunicacion.valor,
            tipoComunicacion: TipoComunicacion.PRESTAMO,
            prestamoId: prestamo.id
        }
        dispatch(addComunicacion(nuevaComunicacion)).then(() => {
            const mensaje = {
                message: "La comunicación fue agregada correctamente.",
                severity: "success"
            }
            dispatch(setearMensaje(mensaje))
            setOpenNuevaComunicacion(false)
            setMensajeComunicacion({valor:"", valido:true})

            dispatch(getPrestamo(id)).then(()=>{}).catch(()=>{});
            setUsuarioComunicacion({valor:"", valido:true})
        }).catch(() => {});
    });


    /*
    const handleEliminarPrestamo = useCallback(
        (id) => () => {
            console.log("El id es: "+ id);
            dispatch(deletePrestamo(id)).then(()=>{
                const mensaje = {
                    message: "Préstamo eliminado con éxito",
                    severity: "success",
                }
                dispatch(setearMensaje(mensaje))
                navigate(-1)

            }).catch(()=> {

            })
        }
    );
    */

    useEffect(()=>{
        if(prestamo!== null) {
            //console.log("hay cliente");
            setItemsMenu([
                {
                    accion: "Cambiar Estado",
                    //icono: <EastIcon/>,
                    icono: <KeyboardDoubleArrowRightIcon />,
                    onClick: openPopupCambioEstado,
                    activo: prestamosNoActivos.includes(prestamo.estadoPrestamo) ? false : true
                },
                {
                    accion: "Nueva Comunicación",
                    //icono: <EastIcon/>,
                    icono: <AddCommentIcon />,
                    onClick: openPopupNuevaComunicacion,
                    activo: prestamosNoActivos.includes(prestamo.estadoPrestamo) ? false : true
                },

                {
                    accion: "Pagar cuotas",
                    icono: <Payment/>,
                    onClick: handlePagar,
                    //activo: (prestamo.estado === "ACTIVO") ? true : false,
                    activo: prestamosNoActivos.includes(prestamo.estadoPrestamo) ? false : true,
                },
                {
                    accion: "Adelantar pago",
                    icono: <LocalAtmIcon/>,
                    onClick: handleAdelantar,
                    //activo: (prestamo.estado === "ACTIVO") ? true : false,
                    activo: false,
                },
                {
                    accion: "Editar",
                    icono: <EditIcon/>,
                    onClick: handleEditarPrestamo(prestamo.id),
                    activo: prestamosNoActivos.includes(prestamo.estadoPrestamo) ? false : true
                },
                {
                    accion: "Ver cliente",
                    icono: <PersonIcon/>,
                    onClick: handleVerCliente(prestamo.idCliente),
                    //activo: (prestamo.estado === "ACTIVO") ? true : false,
                    activo: true,
                },
                {
                    accion: "REFINANCIAR",
                    icono: <CurrencyExchangeOutlined/>,
                    onClick: handleRefinanciar,
                    //activo: (prestamo.estado === "ACTIVO") ? true : false,
                    activo: prestamosNoActivos.includes(prestamo.estadoPrestamo) ? false : true,
                },

            ]);
        }
    },[prestamo]);
    const { message } = useSelector(state => state.mensaje);

    const handleAtras = () => {
        return navigate(-1);
    }
    const [tipoMov, setTipoMov] = useState({valor: "", valido: true});
    const [estadoCuotaMensual, setEstadoCuotaMensual] = useState({valor: "", valido: true});
    const [estadoCuotaAnual, setEstadoCuotaAnual] = useState({valor: ""});
    const [checkedFilPagos, setCheckedFilPagos] = React.useState(false);
    const [tipoComunicacion, setTipoComunicacion] = useState({valor: "", valido: true});
    const [usuarioComunicacion, setUsuarioComunicacion] = useState({valor: "", valido: true});

    const fechaHoy = new Date()
    const fechaAntes = new Date()
    fechaAntes.setDate(fechaHoy.getDate() -2)
    const [fechaDesde, setFechaDesde] = React.useState({valor: fechaAntes, valido: true});
    const [fechaHasta, setFechaHasta] = React.useState({valor: fechaHoy, valido: true});

    const estadoPrestamo = (estado) => {
        const dato = {
            value: estado,
            color: "secondary",
            variant: "outlined"
        }
        if (estado == "AL_DIA") {
            dato.value = EstadoPrestamo.AL_DIA
            dato.color = "success"
            dato.variant = "outlined"

        } else if (estado === "FINALIZADO") {
            dato.value = EstadoPrestamo.FINALIZADO
            dato.color = "success"
            dato.variant = "filled"
        }
        else if (estado === "DEUDOR_CONSENSUADO") {
            dato.value = EstadoPrestamo.DEUDOR_CONSENSUADO
            dato.color = "warning"
            dato.variant = "outlined"
        }
        else if (estado === "DEUDOR_GRAVE") {
            dato.value = EstadoPrestamo.DEUDOR_GRAVE
            dato.color = "warning"
            dato.variant = "filled"
        }
        else if (estado === "EN_JUICIO"){
            dato.value = EstadoPrestamo.EN_JUICIO
            dato.color = "error"
            dato.variant = "filled"
        }
        else if (estado === "CANCELADO") {
            dato.value = EstadoPrestamo.CANCELADO
            dato.color = "error"
            dato.variant = "outlined"
        }
        return dato;
    };
    const moneda = (moneda) => {
        const dato = {
            value: moneda,
            color: "secondary",
            variant: null
        }
        if (moneda === "USD") {
            dato.color = "info"
            dato.variant = "outlined"

        }
        return dato;
    };


    //TODO: validaciones / carga de datos / chequeo de validaciones prvio a guardar.

    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                    elevation={3}>

                    {prestamo && (
                        <>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={10} >
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                >
                                    <b>Préstamo #{(prestamo.numeroPrestamo ? prestamo.numeroPrestamo : prestamo.id) + "-" + prestamo.serie}</b>
                                </Typography>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="#9C9897"
                                    noWrap
                                    marginLeft="2%"
                                >
                                    <b>{prestamo.tipoPrestamo}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}  sm={1} >
                                <Fab sx={{mb: 0, ml: 0}}
                                     title={"Volver atrás"}
                                     size = "medium"
                                     color="primary"
                                     aria-label="add"
                                     onClick={handleAtras}
                                >
                                    <ArrowBackIcon/>
                                </Fab>
                            </Grid>
                            <Grid item xs={6}  sm={1} >

                                <Fab sx={{mb: 0, ml: 0}}
                                     size = "medium"
                                     color="primary"
                                     aria-label="add"
                                >
                                    <MenuDeplegableComponent titulo={"Opciones"} items={itemsMenu}/>
                                </Fab>

                            </Grid>
                            <Grid item xs={12} style={{paddingTop: '0px'}} >
                                <hr  paddingTop={5}/>{/*LINEA HORIZONTAL*/}
                            </Grid>
                            <Grid item xs={12} lg={4}  >
                                <TextboxComponent
                                    titulo={{valor: "Fecha de otorgamiento", negrita: true}}
                                    dato={{valor: formatDDMMYYYY(new Date(prestamo.fechaAlta+"T00:00:00"))}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}  >
                                <TextboxComponent
                                    titulo={{valor:"Estado", negrita: true}}
                                    etiqueta={{label: estadoPrestamo(prestamo.estadoPrestamo).value,
                                        color:estadoPrestamo(prestamo.estadoPrestamo).color,
                                        variant: estadoPrestamo(prestamo.estadoPrestamo).variant,
                                        size: "medium",
                                        negrita: true,
                                        fontSize:15}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor:"Moneda", negrita: true}}
                                    etiqueta={{label: moneda(prestamo.simboloMoneda).value,
                                        color:moneda(prestamo.simboloMoneda).color,
                                        variant: moneda(prestamo.simboloMoneda).variant,
                                        size: "medium",
                                        negrita: true}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}  >
                                <TextboxComponent
                                    titulo={{valor:"Cliente", negrita: true}}
                                    dato={{valor: prestamo.nombreCompletoCliente}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}  >

                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor:"Capital financiado", negrita: true}}
                                    dato={{valor: "$"+prestamo.importeFinanciado.toLocaleString("es-UY"), negrita: true}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor:"Capital prestado", negrita: true}}
                                    dato={{valor: "$"+prestamo.importePrestado.toLocaleString("es-UY")}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor:"Tasa de interés", negrita: true}}
                                    dato={{valor: (prestamo.tasa)*100+"%"}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor:"Cuotas totales", negrita: true}}
                                    dato={{valor: prestamo.cantidadCuotas}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor:"Cuotas totales canceladas", negrita: true}}
                                    dato={{valor: prestamo.cuotasCanceladas, negrita: true}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={2} >
                                <TextboxComponent
                                    titulo={{valor:"Valor cuota", negrita: true}}
                                    dato={{valor: "$"+prestamo.valorCuota.toLocaleString("es-UY")}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={2} >
                                <TextboxComponent
                                    titulo={{valor:"Valor ult. cuota", negrita: true}}
                                    dato={{valor: "$"+prestamo.valorUltimaCuota.toLocaleString("es-UY")}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor: "Anualidades totales", negrita: true}}
                                    dato={{valor: prestamo.cantidadAnualidades}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor: "Anualidades totales canceladas", negrita: true}}
                                    dato={{valor: prestamo.anualidadesCanceladas, negrita: true}}
                                />
                            </Grid>
                            <Grid item xs={0} lg={4}></Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor: "Importe total abonado", negrita: true}}
                                    dato={{valor: "$"+prestamo.importeCancelado.toLocaleString("es-UY"), negrita: true, variant:"h4", pt:"5%", pb:"8%"}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor: "Importe descontado", negrita: true}}
                                    dato={{valor: "$"+prestamo.importeDescuento.toLocaleString("es-UY"), negrita: true, variant:"h4", pt:"5%", pb:"8%"}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor: "Saldo pendiente", negrita: true}}
                                    dato={{valor: "$"+prestamo.saldoPendientePrestamo.toLocaleString("es-UY"), negrita: true, variant:"h4", pt:"5%", pb:"8%"}}
                                />
                            </Grid>
                            {/*<Grid item xs={12} >
                                <Grafica
                                    title="Resumen de pagos"
                                    subheader="(Por día)"
                                    type={"radialBar"}
                                    chartLabels={ ["Capital Financiado", "Importe abonado", "Interes"]}
                                    chartData={[
                                        60,50,40
                                    ]}
                                />
                            </Grid>*/}
                            <Grid item xs={12}>
                                <hr />{/*LINEA HORIZONTAL*/}
                            </Grid>
                            <Grid item xs={12} lg={12} style={{paddingTop: '0px', paddingBottom: '1%'}} >
                                <Typography
                                    component="h1"
                                    variant="h5"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                    align="center"
                                >
                                    <b>Datos del Automotor</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor: "Matrícula", negrita: true}}
                                    dato={{valor: prestamo.matricula}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor: "Marca", negrita: true}}
                                    dato={{valor: prestamo.marca}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor: "Modelo", negrita: true}}
                                    dato={{valor: prestamo.modelo}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor: "Año", negrita: true}}
                                    dato={{valor: prestamo.anio}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor: "Kilometraje", negrita: true}}
                                    dato={{valor: prestamo.kilometraje}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} >
                                <TextboxComponent
                                    titulo={{valor: "Control mecánico", negrita: true}}
                                    dato={{valor: prestamo.controlMecanico ? "Si" :"No"}}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <hr />{/*LINEA HORIZONTAL*/}
                            </Grid>
                            <Grid item xs={12} lg={12} style={{paddingTop: '0px', paddingBottom: '1%'}} >
                                <Typography
                                    component="h1"
                                    variant="h5"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                    align="center"
                                >
                                    <b>Información del Préstamo</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{ width: '100%' }}>
                            <Paper
                                elevation={4}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                                        <Tab label="Cuotas Mensuales" {...a11yProps(0)} />
                                        <Tab label="Anualidades" {...a11yProps(1)} />
                                        <Tab label="Movimientos" {...a11yProps(2)} />
                                        <Tab label="Pagos" {...a11yProps(3)} />
                                        <Tab label="Resumen" {...a11yProps(4)} />
                                        <Tab label="Comunicaciones" {...a11yProps(5)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <CuotasPrestamoComponent idPrestamo={id}
                                                             idCliente={prestamo.idCliente}
                                                             estadoCuotaMensual={estadoCuotaMensual}
                                                             setEstadoCuotaMensual={setEstadoCuotaMensual}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <CuotasAnualesPrestamoComponent idPrestamo={id}
                                                                    idCliente={prestamo.idCliente}
                                                                    estadoCuotaAnual={estadoCuotaAnual}
                                                                    setEstadoCuotaAnual={setEstadoCuotaAnual}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <MovimientosPrestamoComponent idPrestamo={id}
                                                                  tipoMov={tipoMov}
                                                                  setTipoMov={setTipoMov}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <PagosPrestamoComponent idPrestamo={id}
                                                            fechaDesde={fechaDesde}
                                                            setFechaDesde={setFechaDesde}
                                                            fechaHasta={fechaHasta}
                                                            setFechaHasta={setFechaHasta}
                                                            checkedFil={checkedFilPagos}
                                                            setCheckedFil={setCheckedFilPagos}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    <ResumenGraficaComponent
                                        importePendiente={prestamo.importeFinanciado - prestamo.importeCancelado}
                                        importeCancelado={prestamo.importeCancelado}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={5}>
                                    <ComunicacionesPrestamoComponent
                                        idPrestamo={id}
                                        tipoComunicacion={tipoComunicacion}
                                        setTipoComunicacion={setTipoComunicacion}
                                        usuarioComunicacion={usuarioComunicacion}
                                        setUsuarioComunicacion={setUsuarioComunicacion}
                                    />
                                </TabPanel>
                            </Paper>
                        </Box>
                        </>
                    )}
                </Paper>
            {
                prestamo &&
                <PopupCambioestadoPrestamoComponent
                    open={openCambioEstado}
                    setOpen={setOpenCambioEstado}
                    prestamo={prestamo}
                    estado={estadoPrestamo}
                    dispatch={dispatch}
                    handleCambiarEstado={handleCambiarEstado}
                    estadoNuevo={estadoNuevo}
                    setEstadoNuevo={setEstadoNuevo}
                    observacionEstado={observacionEstado}
                    setObservacionEstado={setObservacionEstado}
                />
            }
            {
                prestamo &&
                <PopupNuevaComunicacionPrestamoComponent
                    handleNuevaComunicacion={handleNuevaComunicacion}
                    openNuevaComunicacion={openNuevaComunicacion}
                    setOpenNuevaComunicacion={setOpenNuevaComunicacion}
                    mensajeComunicacion={mensajeComunicacion}
                    setMensajeComunicacion={setMensajeComunicacion}
                />
            }
        </Container>
    );
};

export default PrestamoViewComponent;

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}