import {createSlice} from "@reduxjs/toolkit";
import {clearMessage, setearMensaje} from "./snackbar.slice"
import {errores} from "../../common/errores";
import {logout} from "./autenticacion.slice";
import pagoService from "../../services/pago.service";
import reporteService from "../../services/reporte.service";

const initialState = {
    pagosList: [],
    pago: null,
}

export const pagoSlice = createSlice({
    name: "pagos",
    initialState,
    reducers: {
        setPagosList: (state, action) => {
            state.pagosList = action.payload;
        },
        pagosError: (state) =>{
            state.pagosList = [];
        },
        setPago:(state, action) => {
            state.pago = action.payload;
        },pagoError: (state) =>{
            state.pago = null;
        }

    }
});
const {setPagosList, pagosError, setPago, pagoError} = pagoSlice.actions;
export default pagoSlice.reducer;

export const getAllPagos = (pagoId, fechaDesde, fechaHasta, numeroPrestamo, serie) => (dispatch) => {
    return pagoService.fetchAllPagos(pagoId, fechaDesde, fechaHasta, numeroPrestamo, serie).then(
        (data) => {
            dispatch(setPagosList(data));
            dispatch(clearMessage());
            return Promise.resolve();
        },).catch(
        (error) => {
            //console.log("ERROR DE PAGOS");
            dispatch(pagoError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const getPago = (id) => (dispatch) => {
    return pagoService.fetchPago(id).then(
        (data) => {
            dispatch(setPago(data));
            dispatch(clearMessage());
            return Promise.resolve();
        },).catch(
        (error) => {
            //console.log("ERROR DE PAGOS");
            dispatch(pagoError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const pagoNuevo = (pagoNuevo) => (dispatch) => {
    return pagoService.pagoNuevo(pagoNuevo).then(
        (data) => {

            return Promise.resolve(data);
        },).catch(
        (error) => {
            //console.log("ERROR DE PAGOS");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const pagoAdelantoNuevo = (pagoNuevo) => (dispatch) => {
    return pagoService.pagoAdelantoNuevo(pagoNuevo).then(
        (data) => {

            return Promise.resolve(data);
        },).catch(
        (error) => {
            //console.log("ERROR DE PAGOS");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};

export const emitirRecibo = (idPago) => (dispatch) => {
    return reporteService.emitirRecibo(idPago).then(
        (data) => {
            return Promise.resolve(data);
        },).catch(
        (error) => {
            //console.log("ERROR DE EMISION RECIBO");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde EMITIR RECIBO")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};

export const anularPago = (idPago) => (dispatch) => {
    return pagoService.anularPago(idPago).then(
        (data) => {

            return Promise.resolve(data);
        },).catch(
        (error) => {
            console.log("ERROR DE anulacion pago");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde anulacion pago")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
