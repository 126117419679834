import DashboardComponent from "../pages/home/dashboard.component";
import ProtectedRoute from "../helpers/ProtectedRoute";
import PrestamosComponent from "../pages/prestamos/prestamos.component";
import ClientesComponent from "../pages/clientes/clientes.component";
import ClienteComponent from "../pages/clientes/cliente/cliente.component";
import MainLayout from "../layout/MainLayout";
import React from "react";
import PersonaViewComponent from "../pages/clientes/cliente/persona.view.component";
import PrestamoViewComponent from "../pages/prestamos/prestamo/view/prestamo.view.component";
import PrestamoNuevoComponent from "../pages/prestamos/prestamo/nuevo/prestamo.nuevo.component";
import PagosComponent from "../pages/pagos/pagos.component";
import PagoComponent from "../pages/pagos/pago/pago.component";

import PrestamoModificarComponent from "../pages/prestamos/prestamo/modificar/prestamo.modificar.component";

import ClienteModificarComponent from "../pages/clientes/cliente/modificar/cliente.modificar.component";
import ReportesComponent from "../pages/reportes/reportes";
import SoporteComponent from "../pages/menu_account/soporte/soporte.component";
import PerfilComponent from "../pages/menu_account/perfil/perfil.component";
import FormasPagosComponent from "../pages/maestros/formas_pagos/formas.pagos.component";
import FormaPagoModificarComponent from "../pages/maestros/formas_pagos/forma.pago.modificar.component";
import FormaPagoNuevoComponent from "../pages/maestros/formas_pagos/forma.pago.nuevo.component";
import MetodoDePagoComponent from "../pages/pagos/metodos_de_pago/metodo.de.pago.component";
import ReportesSaldosTotalesComponent from "../pages/reportes/reporte.saldos.totales.component";
import ReporteProyeccionIngresosComponent from "../pages/reportes/reporte.proyeccion.ingresos.component";
import AutomotorRefinanciarComponent from "../pages/prestamos/prestamo/nuevo/automotor.refinanciar.component";
import PrestamoRefinanciarComponent from "../pages/prestamos/prestamo/nuevo/prestamo.refinanciar.component";


const MainRoutes = {
    path: '/',
    element: <ProtectedRoute> <MainLayout /></ProtectedRoute> ,
    children: [
        {
            path: '/',
            element: <DashboardComponent />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardComponent />
                }
            ]
        },
        {
            path: 'prestamos',
            children:[
                {
                    path: '',
                    element: <PrestamosComponent/>
                },
                {
                    path: 'nuevo',
                    element: <PrestamoNuevoComponent/>
                },
                {
                    path: 'refinanciar',
                    element: <PrestamoRefinanciarComponent/>
                },
                {
                    path: ':id',
                    element: <PrestamoViewComponent />
                },
                {
                    path: 'modificar/:id',
                    element: <PrestamoModificarComponent />
                },

            ]

        },

        {
            path: 'clientes',
            children:[
                {
                    path:'',
                    element :<ClientesComponent/>
                },
                {
                    path: 'nuevo',
                    element: <ClienteComponent />
                },
                {
                    path: ':id',
                    element: <PersonaViewComponent />
                },
                {
                    path: 'modificar/:id',
                    element: <ClienteModificarComponent />
                }

            ]
        },
        {
            path: 'pagos',
            children:[
                {
                    path:'',
                    element :<PagosComponent/>
                },
                {
                    path:'nuevo',
                    element :<PagoComponent/>
                },
                {
                    path:'metodos_de_pago',
                    element : <MetodoDePagoComponent />
                }

            ]
        },
        {
            path: 'formas_pagos',
            children:[
                {
                    path:'',
                    element :<FormasPagosComponent />
                },
                {
                    path:'nuevo',
                    element :<FormaPagoNuevoComponent />
                },
                {
                    path: 'modificar/:id',
                    element: <FormaPagoModificarComponent />
                }
            ]

        },
        {
            path: 'reportes',
            children:[
                {
                    path:'',
                    element :<ReportesComponent/>
                },
                {
                    path:'saldos_totales',
                    element: <ReportesSaldosTotalesComponent />
                },
                {
                    path: 'proyeccion_ingresos',
                    element: <ReporteProyeccionIngresosComponent/>
                }
            ]

        },
        {
            path: 'configuracion',
            children:[
                {
                    path:'soporte',
                    element :<SoporteComponent/>
                },

            ]

        },
        {
            path: 'perfil',
            children:[
                {
                    path:'',
                    element :<PerfilComponent/>
                },

            ]

        }
    ]
};

export default MainRoutes;