import React from 'react';
import TextField from "@mui/material/TextField";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {DatePicker} from "@mui/x-date-pickers";

const DatePickerComponent = (props) => {
    const handleChange = (e) => {
        props.setEstado({valor: e});
    }
    const validacion = () => {
        if(props.expresionRegular){
            if(props.expresionRegular.test(props.estado.valor)){
                props.setEstado({...props.estado, valido: true})
                console.log("fecha valida")
            }
            else{
                console.log("fecha invalida")
                props.setEstado({...props.estado, valido: false})
            }
        }

    }
    return (
        <DatePicker
            label={props.label}
            inputFormat={props.formato}
            value={props.estado.valor}
            onChange={handleChange}
            onBlur={validacion}
            disabled={props.disabled ? props.disabled : false}
            renderInput={(params) => <TextField {...params}  helperText= {props.estado.valido ? "":props.leyendaError}/>}
        />
    );
};

export default DatePickerComponent;
