

import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleIcon from '@mui/icons-material/People';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PaymentIcon from '@mui/icons-material/Payment';
import AddCardIcon from '@mui/icons-material/AddCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Link } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import PaymentsIcon from '@mui/icons-material/Payments';




const MenuItems = () => {

const [openMaster, setOpenMaster] = React.useState(false);
const [openCobranzas, setOpenCobranzas] = React.useState(false);

const handleMaster = () => {
    setOpenMaster(!openMaster);
};
const handleCobranzas = () => {
    setOpenCobranzas(!openCobranzas)
}

return (


    <React.Fragment>
        <ListItemButton component= {Link} to="/">
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
        </ListItemButton>
        <ListItemButton component= {Link} to='/clientes'>
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Clientes" />
        </ListItemButton>
        <ListItemButton component= {Link} to='/prestamos'>
            <ListItemIcon>
                <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText primary="Préstamos" />
        </ListItemButton>
        <ListItemButton onClick={handleCobranzas}>
            <ListItemIcon>
                <PaymentIcon />
            </ListItemIcon>
            <ListItemText primary="Cobranzas" />
            { openCobranzas ? <ExpandLess /> : <ExpandMore /> }
        </ListItemButton>
        <Collapse in={openCobranzas} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} component={Link} to='/pagos/nuevo'>
                    <ListItemIcon>
                        <AddCardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Nuevo Pago" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} component={Link} to='/pagos'>
                    <ListItemIcon>
                        <PaymentsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Consulta de Pagos" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} component={Link} to='/pagos/metodos_de_pago'>
                    <ListItemIcon>
                        <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Métodos de Pago" />
                </ListItemButton>
            </List>
        </Collapse>
        <ListItemButton onClick={handleMaster}>
            <ListItemIcon>
                <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Maestros" />
            {openMaster ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openMaster} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} component={Link} to='/formas_pagos'>
                    <ListItemIcon>
                        <PaymentsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Formas de Pago" />
                </ListItemButton>
            </List>
        </Collapse>
        { /*<ListItemButton component= {Link} to='/reportes'>
            <ListItemIcon>
                <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Reportes" />
        </ListItemButton> */}
    </React.Fragment>
);
}
export const favoritosItems = (
    <React.Fragment>
        <ListSubheader component="div" inset>
            Reportes
        </ListSubheader>
            <ListItemButton component={Link} to='/reportes/saldos_totales'>
                <ListItemIcon>
                    <AssignmentIcon/>
                </ListItemIcon>
                <ListItemText primary="Saldos"/>
            </ListItemButton>
            <ListItemButton component={Link} to='/reportes/proyeccion_ingresos'>
            <ListItemIcon>
            <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Proyección Ingresos" />
            </ListItemButton>
        {
            /*
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Reporte 3" />
        </ListItemButton>
        */}
    </React.Fragment>
);

export default MenuItems;