import * as React from 'react';
import {DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {Popover} from "@mui/material";
import { useDemoData } from '@mui/x-data-grid-generator';
import Typography from "@mui/material/Typography";


const GridComponentPopover = (props) => {
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setValue] = React.useState('');

    const handlePopoverOpen = (event) => {
        const id = Number(event.currentTarget.getAttribute('data-id'))
        const row = props.rows.find((r) => r.id === id);
        setValue(row[props.fieldViewPopover]);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div style={{ height:props.height?props.height: 510, width:props.width?props.width:'100%'}}>
            <DataGrid
                rows={props.rows}
                columns={props.columns}
                rowHeight={props.rowHeight}
                pageSize={props.pageSize?props.pageSize: 5}
                rowsPerPageOptions={[props.pageSize?props.pageSize: 5]}
                disableSelectionOnClick
                components={props.disableToolbar ? {} :{}}
                componentsProps={{
                    row: {
                        onMouseEnter: handlePopoverOpen,
                        onMouseLeave: handlePopoverClose,
                    },
                }}
                checkboxSelection={props.checkboxSelection ? props.checkboxSelection: false}
                onSelectionModelChange={(newSelectionModel) => {
                    props.setSeleccion(newSelectionModel);
                }}
                selectionModel={props.seleccion}
                localeText={
                    {noRowsLabel: props.mensajeSinFilas?props.mensajeSinFilas: "No se ha encontrado datos."}}

            />
            <Popover
                sx={{
                    pointerEvents: 'none',
                    zIndex: '999999',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography  sx={{ p: 1 }} zIndex={999999} maxWidth={600}>{value}</Typography>
            </Popover>
        </div>
    );
};

export default GridComponentPopover;

/*localeText={{
                    noRowsLabel: "No se ha encontrado datos.",
                    noResultsOverlayLabel: "No se ha encontrado ningún resultado",
                    toolbarColumns: "Columnas",
                    toolbarColumnsLabel: "Seleccionar columnas",
                    toolbarFilters: "Filtros",
                    toolbarFiltersLabel: "Ver filtros",
                    toolbarFiltersTooltipHide: "Quitar filtros",
                    toolbarFiltersTooltipShow: "Ver filtros",
                    //toolbarExport:"Exportar",
                    toolbarExportCSV: "Descargar CSV",
                    toolbarExportPrint: "Imprimir tabla",
                    toolbarDensity: "Tamaño de fila",
                    filterOperatorIsEmpty: "Es vacio",
                    filterOperatorStartsWith: "Empieza con",
                    filterOperatorEndsWith: "Termina con",
                    filterOperatorEquals: "Igual a",
                    filterOperatorContains: "Contiene",
                    filterOperatorIs: "Es",
                    filterOperatorIsNotEmpty: "No vacio",
                    filterOperatorIsAnyOf: "Es cualquiera de",


                }}*/