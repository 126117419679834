import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from "react-router-dom";
import GridComponent from "../../../../components/grid.component";
import {useDispatch, useSelector} from "react-redux";
import {getAllCuotasAnuales, getAllMovimientosPrestamo} from "../../../../store/slices/prestamo.slice";
import Grid from "@mui/material/Grid";
import {Chip, Fab} from "@mui/material";
import SelectComponent from "../../../../components/select.component";
import {formatDDMMYYYY, formatHHMMSS} from "../../../../common/fechaFormat";
import {TipoMovimiento} from "../../../../common/enum/tipoMovimiento";
import GridComponentPopover from "../../../../components/grid.component.popover";
import Typography from "@mui/material/Typography";
import {TipoComunicacion} from "../../../../common/enum/tipoComunicacion";
import {getAllComunicacionesPrestamo} from "../../../../store/slices/comunicacion.slice";
import AddIcon from "@mui/icons-material/Add";
import PopupNuevaComunicacionPrestamoComponent from "./popup/popup.nueva.comunicacion.prestamo.component";



const ComunicacionesPrestamoComponent = (props) => {

    const {idPrestamo} = props;
    const dispatch = useDispatch();
    const [usuariosList, setUsuariosList] = useState([])

    const {comunicacionPrestamoList} = useSelector(state => state.comunicacion);

    useEffect(() => {

        dispatch(getAllComunicacionesPrestamo(props.tipoComunicacion.valor, idPrestamo, props.usuarioComunicacion.valor))
            .then(()=>{

        }).catch(()=>{

        });
    },[dispatch, props.tipoComunicacion, props.usuarioComunicacion]);

    useEffect(()=>{
        comunicacionPrestamoList.forEach(
            (com => {
                if(!usuariosList.includes(com.usuario)) {
                    setUsuariosList([...usuariosList, com.usuario])
                }
            }));
        console.log(usuariosList)
    },[comunicacionPrestamoList])


    const fechaComunicacion = (fecha) => {
        const fechaCom = new Date(fecha +"T00:00:00")
        const dato = {
            value: formatDDMMYYYY(fechaCom),
            color: "success",
            variant: "outlined",
            disabled: false
        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
                sx={{alignItems: "cented", fontSize: 10}}
            />)
    };
    const tipoComunicacion = (tipo) => {

        const dato = {
            value: tipo,
            color: "secondary",
            variant: "outlined"
        }
        if (tipo === "PRESTAMO") {
            dato.value = TipoComunicacion.PRESTAMO
            dato.color = "warning"
            dato.variant = "outlined"

        }else if (tipo === "CAMBIO_ESTADO_PRESTAMO") {
            dato.value = TipoComunicacion.CAMBIO_ESTADO_PRESTAMO
            dato.color = "info"
            dato.variant = "outlined"

        }
        return (
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                sx={{alignItems: "cented", fontSize: 10}}
            />
        );
    };
    const horaComunicacion = (hora) => {
        const horaCom = new Date("2000-01-01"+"T"+hora)
        const dato = {
            value: formatHHMMSS(horaCom),
            color: "warning",
            variant: "outlined",
            disabled: false
        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
                sx={{ fontSize:10 }}
            />)
    };
    const mensajeRender = (mensaje) => {
        return(
            <Typography
                variant={'body'}
                noWrap={true}
            >
                {mensaje}
            </Typography>
        )
    }

    const columns = [
        {
            field: 'fechaComunicacion',
            headerName: 'Fecha',
            type:'date',
            width: 90,
            editable: false,
            renderCell: (param) => (
                fechaComunicacion(param.value)
            ),
        },
        {
            field: 'horaComunicacion',
            headerName: 'Hora',
            width: 80,
            type: 'time',
            editable: false,
            renderCell: (param) => (
                horaComunicacion(param.value)
            ),
        },
        {
            field: 'mensaje',
            headerName: 'Mensaje',
            width: 300,
            editable: false,
            renderCell: (param) => (
                mensajeRender(param.value)
            ),
        },
        {
            field: 'tipoComunicacion',
            headerName: 'Tipo',
            width: 150,
            editable: false,
            renderCell: (param) => (
              tipoComunicacion(param.value)
            ),
        },
        {
            field: 'usuario',
            headerName: 'Usuario',
            width: 170,
            editable: false,
        }
    ];


    return (
        <>
            <Grid container spacing={2} paddingLeft={2} style={{paddingBottom:"1%"}}>

                <Grid item xs={12} md={4} >
                    <SelectComponent label="Tipo de Comunicación"
                                     items={Object.entries(TipoComunicacion).map(([key, value]) => ({id: key, descripcion:value}))}
                                     required={false}
                                     estado={props.tipoComunicacion}
                                     setEstado={props.setTipoComunicacion}
                                     sx={{'& > :not(style)': {width: '100%'}}}/>
                </Grid>
                <Grid item xs={12} md={3.5} >
                    <SelectComponent label="Usuario"
                                     items={usuariosList.map(usuario => ({id: usuario, descripcion: usuario}))}
                                     required={false}
                                     estado={props.usuarioComunicacion}
                                     setEstado={props.setUsuarioComunicacion}
                                     sx={{'& > :not(style)': {width: '100%'}}}/>
                </Grid>
            </Grid>

            <GridComponentPopover rows={comunicacionPrestamoList}
                                  columns={columns}
                                  rowHeight={30}
                                  pageSize={12}
                                  fieldViewPopover={"mensaje"}
            />

        </>
    );
}
export default ComunicacionesPrestamoComponent