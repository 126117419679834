import React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Stack} from "@mui/material";

const ButtonComponent = (props) => {
    return (
           <Button label={props.label} variant={props.type} onClick={props.onClick} component={props.component}>{props.label}</Button>
    );
};

export default ButtonComponent;