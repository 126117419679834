import Box from "@mui/material/Box";
import InputComponent from "../../../../components/input.component";
import SelectComponent from "../../../../components/select.component";
import {Fab, FormControlLabel, Stack, Switch} from "@mui/material";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import ButtonComponent from "../../../../components/button.component";
import React, {useCallback, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {expresiones as er} from "../../../../common/expresionesRegulares";
import {addPrestamoAutomotor, getPrestamo, updatePrestamoAutomotor} from "../../../../store/slices/prestamo.slice"
import {setearMensaje} from "../../../../store/slices/snackbar.slice";
import Typography from "@mui/material/Typography";
import {formatDDMMYYYY, formatYYYYMMDD} from "../../../../common/fechaFormat";


const AutomotorModificarComponent = () => {

    const {prestamo} = useSelector(state => state.prestamos)
    const [marca, setMarca] = useState({valor: prestamo.marca, valido:true });
    const [modelo, setModelo] = useState({valor: prestamo.modelo, valido:true });
    const [matricula, setMatricula] = useState({valor: prestamo.matricula, valido:true });
    const [anio, setAnio] = useState({valor:prestamo.anio, valido: true});
    const [kilometraje, setKilometraje] = useState({valor:prestamo.kilometraje, valido: true});
    const [checked, setChecked] = useState(prestamo.controlMecanico);
    const year = (new Date()).getFullYear();
    const yearList = Array.from(new Array(50),( val, index) => year - index);
    const aniosList = yearList.map(( obj, index ) => { let an = {}; an.id=obj; an.descripcion = obj; return an;  }  );

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChangeSwitch = (event) => {
        setChecked(event.target.checked);
    };

    const handleCancelar = (e) => {
        e.preventDefault();
        return navigate(-1);
    }
    const handleAceptar = (e) => {
        e.preventDefault();
        if(validarFormulario()){

            const prestamoModificar = {
                id: prestamo.id,
                matricula: matricula.valor,
                marca: marca.valor,
                modelo:modelo.valor,
                anio: anio.valor,
                kilometraje: kilometraje.valor,
                controlMecanico: checked
            }

            console.log("Prestamo Nuevo " + JSON.stringify(prestamoModificar));
            dispatch(updatePrestamoAutomotor(prestamoModificar)).then(()=>{
                const mensaje = {
                    message: "Modificación realizada con éxito",
                    severity: "success",
                }
                dispatch(setearMensaje(mensaje))
                navigate(-1)
            }).catch(()=>{
                console.log("NOOOOO inserte")
            })

        }else{
            console.log("formulario no validado");
            const mensaje = {
                message: "El formulario esta incompleto",
                severity: "error",
            }
            dispatch(setearMensaje(mensaje))
        }
    }

    const validarFormulario = () => {
        let valido = true;
        if(
            marca.valido === false ||
            modelo.valido === false ||
            matricula.valido === false ||
            anio.valido === false ||
            kilometraje.valido === false
        ){
            valido = false;
        }
        if(
            marca.valor === "" ||
            modelo.valor === "" ||
            matricula.valor === "" ||
            anio.valor === "" ||
            kilometraje.valor === ""
        )
        {
            valido = false;
        }
        return valido;
    }


    let fechaAlta = new Date(prestamo.fechaAlta+"T"+"00:00:00")

    return (
        <Box component="form"
             sx={{
                 '& > :not(style)': {m: 1, width: '100%'},
             }}
             noValidate
             autoComplete="off">
            <Box sx={ {flexGrow: 1}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}  >
                        <Typography>
                            Fecha del prestamo
                        </Typography>
                        <Typography color="#9C9897">
                            {formatDDMMYYYY(fechaAlta)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}  >
                        <Typography>
                            Estado
                        </Typography>
                        <Typography color="#9C9897">
                            {prestamo.estado}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} >
                        <Typography >
                            Moneda
                        </Typography>
                        <Typography color="#9C9897">
                            {prestamo.simboloMoneda}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={8}  >
                        <Typography>
                            Cliente
                        </Typography>
                        <Typography color="#9C9897">
                            {prestamo.nombreCompletoCliente}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}  >
                        <Typography>
                            CTA CTE
                        </Typography>
                        <Typography color="#9C9897">
                            {"$"+prestamo.saldo.toLocaleString("es-UY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} >
                        <Typography >
                            Capital Financiado
                        </Typography>
                        <Typography color="#9C9897">
                            {"$"+prestamo.importeFinanciado.toLocaleString("es-UY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} >
                        <Typography >
                            Capital Prestado
                        </Typography>
                        <Typography color="#9C9897">
                            {"$"+prestamo.importePrestado.toLocaleString("es-UY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} >
                        <Typography >
                            Tasa
                        </Typography>
                        <Typography color="#9C9897">
                            {(prestamo.tasa)*100}%
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} >
                        <Typography >
                            Cantidad de Cuotas
                        </Typography>
                        <Typography color="#9C9897">
                            {prestamo.cantidadCuotas}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} >
                        <Typography >
                            Cantidad de Cuotas Canceladas
                        </Typography>
                        <Typography color="#9C9897">
                            {prestamo.cuotasCanceladas}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={2} >
                        <Typography >
                            Valor cuota
                        </Typography>
                        <Typography color="#9C9897">
                            {"$"+prestamo.valorCuota.toLocaleString("es-UY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={2} >
                        <Typography >
                            Valor Ult. cuota
                        </Typography>
                        <Typography color="#9C9897">
                            {"$"+prestamo.valorUltimaCuota.toLocaleString("es-UY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} >
                        <Typography >
                            Cantidad Anualidades
                        </Typography>
                        <Typography color="#9C9897">
                            {prestamo.cantidadAnualidades}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} >
                        <Typography >
                            Cantidad de Anualidades Canceladas
                        </Typography>
                        <Typography color="#9C9897">
                            {prestamo.anualidadesCanceladas}
                        </Typography>
                    </Grid>
                    <Grid item xs={0} lg={4}></Grid>
                    <Grid item xs={12} lg={4} >
                        <Typography >
                            Importe Total Abonado
                        </Typography>
                        <Typography color="#9C9897">
                            {"$"+prestamo.importeCancelado.toLocaleString("es-UY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} >
                        <Typography >
                            Importe Descontado
                        </Typography>
                        <Typography color="#9C9897">
                            {"$"+prestamo.importeDescuento.toLocaleString("es-UY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} >
                        <Typography >
                            Saldo Pendiente
                        </Typography>
                        <Typography color="#9C9897">
                            {"$"+prestamo.saldoPendientePrestamo.toLocaleString("es-UY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <hr />{/*LINEA HORIZONTAL*/}
                    </Grid>
                    <Grid item xs={12} lg={12} style={{paddingTop: '0px', paddingBottom: '1%'}} >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            marginLeft="2%"
                            align="center"
                        >
                            <b>Datos del Automotor</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputComponent sx={{width: "90%"}}
                                        label="Marca"
                                        type="outlined"
                                        required={true}
                                        estado={marca}
                                        setEstado={setMarca}
                                        leyendaError= "Debe ingresar una marca"
                                        expresionRegular={er.marca}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputComponent sx={{width: "90%"}}
                                        label="Modelo"
                                        type="outlined"
                                        required={true}
                                        estado={modelo}
                                        setEstado={setModelo}
                                        leyendaError= "Debe ingresar un modelo"
                                        expresionRegular={er.textoNumeroConEspacios}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputComponent sx={{width: "90%"}}
                                        label="Matrícula"
                                        type="outlined"
                                        required={true}
                                        estado={matricula}
                                        setEstado={setMatricula}
                                        leyendaError= "Debe ingresar una matrícula"
                                        expresionRegular={er.textoNumeroConEspacios}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <SelectComponent label="Año"
                                         items={aniosList}
                                         required={true}
                                         estado={anio}
                                         setEstado={setAnio}
                                         sx={{'& > :not(style)': {width: '90%'}}}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputComponent sx={{width: "90%"}}
                                        label="Kilometraje"
                                        type="outlined"
                                        required={true}
                                        estado={kilometraje}
                                        setEstado={setKilometraje}
                                        leyendaError= "Debe ingresar un kilmetraje válido"
                                        expresionRegular={er.numericoConCero}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControlLabel control={
                            <Switch
                                checked={checked}
                                onChange={handleChangeSwitch}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                                  label="Control Mecánico"
                                  labelPlacement="bottom"
                                  sx={{ width: "70%"}}
                        />

                    </Grid>
                    <Grid item xs={12} >
                        <hr />{/*LINEA HORIZONTAL*/}
                    </Grid>
                </Grid>

            </Box>
            <Stack spacing={2} direction="row">
                <ButtonComponent label="Aceptar" type="contained" onClick={handleAceptar}/>
                <ButtonComponent label="Cancelar" type="outlined" onClick={handleCancelar}/>
            </Stack>
        </Box>

    )
};

export default AutomotorModificarComponent;