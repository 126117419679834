export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  const accessToken = localStorage.getItem("token");

  if (user && accessToken) {
    // For Spring Boot back-end
     return {
       Authorization: "Bearer " + accessToken,
       'Content-Type': 'application/json',
     };

    // for Node.js Express back-end
    //return {"x-access-token": accessToken};
  } else {
    return {};
  }
}