import {useNavigate} from "react-router-dom";
import {Fab} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, {useEffect, useState} from "react";
import GridComponent from "../../components/grid.component";
import {useDispatch, useSelector} from "react-redux";
import {proyeccionIngresos} from "../../store/slices/reporte.slice";
import {meses} from "../../common/fechaFormat";

const ReporteProyeccionIngresosComponent = () => {

    const { proyeccionIngresosList } = useSelector(state => state.reportes);
    const [loading, setLoading] = useState(true);
    var contRowId = 0;
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(proyeccionIngresos())
            .then(()=>{
                setLoading(false)
            })
            .catch(()=>{});
    }, [dispatch]);

    const columnsPI = [
        {
            field:'anio',
            headerName: 'Año',
            width: 70
        },
        {   field: 'mes',
            headerName: 'Mes',
            width: 100,
            valueGetter: (param) => {
                if(param.value){
                    return meses[param.value]
                }
                else
                    return "Atrasado"
            }
        },
        {   field: 'importeCuotaMensual',
            headerName: 'Cuota mensual proyectado',
            type: 'number',
            width: 200,
            editable: false,
            valueGetter: (param) => param.value ===null ? "$ " +0 : "$ " + param.value.toLocaleString("es-UY")
        },
        {   field: 'importeCuotaAnual',
            headerName: 'Cuota anual proyectado',
            type: 'number',
            width: 200,
            editable: false,
            valueGetter: (param) => param.value ===null ? "$ " +0 : "$ " + param.value.toLocaleString("es-UY")
        },
        {   field: 'importeTotal',
            headerName: 'Importe proyectado',
            type: 'number',
            width: 200,
            editable: false,
           valueGetter: (param) => param.value ===null ? "$ " + 0 : "$ " + param.value.toLocaleString("es-UY")
        },
 ];

    const handleAtras = () => {
        return navigate(-1);
    }

    return (
        <Container maxWidth="lg" sx={{ mt:12, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} >
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: "100%",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}  sm={11} >
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                >

                                    <b>Reporte de Proyección de Ingresos</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}  sm={1} >
                                <Fab sx={{mb: 0, ml: 0}}
                                     title={"Volver atras"}
                                     size = "medium"
                                     color="primary"
                                     aria-label="add"
                                     onClick={handleAtras}
                                >
                                    <ArrowBackIcon/>
                                </Fab>
                            </Grid>
                            <Grid item xs={12} style={{paddingTop: '0px'}} >
                                <hr  paddingTop={5}/>{/*LINEA HORIZONTAL*/}
                            </Grid>
                        </Grid>

                        <GridComponent rows={loading === true ? [] : proyeccionIngresosList} columns={columnsPI} rowHeight={30}  pageSize={100} getRowId={(row) => contRowId++} loading={loading}/>

                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
export default ReporteProyeccionIngresosComponent