import React, {useEffect} from 'react';
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import HeaderComponent from "../components/header/header.component";
import NavbarComponent from "../components/navbar/navbar.component";
import {Outlet, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import notifier from "../common/notifier";
import FooterComponent from "../components/footer.component";


const MainLayout = ({ component }) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {isLoggedIn} = useSelector(state => state.autenticacion)
    const {message, severity, duration} = useSelector(state => state.mensaje)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    notifier();
/*
    useEffect(()=> {
        console.log("Mensaje desde MainLayout")
        console.log("Mensaje: "+message);
        if(message !== '' && message !== null)
            enqueueSnackbar(message, { variant: severity});

    }, [message, enqueueSnackbar, dispatch])

*/
    useEffect(() => {
        if(!isLoggedIn)
            return navigate("/login")

    },[isLoggedIn]);
    useEffect(()=>{
        window.scroll(0,0)
    },[])

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    //console.log("mainLayout")
    return (
            <Box sx={{ display: 'flex'}} >
                <CssBaseline />
                <HeaderComponent />
                <NavbarComponent />

                <Box
                    component="main"
                    id="main" //Este id es importante, la funcion de scrollToTop utiliza este id
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Outlet/>
                    <FooterComponent/>
                </Box>

            </Box>
    );
}

/*
const MainPageComponent = () => {
    return (

            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <HeaderComponent />
                <NavbarComponent />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >

                    <Routes>
                            <Route exact path={"/dashboard"} element={<DashboardComponent />}/>
                            <Route exact path="/prestamos" element={<PrestamosComponent/>}/>
                            <Route exact path="/clientes" element={<ClientesComponent />}/>
                            <Route exact path="/clientes/nuevo" element={<ClienteComponent type="nuevo" />} />
                    </Routes>


                </Box>

            </Box>

    );
};
*/
export default MainLayout;