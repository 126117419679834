import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {EstadoPrestamo} from "../../../../../common/enum/estadoPrestamo";
import SelectComponent from "../../../../../components/select.component";
import {Box, Chip, Typography} from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {expresiones as er} from "../../../../../common/expresionesRegulares";
import InputComponent from "../../../../../components/input.component";
import {setearMensaje} from "../../../../../store/slices/snackbar.slice";
import {cambioEstadoPrestamo} from "../../../../../store/slices/prestamo.slice";

const PopupNuevaComunicacionPrestamoComponent = (props) => {

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');

    const {
        handleNuevaComunicacion,
        openNuevaComunicacion,
        setOpenNuevaComunicacion,
        mensajeComunicacion,
        setMensajeComunicacion,
    } = props;

    const handleClose = () => {
        setOpenNuevaComunicacion(false);
    };

    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={openNuevaComunicacion }
            onClose={handleClose}
            >
            <DialogTitle> <b>Agregar Comunicación Prestamo </b> </DialogTitle>
            <Divider  sx={{ mt:'0ch', mb:'1ch'}}/>
            <DialogContent>
                <Grid container spacing={1}>
                <InputComponent sx={{width: "100%"}}
                                label="Mensaje"
                                type="outlined"
                                required={false}
                                estado={mensajeComunicacion}
                                setEstado={setMensajeComunicacion}
                                leyendaError= "Supero el máximo de caracteres posible"
                                expresionRegular={er.texto255Vacio}
                                multiline={true}
                                maxRows={3}
                                tipo={"text"}
                                inputProps={{ maxLength:"255"}}
                />
                </Grid>
            </DialogContent>
            <DialogActions >
                <Button variant={"contained"} onClick={handleNuevaComunicacion} disabled={mensajeComunicacion.valor ==="" ? true : false} >Enviar</Button>
                <Button variant={"outlined"} onClick={handleClose}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    )

}

export default PopupNuevaComunicacionPrestamoComponent