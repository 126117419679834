import {createSlice} from "@reduxjs/toolkit";
import {clearMessage, setearMensaje} from "./snackbar.slice";
import {errores} from "../../common/errores";
import {logout} from "./autenticacion.slice";
import metodoPagoService from "../../services/metodo.pago.service";

const initialState = {
    metodosPagosList: []
}

export const metodoPagoSlice = createSlice ({
    name: "metodosPagos",
    initialState,
    reducers: {
        setMetodosPagosList: (state, action) => {
            state.metodosPagosList = action.payload;
        },
        metodosPagosError: (state) => {
            state.metodosPagosList = [];
        },
    }
});

const {setMetodosPagosList, metodosPagosError} = metodoPagoSlice.actions;
export default metodoPagoSlice.reducer;

export const getAllMetodosPagos = (pagoId, fechaDesde, fechaHasta, formaPagoId, ubicacionEfectivo) => (dispatch) => {
    return metodoPagoService.fetchAllMetodosPagos(pagoId, fechaDesde, fechaHasta, formaPagoId, ubicacionEfectivo).then(
        (data) => {
            dispatch(setMetodosPagosList(data));
            dispatch(clearMessage());
            return Promise.resolve();
        },).catch(
        (error) => {
            dispatch(metodosPagosError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                dispatch(logout());
            }
            return Promise.reject();
        }
    )
}

export const putCambioUbicacionEfectivo = (ubicacion, ids) => (dispatch) => {
    return metodoPagoService.putCambioUbicacionEfectivo(ubicacion, ids).then(
        (data) => {
           return Promise.resolve()
        }, ).catch(
            (error) => {
                dispatch(metodosPagosError());
                const mensaje = {
                    message: error.message,
                    severity: "error",
                }
                dispatch(setearMensaje(mensaje));

                if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                    dispatch(logout());
                }
                return Promise.reject();
            }
        )
}

export const exportAllMetodosPagos = (formato, pagoId, fechaDesde, fechaHasta, formaPagoId, ubicacionEfectivo) => (dispatch) => {
    return metodoPagoService.exportAllMetodosPagos(formato, pagoId, fechaDesde, fechaHasta, formaPagoId, ubicacionEfectivo).then(
        (data) => {
            return Promise.resolve(data);
        },).catch(
        (error) => {
            dispatch(metodosPagosError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                dispatch(logout());
            }
            return Promise.reject();
        }
    )
}