import React, { createContext, useContext, useState } from 'react'

const StateContext = createContext();

const initialState = {
    menu: true,
}
//Esto es un provider hecho manual (contexto)
//TODO migrar a redux
export const ContextProvider = ({ children  }) => {

    const [open, setOpen] = useState(true);
    const [title, setTitle] = useState("Gestion de Cuentas");
    return (
        <StateContext.Provider
            value={{open,
                    setOpen,
                    title,
                setTitle,
            }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);
