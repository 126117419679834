import React, {useCallback, useEffect} from 'react'
import {Link, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import GridComponent from "../../../components/grid.component";
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import {getAllFormasPagos, getFormaPago} from "../../../store/slices/forma.pago.slice";

const FormasPagosComponent = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getAllFormasPagos()).then(()=>{}).catch(()=>{});
    }, [dispatch]);

    const { formasPagosList } = useSelector(state => state.formasPagos);

    useEffect(() => {
        dispatch(getAllFormasPagos()).then(()=>{}).catch(()=>{});
    }, [dispatch]);


    const columns = [
        {
            field: 'actions',
            type: 'actions',
            width: 110,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Editar"
                    onClick={modificarFormaPago(params.id)}
                />,
            ],
        },
        {   field: 'id',
            headerName: '#',
            width: 55
        },
        {
            field: 'nombre',
            headerName: 'Nombre',
            width: 250,
            editable: false,
        },
        {
            field: 'nombreReferencia1',
            headerName: 'Nombre Referencia 1',
            width: 250,
            editable: false,
        },
        {
            field: 'nombreReferencia2',
            headerName: 'Nombre Referencia 2',
            width: 250,
            editable: false,
        },
    ];

    const modificarFormaPago = useCallback(
        (id) => () => {
            dispatch(getFormaPago(id)).then( () => {
                navigate("/formas_pagos/modificar/"+id);
            }).catch( (error) => {});

        }
    );

    return (

        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: "100%",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}  sm={11} >
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                >
                                    <b>Formas de Pago</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}  sm={1} >
                                <Fab sx={{mb: 2, ml: 0}} size = "medium" color="primary" aria-label="add" component= {Link} to="/formas_pagos/nuevo" >
                                    <AddIcon   />
                                </Fab>
                            </Grid>
                        </Grid>
                         <GridComponent  rows={formasPagosList} columns={columns} rowHeight={30}  pageSize={20} disableToolbar={true} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default FormasPagosComponent;