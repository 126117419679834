import React, {useState} from 'react';
import SearchIcon from '@mui/icons-material/Search'
import {styled, alpha, useTheme} from "@mui/material/styles";
import {InputBase, MenuItem, MenuList, Popper} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getBuscar} from "../../../store/slices/buscar.slice";
import {useNavigate} from "react-router-dom";
import Transitions from "../../extended/transitions";
import {useRef} from "react";
import Paper from "@mui/material/Paper";
import {useCallback} from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
}));

const SearchComponent = (callback, deps) => {
    const dispatch = useDispatch();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const {buscarClientesList, buscarPrestamosList} = useSelector(state => state.buscar)
    const [valor, setValor] = useState("")
    const [overP, setOverP] = useState(false)
    const navigate = useNavigate();
    //let prestamosList = [];
    let clientesList = [];

    const onChange = (e) => {
        setValor(e.target.value);
        if(e.target.value.length === 0)
            setOpen(false);
    }
    const buscar = () =>{
        console.log("consulta: "+valor);
        if(valor.length !== 0) {
            dispatch(getBuscar(valor)).then(() => {
                //console.log("Busqueda: " + buscarList);
                //prestamosList = buscarList.filter((param)=> param.tipo ==="PRESTAMO")
                setOpen(true)
            }).catch(() => {
            });
        }
    }
    const onBlur = useCallback(
        () => {
        if(valor.length !== 0 && !overP)
            setOpen(!open);
    });

    const ir = useCallback(
        (object) => () => {
            console.log("ir")
            if(object.tipo === "PRESTAMO"){
                console.log("ir prestamo: "+object.id)
                navigate("/Prestamos/"+object.id)
            }
            else if(object.tipo === "CLIENTE"){
                navigate("/Clientes/"+object.id)
            }
            setOpen(false);
            setValor("");
        }
    );
    const overPopper = () => {
        setOverP(true)
    };
    const outPopper = () => {
        setOverP(false)
    }


    return (
        <>
        <Search
            ref={anchorRef}>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Buscar…"
                inputProps={{ 'aria-label': 'search' }}
                value={valor}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onBlur}
                onKeyUp={buscar}

            />


        </Search>
        <Popper
            onMouseOver={overPopper}
            onMouseLeave={outPopper}
            placement="bottom-end"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            popperOptions={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 9]
                        }
                    }
                ]
            }}
        >
            {({ TransitionProps }) => (
                <Transitions type="fade" in={open} {...TransitionProps}>
                    {open && (
                        <Paper
                            sx={{
                                minWidth: 320,
                                maxWidth: 400,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 300
                                }
                            }}
                        >

                            <MenuList>
                                {buscarPrestamosList.length + buscarClientesList.length === 0 && <MenuItem key={0} disabled={true}>No hay resultados</MenuItem>}
                                {buscarPrestamosList.length + buscarClientesList.length !== 0 &&
                                    <Divider sx={{ my: 0.5 }} >
                                        <Typography variant="body2" color="text.secondary">
                                            Prestamos
                                        </Typography>
                                    </Divider> }
                                {(buscarPrestamosList.length === 0 && buscarClientesList.length !== 0) && <MenuItem key={0} disabled={true}>No hay resultados</MenuItem>}
                                {
                                    buscarPrestamosList.map(function (object, i) {
                                        return <MenuItem key={i} onClick={ir(object)}> <strong>PR: #{object.numeroPrestamo ? object.numeroPrestamo : object.id}{object.serie}</strong> - {object.descripcion}</MenuItem>;
                                    })
                                }
                                {buscarPrestamosList.length + buscarClientesList.length !== 0 &&
                                    <Divider sx={{ my: 0.5 }} >
                                        <Typography variant="body2" color="text.secondary">
                                            Clientes
                                        </Typography>
                                    </Divider> }
                                {(buscarClientesList.length === 0 && buscarPrestamosList.length !== 0 )&& <MenuItem key={0} disabled={true}>No hay resultados</MenuItem>}
                                {
                                    buscarClientesList.map(function(object, i){
                                        return <MenuItem key={i} onClick={ir(object)}> <strong>{object.documento}</strong> - {object.nombreCompleto}</MenuItem>;
                                    })
                                }
                            </MenuList>
                        </Paper>
                    )}
                </Transitions>
            )}
        </Popper>
        </>
    );
};


export default SearchComponent;