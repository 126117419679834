 import {useNavigate} from 'react-router-dom'
import React, {useEffect} from 'react'
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useDispatch} from "react-redux"
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Fab} from "@mui/material";




const SoporteComponent = () => {

    const navigate = useNavigate();

    const handleAtras = () => {
        return navigate(-1);
    }

    const dispatch = useDispatch();


    useEffect(() => {

    }, [dispatch]);

    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: "100%",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}  sm={11} >
                            <Typography
                                component="h1"
                                variant="h4"
                                color="inherit"
                                noWrap
                                marginLeft="2%"
                            >
                                <b>Soporte</b>
                            </Typography>
                        </Grid>
                            <Grid item xs={6}  sm={1} >
                                <Fab sx={{mb: 0, ml: 0}}
                                     title={"Volver atras"}
                                     size = "medium"
                                     color="primary"
                                     aria-label="add"
                                     onClick={handleAtras}
                                >
                                    <ArrowBackIcon/>
                                </Fab>
                            </Grid>
                    </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
export default SoporteComponent
