import React, {useEffect} from 'react';
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import {useStateContext} from "../../../../contexts/ContextProvider";
import PropTypes from "prop-types";
import TabPanel from "../../../../components/tabpanel.component";
import {useSelector} from "react-redux";
import AutomotorNuevoComponent from "./automotor.nuevo.component";
import Typography from "@mui/material/Typography";
import AutomotorRefinanciarComponent from "./automotor.refinanciar.component";

const PrestamoRefinanciarComponent = (props) => {

    const {open, setOpen, title, setTitle} = useStateContext();
    useEffect(() => {
        setTitle("Nuevo Prestamo");
    });
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const { message } = useSelector(state => state.mensaje);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    //TODO: validaciones / carga de datos / chequeo de validaciones prvio a guardar.

    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',


                        }}
                        elevation={3}>
                        <Box sx={{ width: '100%' }}>
                            <Typography
                                component="h1"
                                variant="h4"
                                color="inherit"
                                noWrap
                            >
                                <b>Refinanciar Préstamo</b>
                            </Typography>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Automotor" {...a11yProps(0)} />
                                </Tabs>
                            </Box>
                                <TabPanel value={value} index={0}>
                                    <AutomotorRefinanciarComponent/>
                                </TabPanel>
                        </Box>
                    </Paper>

        </Container>
    );
};

export default PrestamoRefinanciarComponent;

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}