import React, {useCallback, useEffect, useMemo} from 'react'
import Container from '@mui/material/Container';
import {Link, Navigate, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useStateContext} from "../../contexts/ContextProvider";
import GridComponent from "../../components/grid.component";
import {GridActionsCellItem, GridColDef} from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility'
import {useDispatch, useSelector} from "react-redux";
import {getAllClientes} from "../../store/slices/cliente.slice";
import {Chip, Fab} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Typography from "@mui/material/Typography";
import {TipoCliente} from "../../common/enum/tipoCliente";
import {EstadoCliente} from "../../common/enum/estadoCliente";


const ClientesComponent = () => {
    const navigate = useNavigate();

    const verCliente = useCallback(
        (id) => () => {
            //console.log("El id es: "+ id);
            navigate("/clientes/"+id);
        }
    );
    const modificarCliente = useCallback(
        (id) => () => {
            //console.log("El id es: "+ id);
            navigate("/clientes/modificar/"+id);
        }
    );
    const eliminarCliente = useCallback(
        (id) => () => {
            //console.log("El id es: "+ id);
            //navigate("/clientes/"+id);
        }
    );
    const tipoCliente = (tipo) => {
        const dato = {
            value: tipo,
            color: "string",
            variant: "outlined"
        }
        if (tipo === TipoCliente.PERSONA) {
            dato.value = "Persona"
            dato.color = "warning"
            dato.variant = "outlined"

        } else if (tipo === TipoCliente.EMPRESA) {
            dato.value = "Empresa"
            dato.color = "info"
            dato.variant = "outlined"
        }
        return (
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
            />
        );
    };
    const estadoCliente = (estado) => {
        const dato = {
            value: estado,
            color: "string",
            variant: "outlined"
        }
        if (estado === EstadoCliente.ACTIVO) {
            dato.value = "Activo"
            dato.color = "success"
            dato.variant = "outlined"

        } else if (estado === EstadoCliente.BLOQUEADO) {
            dato.value = "Bloqueado"
            dato.color = "error"
            dato.variant = "filled"
        }
        else if (estado === EstadoCliente.BAJA) {
            dato.value = "Baja"
            dato.color = "error"
            dato.variant = "filled"
        }
        return (
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
            />
        );
    };

    const columns = [
        {
            field: 'actions',
            type: 'actions',
            width: 100,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<VisibilityIcon />}
                    label="Ver"
                    onClick={verCliente(params.id)}
                />,
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Editar"
                    onClick={modificarCliente(params.id)}
                />,
                /*
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Borrar"
                    onClick={eliminarCliente(params.id)}
                />,

                 */
            ],
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 20,
            editable: false,
        },
        {
            field: 'documento',
            headerName: 'Documento',
            width: 100,
            editable: false,
            renderCell: (param) => `${param.row.tipoDocumento} ${param.value}`
        },
        {
            field: 'nombreCompleto',
            headerName: 'Nombre',
            width: 200,
            editable: false,
        },
        {
            field: 'estado',
            headerName: 'Estado',
            width: 100,
            editable: false,
            renderCell: (param) => (
                estadoCliente(param.value)
            ),
        },
        {
            field: 'tipo',
            headerName: 'Tipo',
            width: 100,
            editable: false,
            renderCell: (param) => (
                tipoCliente(param.value)
            ),
        },
        {
            field: 'telefono',
            headerName: 'Teléfono',
            width: 120,
            editable: false,
        },
    ];

    const {open, setOpen, title, setTitle} = useStateContext();
    const {clientesList} = useSelector(state => state.clientes)
    const dispatch = useDispatch();
    const { message } = useSelector(state => state.mensaje);

    const nuevoCliente = (e) => {
        e.preventDefault();
        //console.log("estoy agregando un cliente");
        return navigate("/clientes/nuevo");
    }

    useEffect(() => {
        setTitle("Clientes");
        dispatch(getAllClientes()).then(()=>{}).catch(()=>{});

    },[dispatch]);


    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}  sm={11} >
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                >
                                    <b>Clientes</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}  sm={1} >
                            <Fab sx={{mb: 2, ml: 0}} size = "medium" color="primary" aria-label="add" component= {Link} to="/clientes/nuevo" >
                                <AddIcon   />
                            </Fab>
                            </Grid>
                        </Grid>
                        <GridComponent rows={clientesList} columns={columns} rowHeight={30} pageSize={20} />

                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
export default ClientesComponent
