import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import {
    Chip,
    Collapse,
    FormControl, InputAdornment,
    InputLabel,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import TextboxComponent from "../../../components/textbox.component";
import Grid from "@mui/material/Grid";
import {formatDDMMYYYY} from "../../../common/fechaFormat";
import {emitirRecibo, pagoAdelantoNuevo, pagoNuevo} from "../../../store/slices/pago.slice";
import {setearMensaje} from "../../../store/slices/snackbar.slice";
import {downloadFile} from "../../../common/downloadFile";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useCallback, useState} from "react";
import {ContextProvider, useStateContext} from "../../../contexts/ContextProvider";
import {cambiarContraseña} from "../../../store/slices/autenticacion.slice";

const PopupCambioContrasenia= (props) => {

    const {dispatch} = props;


    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [passwordActual, setPasswordActual] = useState("");
    const [passwordNueva, setPasswordNueva] = useState("");
    const [passwordNuevaConfir, setPasswordNuevaConfir] = useState("");
    const [showPasswordActual, setShowPasswordActual] = useState (false);
    const [showPasswordNueva, setShowPasswordNueva] = useState (false);
    const [showPasswordNuevaConfir, setShowPasswordNuevaConfir] = useState (false);

    const onChangePasswordActual = (e) => {
        e.preventDefault();
        const password = e.target.value;
        setPasswordActual(password);
    };
    const onChangePasswordNueva = (e) => {
        e.preventDefault();
        const password = e.target.value;
        setPasswordNueva(password);
    };
    const onChangePasswordNuevaConfir = (e) => {
        e.preventDefault();
        const password = e.target.value;
        setPasswordNuevaConfir(password);
    };
    const handleClicShowPassword = useCallback(
        (index) => ()=>{
            if(index === 0)
                setShowPasswordActual(!showPasswordActual);
            else if(index === 1)
                setShowPasswordNueva(!showPasswordNueva);
            else if(index === 2)
                setShowPasswordNuevaConfir(!showPasswordNuevaConfir);
    });

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleConfirmar = () => {
        if(passwordActual.length > 0 && passwordNueva.length > 0 && passwordNuevaConfir.length > 0){
            const usuarioPassword = {
                id: props.idUsuario,
                usuario: props.usuario,
                contraseniaActual: passwordActual,
                contraseniaNueva: passwordNueva,
                contraseniaConfirmacion: passwordNuevaConfir,
            }
            dispatch(cambiarContraseña(usuarioPassword)).then(() => {
                const mensaje = {
                    message: "Cambio de contraseña realizada con éxito",
                    severity: "success",
                }
                dispatch(setearMensaje(mensaje));
                props.setOpen(false)
            }).catch(() => {});
        }
        else{
            const mensaje = {
                message: "Las contraseñas no deben ser vacías.",
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));
        }
    };


    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle>
                <Grid container spacing={1} >
                    <Grid item xs={12} sx={{justifyContent: "center", display: "flex"}} >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main', }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5" marginTop={"3%"} >
                            Cambiar contraseña
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                        <Grid container spacing={1} >
                            <Grid item xs={12} marginTop={"1%"}>
                                <FormControl variant="outlined" fullWidth={"30%"}>
                                    <InputLabel htmlFor="outlined-adornment-password">Contraseña actual</InputLabel>
                                    <OutlinedInput
                                        required
                                        name="password_actual"
                                        label="Contraseña actual"
                                        type={showPasswordActual ? 'text' : 'password'}
                                        id="password_actual"
                                        onChange={onChangePasswordActual}
                                        autoComplete="current-password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClicShowPassword(0)}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPasswordActual ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} marginTop={"1%"}>
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel htmlFor="outlined-adornment-password">Contraseña nueva</InputLabel>
                                    <OutlinedInput
                                        required
                                        name="password_nueva"
                                        label="Contraseña nueva"
                                        type={showPasswordNueva ? 'text' : 'password'}
                                        id="password_nueva"
                                        onChange={onChangePasswordNueva}
                                        autoComplete="current-password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClicShowPassword(1)}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPasswordNueva ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} marginTop={"1%"}>
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel htmlFor="outlined-adornment-password">Contraseña nueva confirmación</InputLabel>
                                    <OutlinedInput
                                        required
                                        name="password_nueva_confir"
                                        label="Contraseña nueva confirmación"
                                        type={showPasswordNuevaConfir ? 'text' : 'password'}
                                        id="password_nueva_confir"
                                        onChange={onChangePasswordNuevaConfir}
                                        autoComplete="current-password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClicShowPassword(2)}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPasswordNuevaConfir ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{justifyContent: "center"}} >
                <Button variant={"contained"} onClick={handleConfirmar}>Confirmar</Button>
                <Button variant={"outlined"} onClick={handleClose}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
}
export default  PopupCambioContrasenia