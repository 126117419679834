import {useLocation, useNavigate} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useDispatch} from "react-redux"
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Fab} from "@mui/material";
import PopupCambioContrasenia from "./popup.cambio_contrasenia.commponent";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {getUsuario} from "../../../store/slices/autenticacion.slice";
import TextboxComponent from "../../../components/textbox.component";




const PerfilComponent = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [openCambiarContrasenia, setOpenCambiarContrasenia] = useState(location.state ? location.state.openCambiarContrasenia : false);

    const usuario = getUsuario();
    const nombre = usuario ? usuario.nombre + " " + usuario.apellido : null;
    const cargo = usuario ? usuario.cargo : null;

    const handleAtras = () => {
        return navigate(-1);
    }
    const handleCambiarContrasenia = () => {
        setOpenCambiarContrasenia(true);
    }
    const dispatch = useDispatch();


    useEffect(() => {

    }, [dispatch]);

    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: "100%",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}  sm={8} >
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                >
                                    <b>Perfil</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}  sm={3} >
                                <Button variant={"contained"} onClick={handleCambiarContrasenia}>Cambiar contraseña</Button>
                            </Grid>
                            <Grid item xs={6}  sm={1} >
                                <Fab sx={{mb: 0, ml: 0}}
                                     title={"Volver atras"}
                                     size = "medium"
                                     color="primary"
                                     aria-label="add"
                                     onClick={handleAtras}
                                >
                                    <ArrowBackIcon/>
                                </Fab>
                            </Grid>
                        </Grid>
                        <Divider  sx={{ mt:'0ch', mb:'2ch'}}/>
                        <Grid container spacing={3}>
                            <Grid item xs={6}  sm={12} >
                                <Typography>
                                    Usuario: {usuario.usuario}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}  sm={12} >
                                <Typography>
                                    Nombre: {nombre}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}  sm={12} >
                                <Typography>
                                    Cargo: {cargo}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <PopupCambioContrasenia
                dispatch={dispatch}
                idUsuario={usuario.id}
                usuario={usuario.usuario}
                open={openCambiarContrasenia}
                setOpen={setOpenCambiarContrasenia}
            />
        </Container>
    );
}
export default PerfilComponent
