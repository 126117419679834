import React, {useEffect} from 'react';
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {useStateContext} from "../../../contexts/ContextProvider";
import PersonaNuevaComponent from "./persona.nueva.component";
import PropTypes from "prop-types";
import TabPanel from "../../../components/tabpanel.component";
import EmpresaNuevaComponent from "./empresa.nueva.component";
import Typography from "@mui/material/Typography";

const ClienteComponent = (props) => {

    const {open, setOpen, title, setTitle} = useStateContext();
    useEffect(() => {
        setTitle("Nuevo Cliente");
    });
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>

                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',


                        }}
                        elevation={3}>
                        <Box sx={{ width: '100%' }}>
                            <Typography
                                component="h5"
                                variant="h5"
                                color="inherit"
                                noWrap
                            >
                                Nuevo Cliente
                            </Typography>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                                    <Tab label="Persona" {...a11yProps(0)} />
                                </Tabs>
                            </Box>
                                <TabPanel value={value} index={0}>
                                    <PersonaNuevaComponent/>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <EmpresaNuevaComponent/>
                                </TabPanel>
                        </Box>
                    </Paper>

        </Container>
    );
};

export default ClienteComponent;

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}