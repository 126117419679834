import Grafica from "../../../../components/dashboard/grafica";
import * as React from "react";


const ResumenGraficaComponent = (props) => {


    return (
        <Grafica
            title="Resumen de pagos"
            subheader=""
            type={"donut"}
            chartLabels={ ["Cancelado", "Pendiente"]}
            chartData={[props.importeCancelado, props.importePendiente]}
        />
    );
}
export default ResumenGraficaComponent;
