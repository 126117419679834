import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login } from "../../store/slices/autenticacion.slice";
import {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import {Alert, FormControl, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";


const theme = createTheme();
const LOGIN_URL = '/login';

export default function LoginComponent(props) {

    const form = useRef();
    const checkBtn = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);//esto es para un efecto de cargar en boton
    const [showPassword, setShowPassword] = useState (false);

    const { isLoggedIn } = useSelector(state => state.autenticacion);
    const [message, setMessage]  = useState("");

    const dispatch = useDispatch();

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };
    const handleClicShowPassword = () => {
        setShowPassword(!showPassword);
    }
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLogin = (e) => {
        e.preventDefault();

        //setLoading(true); esto es para un efecto de cargar en boton

        //form.current.validateAll();

       // if (checkBtn.current.context._errors.length === 0) {
            dispatch(login(username, password))
                .then(() => {
                    //props.history.push("/");
                    //window.location.reload();
                    setMessage("");
                })
                .catch((mensaje) => {
                    setMessage(mensaje.message);
                    //setLoading(false);
                });
        //} else {
          //  setLoading(false);
        //}
    };

    if (isLoggedIn) {
        return <Navigate to="/" />;
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                    <Paper sx={{
                        marginTop: 12,
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        alignContent: 'center',
                        alignSelf: 'center',
                        textAlign: 'center',
                    }} elevation={5}>

                        <Box component="form" onSubmit={handleLogin}  sx={{ mt: 1}}>
                        <Grid container spacing={1} >
                            <Grid item xs={12} sx={{justifyContent: "center", display: "flex"}} >
                                <Avatar sx={{ m: 1, bgcolor: 'primary.main', }}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5" marginTop={"4%"}>
                                    Sign in
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="user"
                                        label="Usuario"
                                        name="usuario"
                                        //autoComplete="email"
                                        onChange={onChangeUsername}
                                        autoFocus
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                        <OutlinedInput
                                            required
                                            name="password"
                                            label="Contraseña"
                                            type={showPassword ? 'text' : 'password'}
                                            id="password"
                                            onChange={onChangePassword}
                                            autoComplete="current-password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClicShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Log In
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                {message !== "" && (
                    <Alert sx={{marginTop: "3%"}} severity = "error">{message}</Alert>)}
            </Container>
        </ThemeProvider>
    );
}