// @mui
import PropTypes from 'prop-types';
import {Box, Stack, Link, Card, Button, Divider, Typography, CardHeader, Chip} from '@mui/material';
// utils
//import { fToNow } from '../../../utils/formatTime';
import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {formatDDMMYYYY} from "../../common/fechaFormat";
import Paper from "@mui/material/Paper";
// components
//import Iconify from '../../../components/Iconify';
//import Scrollbar from '../../../components/Scrollbar';

// ----------------------------------------------------------------------

CuotasVencidas.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    list: PropTypes.array.isRequired,
};

export default function CuotasVencidas({ title, subheader, list, ...other }) {
    return (
        <Card {...other}>
            <CardHeader
                title={<Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15 }} noWrap>
                    <b>Cuotas próximas a vencer</b>
                         </Typography>}
            />
            <Stack spacing={0} sx={{ pl: 2, pr: 2, pb: 2, overflowX: 'auto' }}  height={424}>
                {list.map((cuota) => (
                    <CuotaItem key={cuota.id} cuota={cuota} />
                ))}
            </Stack>
        </Card>
    );
}

// ----------------------------------------------------------------------

CuotaItem.propTypes = {
    news: PropTypes.shape({
        description: PropTypes.string,
        image: PropTypes.string,
        postedAt: PropTypes.instanceOf(Date),
        title: PropTypes.string,
    }),
};

function CuotaItem({cuota}) {
    const navigate = useNavigate();
    const verPrestamo = useCallback(
      (idPrestamo) => () => {
          navigate("/prestamos/"+idPrestamo);
    });
    const estadoCuota = (estado, vencimiento) => {
        const venci = new Date(vencimiento +"T00:00:00")
        const hoy = new Date()
        hoy.setHours(0,0,0,0)
        console.log("hoy: "+ hoy+ "vencimiento: "+venci)
        console.log("estadoCUOTA: " + estado)
        const dato = {
            value: estado,
            color: "secondary",
            variant: "outlined"
        }
        if (estado === "PENDIENTE" && hoy > venci) {
            dato.value = "Vencida"
            dato.color = "error"
            dato.variant = "filled"

        }
        else if (estado === "PENDIENTE" && hoy.getTime() === venci.getTime()) {
            dato.value = "Vence hoy"
            dato.color = "warning"
            dato.variant = "filled"

        }else if (estado === "PENDIENTE") {
            dato.value = "Pendiente"
            dato.color = "info"
            dato.variant = "outlined"

        } else if (estado === "CANCELADA") {
            dato.value = "Cancelada"
            dato.color = "success"
            dato.variant = "filled"
        }
        return (
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                sx={{fontSize: 10}}
            />
        );
    };
    const vencimientoCuota = (vencimiento, estado) => {
        console.log("estadoCUOTA: " + vencimiento+ " estado: "+estado)
        const hoy = new Date()
        hoy.setHours(0,0,0,0)
        const venci = new Date(vencimiento +"T00:00:00")
        const dato = {
            value: formatDDMMYYYY(venci),
            color: "info",
            variant: "outlined",
            disabled: false
        }
        if(estado === "CANCELADA"){
            dato.color = "success"
            dato.variant = "outlined"
            dato.disabled = true
        }
        else{
            if(hoy < venci){
                dato.color = "success"
                dato.variant = "outlined"
            }
            else if(hoy > venci){
                dato.color = "error"
                dato.variant = "filled"
            }
            else if(hoy.getTime() === venci.getTime()){
                dato.color = "warning"
                dato.variant = "filled"
            }
        }

        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
                sx={{fontSize: 10}}
            />)
    };

    return (
        <Stack direction="row" alignItems="center" spacing={3}>
            <Paper elevation={4} sx={{ minWidth: 1, flexGrow: 1, pl:1, mt:0.5  }} bgcolor="#FEF5E7" borderRadius={1}  >
                <Link color="inherit" variant="subtitle2" fontSize={11} underline="hover" onClick={verPrestamo(cuota.idPrestamo)} noWrap>
                    <b> Préstamo #{cuota.numeroPrestamo ? cuota.numeroPrestamo : cuota.idPrestamo}{cuota.seriePrestamo}</b>
                </Link>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary' }}
                        fontSize={10}
                        noWrap>
                        <b>Cuota Nº {cuota.numeroCuota}</b> {cuota.tipo}
                    </Typography>
                    <Typography variant="body2"
                                sx={{ color: 'text.secondary'}}
                                fontSize={10}
                                noWrap>
                        {vencimientoCuota(cuota.vencimiento, cuota.estado)} {estadoCuota(cuota.estado, cuota.vencimiento)}
                    </Typography>
                </Stack>
            </Paper>
        </Stack>


    );
}