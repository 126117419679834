import React, {useCallback, useEffect} from 'react'
import Container from '@mui/material/Container';
import {Link, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useStateContext} from "../../contexts/ContextProvider";
import {useDispatch, useSelector} from "react-redux";
import {Chip, Collapse, Fab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Typography from "@mui/material/Typography";
import {emitirRecibo, getAllPagos, anularPago} from "../../store/slices/pago.slice";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DatePickerComponent from "../../components/date.picker.component";
import SearchIcon from '@mui/icons-material/Search';
import {formatYYYYMMDD, formatDDMMYYYY, formatHHMMSS}  from "../../common/fechaFormat";
import Divider from "@mui/material/Divider";
import PrintIcon from "@mui/icons-material/Print";
import {downloadFile} from "../../common/downloadFile";
import InputComponent from "../../components/input.component";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import CircularProgress from "@mui/material/CircularProgress";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {setearMensaje} from "../../store/slices/snackbar.slice";
import {useConfirm} from "material-ui-confirm";
import {getUsuario} from "../../store/slices/autenticacion.slice";



const PagosComponent = () => {
    const navigate = useNavigate();
    const confirm = useConfirm();
    const usuario = getUsuario();

    const {open, setOpen, title, setTitle} = useStateContext();
    const {pagosList} = useSelector(state => state.pagos)
    const dispatch = useDispatch();

    const fechaHoy = new Date()
    const fechaAntes = new Date()
    fechaAntes.setDate(fechaHoy.getDate() -2)

    const [numeroRecibo, setNumeroRecibo] = React.useState({valor: "", valido: true});
    const [fechaDesde, setFechaDesde] = React.useState({valor: fechaAntes, valido: true});
    const [fechaHasta, setFechaHasta] = React.useState({valor: fechaHoy, valido: true});
    const [numeroPrestamo, setNumeroPrestamo] = React.useState({valor: "", valido: true});
    const [serie, setSerie] = React.useState({valor: "", valido: true});
    const [loadingPrint, setLoadingPrint] = React.useState({loading: false, id: null});
    const [loadingAnulacion, setLoadingAnulacion] = React.useState({loading: false, id: null});


    const buscar = (e) => {
        e.preventDefault();
        dispatch(getAllPagos(numeroRecibo.valor, fechaDesde.valor ? formatYYYYMMDD(fechaDesde.valor) : "", fechaHasta.valor ? formatYYYYMMDD(fechaHasta.valor) : "", numeroPrestamo.valor, serie.valor)).then(()=>{}).catch(()=>{});
    }
    const clear = (e) => {
        e.preventDefault();
        setNumeroRecibo({valor:"", valido:true})
        setFechaDesde({valor:fechaAntes})
        setFechaHasta({valor:fechaHoy})
        setNumeroPrestamo({valor:"", valido:true})
        setSerie({valor:"", valido:true})
    }

    useEffect(() => {
        setTitle("Consulta de pagos");
        dispatch(getAllPagos(numeroRecibo.valor, fechaDesde.valor ? formatYYYYMMDD(fechaDesde.valor) : "", fechaHasta.valor ? formatYYYYMMDD(fechaHasta.valor) : "", numeroPrestamo.valor, serie.valor)).then(()=>{}).catch(()=>{});
    },[dispatch]);

    const reemprimirPago = useCallback(
        (id) => () => {
            setLoadingPrint({loading: true, id: id});
            dispatch(emitirRecibo(id)).then((reporte) => {

                downloadFile(reporte.data, reporte.type, reporte.filename)
                setLoadingPrint({loading: false, id: null})
            }).catch(() => {
                setLoadingPrint({loading: false, id: null})
            })
        }
    );
    const anulacionPago = useCallback(
        (id) => () => {
            confirm({ title: `¿Confirma anulación del pago ${id}?`, confirmationButtonProps: {variant: 'contained'}})
                .then(() => {
                    setLoadingAnulacion({loading: true, id: id});
                    dispatch(anularPago(id)).then(() => {
                        setLoadingAnulacion({loading: false, id: null})
                        const mensaje = {
                            message: "Pago #"+ id +" anulado",
                            severity: "success",
                        }
                        dispatch(setearMensaje(mensaje));
                        dispatch(getAllPagos(numeroRecibo.valor, fechaDesde.valor ? formatYYYYMMDD(fechaDesde.valor) : "", fechaHasta.valor ? formatYYYYMMDD(fechaHasta.valor) : "", numeroPrestamo.valor, serie.valor)).then(()=>{}).catch(()=>{});
                    }).catch(() => {
                        setLoadingAnulacion({loading: false, id: null})
                    })
                })
                .catch(() => { });

        }
    );
    const actions ={
        reemprimirPago: reemprimirPago,
        anulacionPago: anulacionPago,

    }

    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <Grid container spacing={2} >
                            <Grid item xs={12}  sm={11} >
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                >
                                <b>Consulta de Pagos</b>
                                </Typography>
                            </Grid>

                        </Grid>
                            <Divider  sx={{ mt:'0ch', mb:'2ch'}}/>
                        <Grid container spacing={2} paddingLeft={2}>
                            <Grid item xs={12}  md={2} >
                                <InputComponent sx={{width: "100%"}}
                                    label={"Nº Recibo"}
                                    type="outlined"
                                    estado={numeroRecibo}
                                    setEstado={setNumeroRecibo}
                                    tipo={"number"}
                                />
                            </Grid>
                            <Grid item xs={12} md={2.5} >
                                <DatePickerComponent
                                    label="Fecha desde"
                                    formato="dd/MM/yyyy"
                                    estado={fechaDesde}
                                    setEstado={setFechaDesde}
                                />
                            </Grid>
                            <Grid item xs={12} md={2.5} >
                                <DatePickerComponent
                                    label="Fecha hasta"
                                    formato="dd/MM/yyyy"
                                    estado={fechaHasta}
                                    setEstado={setFechaHasta}
                                />
                            </Grid>
                            <Grid item xs={12}  md={2} >
                                <InputComponent sx={{width: "100%"}}
                                                label={"Prestamo"}
                                                type="outlined"
                                                estado={numeroPrestamo}
                                                setEstado={setNumeroPrestamo}
                                                tipo={"number"}
                                />
                            </Grid>
                            <Grid item xs={12}  md={1} >
                                <InputComponent sx={{width: "100%"}}
                                                label={"Serie"}
                                                type="outlined"
                                                estado={serie}
                                                setEstado={setSerie}
                                                upperCase={true}
                                />
                            </Grid>
                            <Grid item xs={12}  md={2} >
                                <Fab sx={{mb: 2, ml: 0}} size = "medium" color="primary" aria-label="add" onClick={clear} >
                                    <DeleteSweepIcon   />
                                </Fab>
                                <Fab sx={{mb: 2, ml: 2}} size = "medium" color="primary" aria-label="add" onClick={buscar} >
                                    <SearchIcon   />
                                </Fab>
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper} sx={{marginTop:2}} >
                            <Table aria-label="collapsible table" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell  sx={{width:"15%"}}/>
                                        <TableCell align="left" sx={{fontWeight: 'bold', width:"12%"}}>Nº Recibo</TableCell>
                                        <TableCell align="left"  sx={{fontWeight: 'bold', width:"15%"}}>Fecha</TableCell>
                                        <TableCell align="left"  sx={{fontWeight: 'bold', width:"15%"}}>Hora</TableCell>
                                        <TableCell align="left"  sx={{fontWeight: 'bold', width:"5%"}}>Prestamo</TableCell>
                                        <TableCell align="center"  sx={{fontWeight: 'bold', width:"20%"}}>Anulado?</TableCell>
                                        <TableCell align="right"  sx={{fontWeight: 'bold', width:"10%"}}>Moneda</TableCell>
                                        <TableCell align="right" sx={{fontWeight: 'bold', width:"20%"}}>Importe</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pagosList.map((row, i) => (
                                        <Row key={i} row={row} acciones={actions} loadingPrint={loadingPrint} loadingAnulacion={loadingAnulacion} usuario={usuario} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
export default PagosComponent
    const fechaPago = (fecha) => {
        const dato = {
            value: formatDDMMYYYY(fecha),
            color: "success",
            variant: "outlined",
            disabled: false
        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
                sx={{ fontSize:15 }}
            />)
    };
    const horaPago = (hora) => {
        const dato = {
            value: formatHHMMSS(hora),
            color: "warning",
            variant: "outlined",
            disabled: false
        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
                sx={{ fontSize:15 }}
            />)
    };
const fechaPagoAnulado = (fecha) => {
    const dato = {
        value: formatDDMMYYYY(new Date(fecha)),
        color: "error",
        variant: "outlined",
        disabled: false
    }
    return(
        <Chip
            label = {dato.value}
            color = {dato.color}
            variant = {dato.variant}
            size = "small"
            disabled={dato.disabled}
            sx={{ fontSize:15 }}
        />)
};
    const moneda = (moneda) => {
        const dato = {
            value: moneda,
            color: "secondary",
            variant: "outlined"
        }
        if (moneda === "USD") {
            dato.color = "info"
            dato.variant = "outlined"

        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
                sx={{ fontSize:15 }}
            />)
    };

    function Row(props) {
        const { row, acciones, loadingPrint, loadingAnulacion, usuario } = props;
        console.log({usuario});
        const [open, setOpen] = React.useState(false);
        let fechaHora = new Date(row.fechaPago+"T"+row.horaPago);
        const estadoPrestamoNoAnulacion = ['CANCELADO', 'FINALIZADO', 'REFINANCIADO'];
        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={acciones.reemprimirPago(row.id)}
                        >
                            { loadingPrint.loading && loadingPrint.id === row.id ? <CircularProgress  size="2ch"/>  :  <PrintIcon />}
                        </IconButton>
                        {
                            usuario.cargo === "Supervisor" || usuario.cargo === "Administrador" &&(
                                !row.anulado && !estadoPrestamoNoAnulacion.includes(row.estadoPrestamo) &&
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={acciones.anulacionPago(row.id)}
                                >
                                    { loadingAnulacion.loading && loadingAnulacion.id === row.id ? <CircularProgress size="2ch"/>  :  <HighlightOffIcon color={'error'}/>}
                                </IconButton>
                            )
                        }
                    </TableCell>
                    <TableCell  align="left" component="th" scope="row">
                        <b>#{row.id}</b>
                    </TableCell>
                    <TableCell align="left">{fechaPago(fechaHora)}</TableCell>
                    <TableCell align="left">{horaPago(fechaHora)}</TableCell>
                    <TableCell align="center"><b>{(row.numeroPrestamo ? row.numeroPrestamo : row.idPrestamo) + "-" + row.serie}</b></TableCell>
                    <TableCell align="center"><b>{row.anulado ? fechaPagoAnulado(row.fechaAnulacion) : null}</b></TableCell>
                    <TableCell align="right">{moneda(row.simboloMoneda)}</TableCell>
                    <TableCell align="right"><b>{"$"+row.importeTotal.toLocaleString("es-UY")}</b></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 ,marginLeft:"5%" }}>
                                <Typography variant="h6" gutterBottom component="div" sx={{fontWeight: 'bold'}}>
                                    Detalle del Pago
                                </Typography>
                                {row.descripcion &&
                                    <Typography>
                                        <b>Observación: </b> {row.descripcion}
                                    </Typography>
                                }
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell  sx={{fontWeight: 'bold'}}>#</TableCell>
                                            <TableCell  sx={{fontWeight: 'bold', width:"60%"}}>Descripcion</TableCell>
                                            <TableCell  sx={{fontWeight: 'bold'}}>Moneda</TableCell>
                                            <TableCell  sx={{fontWeight: 'bold'}} align="right">Importe</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.pagoDetalle.map((detalle, i) => (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">
                                                    {i+1}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {detalle.descripcion}
                                                </TableCell>
                                                <TableCell>{moneda(row.simboloMoneda)}</TableCell>
                                                <TableCell align="right">{detalle.importe}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell rowSpan={4} />
                                            <TableCell rowSpan={4} />
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={0} sx={{fontWeight: 'bold'}}>Importe total</TableCell>
                                            <TableCell align="right" sx={{fontWeight: 'bold'}}>{"$"+row.importeTotal.toLocaleString("es-UY")}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                            <Box sx={{ margin: 1, marginLeft:"50%" }}>
                                <Typography variant="h6" gutterBottom component="div" sx={{fontWeight: 'bold'}}>
                                    Métodos de pago
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell  sx={{fontWeight: 'bold'}}>Forma de pago</TableCell>
                                            <TableCell  sx={{fontWeight: 'bold'}}>Descripción</TableCell>
                                            <TableCell  sx={{fontWeight: 'bold'}} align="right">Importe</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.metodosPago.map((metodo, i) => (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">
                                                    {metodo.formaPagoNombre}
                                                </TableCell>
                                                <TableCell><Box>
                                                    {metodo.nombreReferencia1 &&
                                                        <Typography sx={{ fontSize: 10 }} >
                                                            {metodo.nombreReferencia1+": "+metodo.numeroReferencia1}
                                                        </Typography>
                                                    }
                                                    {metodo.nombreReferencia2 &&
                                                        <Typography sx={{ fontSize: 10 }} >
                                                            {metodo.nombreReferencia2+": "+metodo.numeroReferencia2}
                                                        </Typography>
                                                    }
                                                </Box></TableCell>
                                                <TableCell align="right">{"$"+metodo.importe.toLocaleString("es-UY")}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
}
//TODO Esto da un error al renderizar pero no perjudica, investigar para que sirve ya que las eran propiedades de los nombres de las columnas del ejemplo
/*Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

 */
