import {createSlice} from "@reduxjs/toolkit";
import clienteService from "../../services/cliente.service";
import {clearMessage, setearMensaje} from "./snackbar.slice"
import {logout} from "./autenticacion.slice";
import {errores} from "../../common/errores";

const initialState = {
    clientesList: [],
    cliente: null,
    prestamosClienteList: [],

}

export const clienteSlice = createSlice({
    name: "clientes",
    initialState,
    reducers: {
        setClientesList: (state, action) => {
            //console.log("accion setclientelist")
            //console.log ("SetClientesList - State: "+state.clientesList)
            //console.log("SetClientesList - payload: "+action.payload)
            state.clientesList = action.payload;
        },
        clientesError: (state) =>{
            state.clientesList = [];
        },
        setCliente:(state, action)=>{
            state.cliente = action.payload;
        },
        clienteError: (state) =>{
            state.clientes = null;
        },
        setPrestamosClienteList: (state, action) => {
            state.prestamosClienteList = action.payload;
        },
        prestamosClientesError: (state) =>{
            state.prestamosClienteList = [];
        },

    }
});
const {setClientesList, clientesError, setCliente, clienteError, setPrestamosClienteList, prestamosClienteError} = clienteSlice.actions;
export default clienteSlice.reducer;

export const getAllClientes = () => (dispatch) => {
    return clienteService.fetchAllClientes().then(
        (data) => {
            dispatch(setClientesList(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("ERROR DE CLIENTESSSS: "+(error.tipo));
            dispatch(clientesError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));
            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const addClientePersona = (clienteNuevo) => (dispatch) => {
    return clienteService.addClientePersona(clienteNuevo).then(
        (data) => {
            return Promise.resolve(data);
        },).catch(
        (error) => {
            console.log("error AL INSERTAR NUEVO CLIENTE persona");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const getClientePersona = (id) => (dispatch) => {
    return clienteService.fetchClientePersona(id).then(
        (data) => {
            dispatch(setCliente(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("ERROR DE CLIENTESSSS");
            dispatch(clienteError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};

export const bloquearCliente = (clienteBloquear) => (dispatch) =>{
    console.log("Bloquear cliente")
    return clienteService.bloquearCliente(clienteBloquear).then(
        (data) => {
            dispatch(setCliente(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("ERROR DE CLIENTESSSS");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    )
}
export const desbloquearCliente = (clienteDesbloquear) => (dispatch) =>{
    console.log("Desbloquear cliente")

    return clienteService.desbloquearCliente(clienteDesbloquear).then(
        (data) => {
            dispatch(setCliente(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("ERROR DE CLIENTESSSS");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    )
}
export const getClientePrestamos = (idCliente) => (dispatch) => {
    return clienteService.fetchClientePrestamos(idCliente).then(
        (data) => {
            dispatch(setPrestamosClienteList(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            dispatch(prestamosClienteError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));
            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const modificarClientePersona = (cliente) => (dispatch) => {
    return clienteService.modificarClientePersona(cliente).then(
        (data) => {
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("error AL modificar cliente persona");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde update")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const getAllClientesPrestamosActivos = () => (dispatch) => {
    return clienteService.fetchAllClientesPrestamosActivos().then(
        (data) => {
            dispatch(setClientesList(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            dispatch(clientesError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));
            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};