import { createSlice } from '@reduxjs/toolkit';

export const ENQUEUE_SNACKBAR = 'enqueueSnackbar';
export const CLOSE_SNACKBAR = 'closeSnackbar';
export const REMOVE_SNACKBAR = 'removeSnackbar';

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: { notifications: [] },
    reducers: {
        [ENQUEUE_SNACKBAR]: {
            reducer: (state, {payload}) => {
                state.notifications.push(payload)
            },
            prepare(notification) {
                const payload = { ...notification, key: new Date().getTime() + Math.random()}
                return { payload };
            },
        },
        [CLOSE_SNACKBAR]: {
            reducer: (state, action) => {
                console.log(action)
                const { payload } = action;
                state.notifications = state.notifications.map(notification => {
                    const shouldDismiss = payload.dismissAll || notification.key === payload.key;
                    return shouldDismiss ? { ...notification, dismissed: true } : { ...notification }
                })
            },
            prepare: (key) => ({ payload: { key, dismissAll: !key } })
        },
        [REMOVE_SNACKBAR]: (state, { payload }) => {
            state.notifications = state.notifications.filter(notification => notification.key !== payload);
        }
    }
});


export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;


export const setearMensaje = (mensaje) => async (dispatch) =>{

    const notificacion = {
        message: mensaje.message,
        options: {
            variant: mensaje.severity,
            key: null,
            //action: key => (<Button onClick={() => closeSnackbar(key)}>cerrar</Button>),
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            autoHideDuration: 5000,
            preventDuplicate: true,
            persist: false,
        },


    }
    dispatch(enqueueSnackbar(notificacion))
}
export const  clearMessage = () => (dispatch) =>{
    /*
        console.log("setear mensaje: "+ mensaje.message);
        dispatch(setMessage(mensaje.message));
        dispatch(setOpen(true));
        //dispatch(setSeverity(mensaje.severity));

        if(mensaje.severity === "error")
            dispatch(setDuration(null))
        else
            dispatch(setDuration(6000))

     */
}