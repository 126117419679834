import React, {useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import GridComponent from "../../../../components/grid.component";
import {useDispatch, useSelector} from "react-redux";
import {getAllMovimientosPrestamo} from "../../../../store/slices/prestamo.slice";
import Grid from "@mui/material/Grid";
import {Chip} from "@mui/material";
import SelectComponent from "../../../../components/select.component";
import {formatDDMMYYYY} from "../../../../common/fechaFormat";
import {TipoMovimiento} from "../../../../common/enum/tipoMovimiento";



const MovimientosPrestamoComponent = (props) => {
    const navigate = useNavigate();
    const {idPrestamo} = props;
    const dispatch = useDispatch();
    const {movimientosList} = useSelector(state => state.prestamos);
    useEffect(() => {

        dispatch(getAllMovimientosPrestamo(idPrestamo, props.tipoMov.valor)).then(()=>{

        }).catch(()=>{

        });

    },[dispatch, props.tipoMov]);

    const fechaMovimiento = (fecha) => {
        const fechaMov = new Date(fecha +"T00:00:00")
        const dato = {
            value: formatDDMMYYYY(fechaMov),
            color: "success",
            variant: "outlined",
            disabled: false
        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
                sx={{alignItems: "cented", fontSize: "11px"}}
            />)
    };
    const tipoMovimiento = (tipo) => {

        const dato = {
            value: tipo,
            color: "secondary",
            variant: "outlined"
        }
        if (tipo === "CTA_CTE") {
            dato.value = TipoMovimiento.CTA_CTE
            dato.color = "warning"
            dato.variant = "outlined"

        }else if (tipo === "CUOTA") {
            dato.value = TipoMovimiento.CUOTA
            dato.color = "info"
            dato.variant = "outlined"

        } else if (tipo === "DESCUENTO") {
            dato.value = TipoMovimiento.DESCUENTO
            dato.color = "success"
            dato.variant = "outlined"
        }
        return (
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                sx={{alignItems: "cented", fontSize: "10px"}}
            />
        );
    };
    const moneda = (moneda) => {
        const dato = {
            value: moneda,
            color: "secondary",
            variant: "outlined"
        }
        if (moneda === "USD") {
            dato.color = "info"
            dato.variant = "outlined"

        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
            />)
    };

    const columns = [
        {
            field: 'fecha',
            headerName: 'Fecha',
            type:'date',
            width: 100,
            editable: false,
            renderCell: (param) => (
                fechaMovimiento(param.value)
            ),
        },
        {
            field: 'simboloMoneda',
            headerName: 'Moneda',
            width: 70,
            editable: false,
            renderCell: (param) => (
                moneda(param.value)
            ),
        },
        {
            field: 'idPago',
            headerName: 'Pago #',
            type: 'number',
            width: 70,
            editable: false,
        },
        {
            field: 'descripcion',
            headerName: 'Descripción',
            width: 300,
            editable: false,
        },
        {
            field: 'tipo',
            headerName: 'Tipo',
            width: 105,
            editable: false,
            renderCell: (param) => (
                tipoMovimiento(param.value)
            ),
        },
        {
            field: 'debito',
            headerName: 'Débito',
            type:'number',
            width: 80,
            editable: false,
            valueGetter: (param) => param.value ? param.value.toLocaleString("es-UY") : null
        },
        {
            field: 'credito',
            headerName: 'Crédito',
            type:'number',
            width: 90,
            editable: false,
            valueGetter: (param) => param.value ? param.value.toLocaleString("es-UY") : null
        }
    ];


    return (
        <>
            <Grid container spacing={2} paddingLeft={2} style={{paddingBottom:"1%"}}>

                <Grid item xs={12} md={3.5} >
                    <SelectComponent label="Tipo de Movimiento"
                                     items={Object.entries(TipoMovimiento).map(([key, value]) => ({id: key, descripcion:value}))}
                                     required={true}
                                     estado={props.tipoMov}
                                     setEstado={props.setTipoMov}
                                     sx={{'& > :not(style)': {width: '100%'}}}/>
                </Grid>
            </Grid>

            <GridComponent rows={movimientosList} columns={columns} rowHeight={30} pageSize={12}/>
        </>
    );
}
export default MovimientosPrestamoComponent