import {createSlice} from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import {clearMessage, setearMensaje, setMessage} from "./snackbar.slice"
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {errores} from "../../common/errores";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

export const autenticacionSlice = createSlice({
    name: "autenticacion",
    initialState,
    reducers:{
        registerSuccess: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        registerFail: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        loginSuccess: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload;
        },
        loginFail: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        logoutSuccess: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        }
    }
});
export default autenticacionSlice.reducer;
const {loginSuccess, loginFail, logoutSuccess } = autenticacionSlice.actions;

export const login = (username, password) => (dispatch) => {
    return AuthService.login(username, password).then(
        (data) => {

            dispatch(loginSuccess(data));
            dispatch(clearMessage())
            return Promise.resolve();
        },
        (error) => {
            console.log("ERROR DE autenticacion");

            dispatch(loginFail());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            //dispatch(setearMensaje(mensaje));
            return Promise.reject(mensaje);
        }
    );
};
export const cambiarContraseña = (usuario) => (dispatch) => {
    return AuthService.cambiarContrasenia(usuario).then(
        (data) => {

            return Promise.resolve();
        },
        (error) => {
            console.log("ERROR DE autenticacion");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if (error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA) {
                console.log("logout desde fetchbuscar")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
}

export const logout = () => (dispatch) => {
    dispatch(logoutSuccess());
    window.localStorage.clear();
    return Promise.resolve();
}

export const getUsuario = () => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    return user;

}

