import axiosPrivate, {axiosError} from "../api/axios";
import authHeader from "./auth-header";

const API_URL = "http://localhost:9090/";

const fetchAllPagos = (pagoId, fechaDesde, fechaHasta, numeroPrestamo, serie) => {
    const path = "/pago/obtener/rango_fechas"
    const params = "?pagoId="+ pagoId +"&fechaDesde="+fechaDesde+"&fechaHasta="+fechaHasta + "&numeroPrestamo=" + numeroPrestamo + "&serie=" + serie ;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            //console.log("Pago service");
            //console.log(response.data)
            //console.log(response.data)
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });

}

const fetchPago = (id) => {
    const path = "/pagos/obtener";
    const params= "?idPagos="+id;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            //console.log("cliente service");
            //console.log(response.data)
            //console.log(response.data)
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
const pagoNuevo = (pagoNuevo) => {
    const path = "/pago/cuota";
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.post(path,pagoNuevo ,config )
        .then((response) => {
            //console.log("Pago service");
            //console.log(response.data)

            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
const pagoAdelantoNuevo = (pagoNuevo) => {
    const path = "/pago/adelanto";
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.post(path,pagoNuevo ,config )
        .then((response) => {
            //console.log("Pago service");
            //console.log(response)

            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

const anularPago = (id) => {
    const path = "/pago/anular";
    const params= "?pagoId="+id;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.put(path+params, null ,config)
        .then((response) => {
            console.log("pago service");
            console.log(response.data)
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            console.log(error)
            return Promise.reject(err);
        });
}

export default {
    fetchAllPagos,
    fetchPago,
    pagoNuevo,
    pagoAdelantoNuevo,
    anularPago,

};