import React from 'react';
import TextField from '@mui/material/TextField'

const InputComponent =  (props) => {

    const onChange = (e) => {
        props.setEstado({...props.estado, valor: props.upperCase ? (e.target.value).toUpperCase() : e.target.value})
    }
    const validacion = () => {
        if(props.expresionRegular){
            if(props.expresionRegular.test(props.estado.valor)){
                props.setEstado({...props.estado, valido: true})
                //console.log("VERDADERO");
            }
            else{
                props.setEstado({...props.estado, valido: false})
                    //console.log("FALSO");
            }
        }

    }
    return (
            <TextField
                id="outlined-basic"
                sx={props.sx}
                label={props.label}
                variant={props.type}
                required={props.required}
                value={props.formato? props.estado.valor.toLocaleString(props.formato): props.estado.valor}
                onChange={onChange}
                onKeyUp={validacion}
                onBlur={validacion}
                error ={!(props.estado.valido)}
                helperText= {props.estado.valido ? "":props.leyendaError}
                disabled={props.disabled ? props.disabled :false}
                multiline={props.multiline ? props.multiline :false}
                maxRows={props.maxRows}
                type={props.tipo ? props.tipo : ""}
                inputProps={props.inputProps ? props.inputProps : ""}
            />
    )

}

export default InputComponent