import React from 'react'
import {Skeleton, Stack} from "@mui/material";


const LoadingComponent = () => {
    return (
        <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
            {/* For other variants, adjust the size with `width` and height` */}
            <Skeleton variant="rounded" width={'100%'} height={60} />
            <Skeleton variant="rounded" width={'100%'} height={60} />
            <Skeleton variant="rounded" width={'100%'} height={60} />
        </Stack>
    );
}

export default LoadingComponent;