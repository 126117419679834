import Box from "@mui/material/Box";
import InputComponent from "../../../../components/input.component";
import SelectComponent from "../../../../components/select.component";
import {Fab, FormControlLabel, Stack, Switch} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ButtonComponent from "../../../../components/button.component";
import React, {useCallback, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {useDispatch, useSelector} from "react-redux";
import {getAllPaises, getAllTiposDoc, getAllMonedas} from "../../../../store/slices/generico.slice";
import {expresiones as er} from "../../../../common/expresionesRegulares";
import {refinanciarPrestamoAutomotor, getAllPrestamosActivosFull} from "../../../../store/slices/prestamo.slice"
import {setearMensaje} from "../../../../store/slices/snackbar.slice";
import {getAllClientes} from "../../../../store/slices/cliente.slice";
import Typography from "@mui/material/Typography";
import GridComponent from "../../../../components/grid.component";
import AddIcon from "@mui/icons-material/Add";
import {GridActionsCellItem} from "@mui/x-data-grid";

import DeleteIcon from "@mui/icons-material/Delete";
import DatePickerComponent from "../../../../components/date.picker.component";
import {formatYYYYMMDD}  from "../../../../common/fechaFormat";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";



const AutomotorRefinanciarComponent = () => {

    const columnsAnualidad = [
        {   field: 'valorAnualidad',
            headerName: 'Importe',
            width: 200,
            editable: false
        },
        {
            field: 'fechaVencimiento',
            headerName: 'Vencimiento',
            width: 200,
            editable: false,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 110,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Borrar"
                    onClick={deleteAnualidad(params.id)}
                />,
            ],
        }

    ];

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();


    const { clientesList } = useSelector(state => state.clientes)
    const { monedasList } = useSelector(state => state.genericos)
    const { prestamosList } = useSelector(state => state.prestamos)
    const cantCuotasList = [{"id":6, "descripcion": 6}, {"id":12, "descripcion": 12}, {"id":18, "descripcion": 18}, {"id":24, "descripcion": 24}, {"id":36, "descripcion": 36}, {"id":48, "descripcion": 48}];

    const year = (new Date()).getFullYear();
    const yearList = Array.from(new Array(50),( val, index) => year - index);
    const aniosList = yearList.map(( obj, index ) => { let an = {}; an.id=obj; an.descripcion = obj; return an;  }  );

    const [prestamos, setPrestamos] = useState([]);
    const [moneda, setMoneda] = useState({valor: 1, valido: true});
    const [importePrestado, setImportePrestado] = useState({valor: "", valido: true});
    const [importeFinanciado, setImporteFinanciado] = useState({valor: "", valido: true});
    const [cantCuotas, setCantCuotas] = useState({valor: "", valido: true});
    const [valorCuota, setValorCuota] = useState({valor: "", valido: true});
    const [valorUltimaCuota, setValorUltimaCuota] = useState({valor: "", valido: true});
    const [tasa, setTasa] = useState({valor: "", valido: true});
    const [marca, setMarca] = useState({valor: "", valido:true });
    const [modelo, setModelo] = useState({valor: "", valido:true });
    const [matricula, setMatricula] = useState({valor: "", valido:true });
    const [anio, setAnio] = useState({valor:"", valido: true});
    const [kilometraje, setKilometraje] = useState({valor:"", valido: true});
    const [clientePrestamo, setClientePrestamo] = React.useState({valor:"", valido: true});
    const [prestamoSeleccionado, setPrestamoSeleccionado] = React.useState(location.state ? "": null);
    const [autocomClienteDisable, setAutocomClienteDisable] = React.useState(location.state ? true: false);
    const [autocomPrestamoDisable, setPrestamoClienteDisable] = React.useState(location.state ? true: false);
    const [fechaPrestamo, setFechaPrestamo] = React.useState({valor: new Date(), valido: true});
    const [primerVenCuota, setPrimerVenCuota] = React.useState({valor: new Date(), valido: true});
    const [valorAnualidad, setValorAnualidad] = useState({valor:"", valido: true});
    const [vencimientoAnualidad, setVencimientoAnualidad] = React.useState({valor: new Date(), valido: true});
    const [checked, setChecked] = React.useState(false);
    const [financiadoAnterior, setFinanciadoAnterior] = useState({valor:"", valido: true});
    const [prestadoAnterior, setPrestadoAnterior] = useState({valor:"", valido: true});
    const [pendienteAnterior, setPendienteAnterior] = useState({valor:"", valido: true});
    const [importeCanceladoAnterior, setImporteCanceladoAnterior] = useState({valor:"", valido: true});

    const handleChangeVencimientoAnualidad = (newValue) => {
        setVencimientoAnualidad(newValue);
    };
    const handleChangeSwitch = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        dispatch(getAllPrestamosActivosFull()).then(() => {}).catch(()=>{});
        dispatch(getAllTiposDoc()).then(() => {}).catch(()=>{});
        dispatch(getAllPaises()).then(() => {}).catch(()=>{});
        dispatch(getAllMonedas()).then(() => {}).catch(()=>{});
    }, [dispatch]);


    //    const cantCuotasList = [{"id":1, "descripcion": 6}, {"id":2, "descripcion": 12}, {"id":3, "descripcion": 18}, {"id":4, "descripcion": 24}, {"id":5, "descripcion": 36}, {"id":6, "descripcion": 48}];
    useEffect( () => {
        let porc = 2;
        setTasa({ ...tasa, valor: (cantCuotas.valor/100*porc)} );
    }, [cantCuotas]);

    useEffect(() => {
        if(location.state) {
            setPrestamoSeleccionado(prestamosList.find(elemento => elemento.id === parseInt(location.state.idPrestamo)))
        }
    }, [prestamosList]);

    useEffect(() => {
        //console.log(prestamoSeleccionado)
        if(prestamoSeleccionado) {
            setClientePrestamo({valor: prestamoSeleccionado.nombreCompletoCliente, valido: true})
            setMarca({valor: prestamoSeleccionado.marca, valido: true});
            setModelo({valor: prestamoSeleccionado.modelo, valido: true});
            setMatricula({valor: prestamoSeleccionado.matricula, valido: true});
            setAnio({valor: prestamoSeleccionado.anio, valido: true});
            setChecked(prestamoSeleccionado.control_mecanico);
            setKilometraje({valor: prestamoSeleccionado.kilometraje, valido: true})
            setFinanciadoAnterior({valor: prestamoSeleccionado.importeFinanciado, valido: true});
            setPrestadoAnterior({valor: prestamoSeleccionado.importePrestado, valido: true});
            setImporteCanceladoAnterior({valor: prestamoSeleccionado.importeCancelado, valido: true});
            setPendienteAnterior({valor: prestamoSeleccionado.saldoPendientePrestamo, valido: true});
        }else{
            setClientePrestamo({valor: "", valido: true})
            setMarca({valor: "", valido: true});
            setModelo({valor: "", valido: true});
            setMatricula({valor: "", valido: true});
            setAnio({valor: "", valido: true});
            setChecked(false);
            setKilometraje({valor: "", valido: true});
            setFinanciadoAnterior({valor: "", valido: true});
            setPrestadoAnterior({valor: "", valido: true});
            setImporteCanceladoAnterior({valor: "", valido: true});
            setPendienteAnterior({valor: "", valido: true});
        }
    }, [prestamoSeleccionado]);


    // const anualidadesList = [{ id: 999, fechaVencimiento: '2022-10-01', valorAnualidad: 500 }];
    const anualidadesList = [];
    const [anualidades, setAnualidades] = React.useState(anualidadesList);

    const deleteAnualidad = useCallback(
        (id) => () => {
            const anualidadesList = [...anualidades];
            setAnualidades(anualidadesList.filter( (param) => param.id !== id ));
        }
    );
    const handleAgrAnualidad = (e) => {
        if (valorAnualidad.valido && valorAnualidad.valor !== "")
        {
            const anualidadesList = [...anualidades];
            let ids = anualidades.length;
            anualidadesList.push( { id:ids + 1, valorAnualidad: valorAnualidad.valor, fechaVencimiento: vencimientoAnualidad.valor.toISOString().split('T')[0] } );
            setAnualidades(anualidadesList);
            setValorAnualidad({valor:"", valido: true})
        }
    }



    const handleCancelar = (e) => {
        e.preventDefault();
        return navigate(-1);
    }
    const handleAceptar = (e) => {
        e.preventDefault();
        if(validarFormulario()){

            const prestamoRefinanciado = {
                fechaAlta: formatYYYYMMDD(fechaPrestamo.valor),
                numeroPrestamo: prestamoSeleccionado.id,
                idMoneda: moneda.valor,
                idCliente: prestamoSeleccionado.idCliente,
                importePrestado: importePrestado.valor,
                importeFinanciado: importeFinanciado.valor,
                cantidadCuotas: cantCuotas.valor,
                valorCuota: valorCuota.valor,
                valorUltimaCuota: valorUltimaCuota.valor,
                primerVencimientoCuota: formatYYYYMMDD(primerVenCuota.valor),
                tasa: tasa.valor,
                anualidadesInput: anualidades,
                matricula: matricula.valor,
                marca: marca.valor,
                modelo:modelo.valor,
                anio: anio.valor,
                kilometraje: kilometraje.valor,
                controlMecanico: checked
            }

            //console.log("Prestamo refinanciado " + JSON.stringify(prestamoRefinanciado));
            dispatch(refinanciarPrestamoAutomotor(prestamoRefinanciado)).then((idPrestamo)=>{
                navigate("/Prestamos/" + idPrestamo, {replace: true, });
                const mensaje = {
                    message: "Préstamo #"+ idPrestamo + " creado con éxito",
                    severity: "success",
                }
                dispatch(setearMensaje(mensaje));
            }).catch(()=>{
                //console.log("NOOOOO inserte")
            })

        }else{
            //console.log("formulario no validado");
            const mensaje = {
                message: "El formulario esta incompleto",
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));
        }
    }

    const validarFormulario = () => {
        let valido = true;
        if(
            marca.valido === false ||
            modelo.valido === false ||
            matricula.valido === false ||
            anio.valido === false ||
            kilometraje.valido === false ||
            moneda.valido === false ||
            importePrestado.valido === false ||
            importeFinanciado.valido === false ||
            cantCuotas.valido === false ||
            valorCuota.valido === false ||
            valorUltimaCuota.valido === false ||
            tasa.valido === false
        ){
            valido = false;
        }
        if(
            marca.valor === "" ||
            modelo.valor === "" ||
            matricula.valor === "" ||
            anio.valor === "" ||
            kilometraje.valor === "" ||
            moneda.valor === "" ||
            importePrestado.valor === "" ||
            importeFinanciado.valor === "" ||
            cantCuotas.valor === "" ||
            valorCuota.valor === "" ||
            valorUltimaCuota.valor === "" ||
            tasa.valor === ""
        )
        {
            valido = false;
        }
        return valido;
    }




    return (
        <Box component="form"
             sx={{
                 '& > :not(style)': {m: 1, width: '100%'},
             }}
             noValidate
             autoComplete="off">
            <Box sx={ {flexGrow: 1}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}  >
                        <Typography
                            component="h5"
                            variant="h5"
                            color="inherit"
                            noWrap
                            marginLeft="2%"
                        >
                            Seleccione préstamo a refinanciar
                        </Typography>
                    </Grid>
                    <Grid item xs={12}  lg={12} >

                        <Autocomplete
                            id="combo-box-demo"
                            onChange={(event, value) => setPrestamoSeleccionado(value)}
                            options={prestamosList}
                            loading={true}
                            getOptionLabel={(option) => "#" + (option.numeroPrestamo ? option.numeroPrestamo : option.id) + "-" + option.serie + " - "+option.detalle}
                            style={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label="Préstamo"  />}
                            disabled={autocomPrestamoDisable}
                            value={prestamoSeleccionado}
                        />
                    </Grid>
                    <Grid item xs={12}  lg={12} >
                        <InputComponent sx={{width: "70%"}}
                                        label="Cliente"
                                        type="outlined"
                                        required={true}
                                        estado={clientePrestamo}
                                        setEstado={setClientePrestamo}
                                        //leyendaError= "Debe ingresar un cliente"
                                        disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InputComponent sx={{width: "90%"}}
                                        label="Capital Financiado"
                                        type="outlined"
                                        required={true}
                                        estado={financiadoAnterior}
                                        setEstado={setFinanciadoAnterior}
                                        //leyendaError= "Debe ingresar una marca"
                                        disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InputComponent sx={{width: "90%"}}
                                        label="Capital Prestado"
                                        type="outlined"
                                        required={true}
                                        estado={prestadoAnterior}
                                        setEstado={setPrestadoAnterior}
                                        //leyendaError= "Debe ingresar un modelo"
                                        disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InputComponent sx={{width: "90%"}}
                                        label="Importe Cancelado"
                                        type="outlined"
                                        required={true}
                                        estado={importeCanceladoAnterior}
                                        setEstado={setImporteCanceladoAnterior}
                                        //leyendaError= "Formato de matricula AAA1111 o A111111"
                                        disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <InputComponent sx={{width: "90%"}}
                                        label="Importe Pendiente"
                                        type="outlined"
                                        required={true}
                                        estado={pendienteAnterior}
                                        setEstado={setPendienteAnterior}
                            //leyendaError= "Formato de matricula AAA1111 o A111111"
                                        disabled={true}
                        />
                    </Grid>
                </Grid>

                <Divider  sx={{ mt:'3ch', mb:'3ch'}}/>

                <Grid container spacing={3}>
                    <Grid item xs={12}  >
                        <Typography
                            component="h5"
                            variant="h5"
                            color="inherit"
                            noWrap
                            marginLeft="2%"
                        >
                            Datos del Automotor
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputComponent sx={{width: "90%"}}
                                        label="Marca"
                                        type="outlined"
                                        required={true}
                                        estado={marca}
                                        setEstado={setMarca}
                                        leyendaError= "Debe ingresar una marca"
                                        expresionRegular={er.marca}
                                        disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputComponent sx={{width: "90%"}}
                                        label="Modelo"
                                        type="outlined"
                                        required={true}
                                        estado={modelo}
                                        setEstado={setModelo}
                                        leyendaError= "Debe ingresar un modelo"
                                        expresionRegular={er.texto255}
                                        disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputComponent sx={{width: "90%"}}
                                        label="Matrícula"
                                        type="outlined"
                                        required={true}
                                        estado={matricula}
                                        setEstado={setMatricula}
                                        leyendaError= "Formato de matricula AAA1111 o A111111"
                                        expresionRegular={er.matricula}
                                        tipo={"text"}
                                        inputProps={{maxLength:"7"}}
                                        disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <SelectComponent label="Año"
                                         items={aniosList}
                                         required={true}
                                         estado={anio}
                                         setEstado={setAnio}
                                         sx={{'& > :not(style)': {width: '90%'}}}
                                         disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputComponent sx={{width: "90%"}}
                                        label="Kilometraje"
                                        type="outlined"
                                        required={true}
                                        estado={kilometraje}
                                        setEstado={setKilometraje}
                                        leyendaError= "Debe ingresar un kilmetraje válido"
                                        expresionRegular={er.numericoConCero}
                                        disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControlLabel control={
                            <Switch
                                checked={checked}
                                onChange={handleChangeSwitch}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="Control Mecánico"
                            labelPlacement="bottom"
                            sx={{ width: "70%"}}
                            />

                    </Grid>
                </Grid>

                <Divider  sx={{ mt:'3ch', mb:'3ch'}}/>

                <Grid container spacing={3}>
                    <Grid item xs={12}  >
                        <Typography
                            component="h5"
                            variant="h5"
                            color="inherit"
                            noWrap
                            marginLeft="2%"
                        >
                            Datos del Préstamo
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} >
                            <SelectComponent label="Moneda"
                                             items={monedasList}
                                             required={true}
                                             estado={moneda}
                                             setEstado={setMoneda}
                                             sx={{'& > :not(style)': {width: '30ch'}}}/>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <DatePickerComponent
                            label="Fecha del Préstamo"
                            formato="dd/MM/yyyy"
                            estado={fechaPrestamo}
                            setEstado={setFechaPrestamo}
                            //leyendaError="La fecha debe ser valida"
                            //expresionRegular={er.fecha}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <InputComponent sx={{width: "30%"}}
                                        label="Importe Prestado"
                                        type="outlined"
                                        required={true}
                                        estado={importePrestado}
                                        setEstado={setImportePrestado}
                                        leyendaError= "El importe solo puede tener numeros"
                                        expresionRegular={er.importePrestado}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <InputComponent sx={{width: "30%"}}
                                        label="Importe Financiado"
                                        type="outlined"
                                        required={true}
                                        estado={importeFinanciado}
                                        setEstado={setImporteFinanciado}
                                        leyendaError= "El importe solo puede tener numeros"
                                        expresionRegular={er.importeFinanciado}
                        />
                    </Grid>
                    <Grid item xs={12} md={2.4} >
                        <SelectComponent label="Cuotas"
                                         items={cantCuotasList}
                                         required={true}
                                         estado={cantCuotas}
                                         setEstado={setCantCuotas}
                                         sx={{'& > :not(style)': {width: '100%'}}}/>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <InputComponent sx={{width: "100%"}}
                                        label="Valor Cuota"
                                        type="outlined"
                                        required={true}
                                        estado={valorCuota}
                                        setEstado={setValorCuota}
                                        leyendaError= "El valor de la cuota solo puede tener numeros"
                                        expresionRegular={er.valorCuota}
                        />
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <InputComponent sx={{width: "100%"}}
                                        label="Valor Última Cuota"
                                        type="outlined"
                                        required={true}
                                        estado={valorUltimaCuota}
                                        setEstado={setValorUltimaCuota}
                                        leyendaError= "El valor de la última cuota solo puede tener numeros"
                                        expresionRegular={er.valorCuota}
                        />
                    </Grid>
                    <Grid item xs={12} md={2.4} >
                        <DatePickerComponent
                            label="Vencimiento de Primer Cuota"
                            formato="dd/MM/yyyy"
                            estado={primerVenCuota}
                            setEstado={setPrimerVenCuota}
                        />
                    </Grid>
                    <Grid item xs={12} md={2.4} >
                        <InputComponent sx={{width: "100%"}}
                                        label="Tasa"
                                        type="outlined"
                                        required={true}
                                        estado={tasa}
                                        setEstado={setTasa}
                                        leyendaError= "El valor de la tasa solo puede tener numeros"
                                        expresionRegular={er.numericoConDecimales}
                        />
                    </Grid>
                </Grid>

                <Divider  sx={{ mt:'3ch', mb:'3ch'}}/>

                <Grid container spacing={3}>
                    <Grid item xs={12}  >
                        <Typography
                            component="h5"
                            variant="h5"
                            color="inherit"
                            noWrap
                            marginLeft="2%"
                        >
                            Anualidades
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputComponent sx={{width: "100%"}}
                                        label="Importe Anualidad"
                                        type="outlined"
                                        required={true}
                                        estado={valorAnualidad}
                                        setEstado={setValorAnualidad}
                                        leyendaError= "El valor de la anualidad solo puede tener numeros"
                                        expresionRegular={er.valorCuota}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <DatePickerComponent
                            label="Vencimiento Anualidad"
                            formato="dd/MM/yyyy"
                            estado={vencimientoAnualidad}
                            setEstado={setVencimientoAnualidad}
                        />
                    </Grid>
                    <Grid item xs={12}  md={4} >
                        <Fab sx={{mb: 2, ml: 0, float: 'left'}} size = "small" color="primary" aria-label="add" onClick={handleAgrAnualidad} >
                            <AddIcon   />
                        </Fab>
                    </Grid>
                    <Grid item xs={12} sx={{ mt:'3ch' }} >
                        <Grid container spacing={3} >
                            <GridComponent rows={anualidades} columns={columnsAnualidad} />
                        </Grid>
                    </Grid>

                </Grid>

                <Divider  sx={{ mt:'3ch', mb:'3ch'}}/>

                <Grid container spacing={3}>

                </Grid>
            </Box>
            <Stack spacing={2} direction="row">
                <ButtonComponent label="Refinanciar" type="contained" onClick={handleAceptar}/>
                <ButtonComponent label="Cancelar" type="outlined" onClick={handleCancelar}/>
            </Stack>
        </Box>

    )
};

export default AutomotorRefinanciarComponent;