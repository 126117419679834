import React, {useCallback, useEffect, useState} from 'react'
import Container from '@mui/material/Container';
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useStateContext} from "../../../contexts/ContextProvider";
import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    Chip,
    Fab,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import DatePickerComponent from "../../../components/date.picker.component";
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import CircularProgress from '@mui/material/CircularProgress';
import {formatYYYYMMDD, formatDDMMYYYY, formatHHMMSS}  from "../../../common/fechaFormat";
import Divider from "@mui/material/Divider";
import InputComponent from "../../../components/input.component";
import SelectComponent from "../../../components/select.component";
import {
    exportAllMetodosPagos,
    getAllMetodosPagos,
    putCambioUbicacionEfectivo
} from "../../../store/slices/metodo.pago.slice";
import GridComponent from "../../../components/grid.component";
import {getAllFormasPagos} from "../../../store/slices/forma.pago.slice";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import {FormaPagoUbicaciones} from "../../../common/enum/formaPagoUbicaciones";
import {EstadoCuota} from "../../../common/enum/estadoCuota";
import {downloadFile} from "../../../common/downloadFile";

const MetodoDePagoComponent = () => {
    const navigate = useNavigate();

    const {open, setOpen, title, setTitle} = useStateContext();
    const {metodosPagosList} = useSelector(state => state.metodosPagos)
    const {formasPagosList} = useSelector(state => state.formasPagos)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllMetodosPagos(reciboNumero.valor, fechaDesde.valor ? formatYYYYMMDD(fechaDesde.valor) : "", fechaHasta.valor ? formatYYYYMMDD(fechaHasta.valor) : "", formaPago.valor, ubicacionEfectivo.valor)).then(() =>{}).catch(()=>{});
    },[dispatch]);


    const fechaHoy = new Date()
    const fechaAntes = new Date()
    fechaAntes.setDate(fechaHoy.getDate() -7)

    const [reciboNumero, setReciboNumero] = useState({valor: "", valido: true})
    const [fechaDesde, setFechaDesde] = React.useState({valor: fechaAntes, valido: true});
    const [fechaHasta, setFechaHasta] = React.useState({valor: fechaHoy, valido: true});
    const [seleccionMetodosPagos, setSeleccionMetodosPagos] = React.useState([])
    const [formaPago, setFormaPago] = React.useState({valor: "", valido: true});
    const [loadingPrint, setLoadingPrint] = React.useState(false);


    const [ubicacionEfectivo, setUbicacionEfectivo] = React.useState({valor: "", valido:true});

    const clear = (e) => {
        e.preventDefault();
        setReciboNumero({valor:"", valido:true});
        setFechaDesde({valor:fechaAntes})
        setFechaHasta({valor:fechaHoy})
        setFormaPago({valor:""})
        setUbicacionEfectivo({valor:""})
        }
    const buscar = (e) => {
        e.preventDefault();
        dispatch(getAllMetodosPagos(reciboNumero.valor, fechaDesde.valor ? formatYYYYMMDD(fechaDesde.valor) : "", fechaHasta.valor ? formatYYYYMMDD(fechaHasta.valor) : "", formaPago.valor, ubicacionEfectivo.valor)).then(() =>{}).catch(()=>{});
     }
     const procesarMetodoPago = (ubicacion, id) => () => {
         const ids = [id]
         console.log("procesarMetodosPagos " + ubicacion + " " + ids )
         dispatch(putCambioUbicacionEfectivo(ubicacion,ids)).then(() => {
                 dispatch(getAllMetodosPagos(reciboNumero.valor, fechaDesde.valor ? formatYYYYMMDD(fechaDesde.valor) : "", fechaHasta.valor ? formatYYYYMMDD(fechaHasta.valor) : "", formaPago.valor, ubicacionEfectivo.valor)).then(() =>{}).catch(()=>{});
         }

         )
     }

     const print = () => {
         const formato = "PDF";
         setLoadingPrint(true)
         dispatch(exportAllMetodosPagos(formato, reciboNumero.valor, fechaDesde.valor ? formatYYYYMMDD(fechaDesde.valor) : "", fechaHasta.valor ? formatYYYYMMDD(fechaHasta.valor) : "", formaPago.valor, ubicacionEfectivo.valor)).then(
             (reporte) =>{
                 downloadFile(reporte.data, reporte.type, reporte.filename)
                 setLoadingPrint(false)
             }).catch(()=>{});
     }

     const actionEfectivoCofre = (formaPagoNombre, id, ubicacion) => {
         return (
            <strong>
                {formaPagoNombre === "EFECTIVO" &&
                    <Button
                        variant={ubicacion === FormaPagoUbicaciones.CAJA ? "contained" : "outlined"}
                        size="small"
                        sx={{width:"18ch", color:'yelow', height: "3ch"}}
                        onClick={procesarMetodoPago(ubicacion === FormaPagoUbicaciones.CAJA ? FormaPagoUbicaciones.COFRE : FormaPagoUbicaciones.CAJA, id)}
                    >
                        {
                            ubicacion === FormaPagoUbicaciones.CAJA && "Enviar a Cofre"
                        }
                        {
                            ubicacion === FormaPagoUbicaciones.COFRE && "Enviar a Caja"
                        }
                    </Button>
                }
        </strong>)
     }
    const fechaPago = (fecha) => {
        const fechaPago = new Date(fecha +"T00:00:00")
        const dato = {
            value: formatDDMMYYYY(fechaPago),
            color: "success",
            variant: "outlined",
            disabled: false
        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
            />)
    };

     const columns = [
         {
           field: "pagoId",
           headerName: "Pago",
            type: 'number',
           width: 80,
           editable:false
         },
         {
             field: 'fechaPago',
             headerName: 'Fecha Pago',
             width: 110,
             editable: false,
             renderCell: (param) => (
                 fechaPago(param.value)
             ),
         },
         {
             field: 'formaPagoNombre',
             headerName: 'Forma Pago',
             width: 250,
             editable: false,
         },
         {
             field: 'ubicacion',
             headerName: 'Ubicación',
             width: 100,
             editable: false,
         },
         {
             field: 'importe',
             type: 'number',
             headerName: 'Importe',
             width: 80,
             editable: false,
             valueGetter: (param) => param.value ? "$ "+param.value.toLocaleString("es-UY"): null
         },
         {
             field: 'actions',
             type: 'actions',
             width: 150,
             renderCell: (param) => [
                 actionEfectivoCofre(param.row.formaPagoNombre, param.row.id , param.row.ubicacion)
                 ]
         },
     ];

    useEffect(() => {
        dispatch(getAllFormasPagos()).then(() => {}).catch(()=>{});
    }, [dispatch]);


    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}  sm={11} >
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                >
                                <b>Métodos de Pago</b>
                                </Typography>
                            </Grid>
                        </Grid>
                            <Divider  sx={{ mt:'0ch', mb:'2ch'}}/>
                        <Grid container spacing={1} paddingLeft={2}>
                            <Grid item xs={12} md={2} >
                                <InputComponent sx={{width: "100%"}}
                                                label="Recibo Nº"
                                                type="outlined"
                                                estado={reciboNumero}
                                                setEstado={setReciboNumero}
                                                tipo="number"
                                />
                            </Grid>
                            <Grid item xs={12} md={2.5} >
                                <DatePickerComponent
                                    label="Fecha desde"
                                    formato="dd/MM/yyyy"
                                    estado={fechaDesde}
                                    setEstado={setFechaDesde}
                                />
                            </Grid>
                            <Grid item xs={12} md={2.5} >
                                <DatePickerComponent
                                    label="Fecha hasta"
                                    formato="dd/MM/yyyy"
                                    estado={fechaHasta}
                                    setEstado={setFechaHasta}
                                />
                            </Grid>
                            <Grid item sx={12} md={3} >
                                <SelectComponent label="Formas de Pago"
                                                 items={formasPagosList.map(({id, nombre}) => ({id: id, descripcion:nombre}))}
                                                 estado={formaPago}
                                                 setEstado={setFormaPago}

                                                 sx={{'& > :not(style)': {width: '100%'}}}/>
                            </Grid>
                            <Grid item xs={12}  md={2} >
                                <Fab sx={{mt:1, ml: 0}} size = "small" color="primary" aria-label="add" onClick={clear} >
                                    <DeleteSweepIcon   />
                                </Fab>
                                <Fab sx={{mt:1, ml: 1}} size = "small" color="primary" aria-label="add" onClick={buscar} >
                                    <SearchIcon   />
                                </Fab>
                                <Fab sx={{mt: 1, ml: 1}} size = "small" color="primary" aria-label="add" onClick={print} >
                                    { loadingPrint ? <CircularProgress size="70%" sx={{color:"#ffffff"}} /> : <PrintIcon />}
                                </Fab>

                            </Grid>
                            <Grid item xs={12} style={{paddingTop: '0px'}} >
                                <hr  paddingTop={5}/>{/*LINEA HORIZONTAL*/}
                            </Grid>
                            {
                                formaPago !== null && formaPago.valor === 1 &&
                                <Grid item sx={12} md={2.5} paddingBottom={1}>
                                    <SelectComponent label="Ubicación"
                                                     items={Object.entries(FormaPagoUbicaciones).map(([key, value]) => ({id: key, descripcion:value}))}
                                                     estado={ubicacionEfectivo}
                                                     setEstado={setUbicacionEfectivo}
                                                     sx={{'& > :not(style)': {width: '100%'}}}
                                    />
                                </Grid>
                            }
                        </Grid>
                        <GridComponent  rows={metodosPagosList}
                                        columns={columns}
                                        rowHeight={35}
                                        pageSize={100}
                                        //checkboxSelection={true}
                                        seleccion={seleccionMetodosPagos}
                                        setSeleccion={setSeleccionMetodosPagos}

                        />
                    </Paper>
                </Grid>
            </Grid>

        </Container>
    );
}
export default MetodoDePagoComponent;
