import axiosPrivate, {axiosError} from "../api/axios";
import authHeader from "./auth-header";

const fetchAllPrestamos = (prestamoId, numeroPrestamo, serie, fechaDesde, fechaHasta, estadoPrestamo) => {
    const path = "/prestamos/todos"
    const params = {
        prestamoId: prestamoId,
        numeroPrestamo: numeroPrestamo,
        serie: serie,
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
        estadoPrestamo: estadoPrestamo
    }
    const config = {
        params,
        headers: authHeader()
    };
    return axiosPrivate.get(path, config)
        .then((response) => {
            //console.log("Prestamo service");
            //console.log(response.data)
            //console.log(response.data)
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
const addPrestamoAutomor = (prestamo) => {
    const path = "/prestamos/crear/automotor"
    const config = {
        headers: authHeader()
    };


    return axiosPrivate.post(path, prestamo, config)
        .then((response) => {
            //console.log("prestamo service")
            //console.log(response.data)
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err);
        })

}

const refinanciarPrestamoAutomor = (prestamo) => {
    const path = "/prestamos/refinanciar/automotor"
    const config = {
        headers: authHeader()
    };

    return axiosPrivate.post(path, prestamo, config)
        .then((response) => {
            //console.log("prestamo service")
            //console.log(response.data)
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err);
        })

}

const deletePrestamo = (idPrestamo) => {
    const path = "/prestamos/eliminar"
    const params= "?idPrestamo="+idPrestamo;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.delete(path+params, config)
        .then((response) => {
            //console.log("prestamo service")
            //console.log(response.data)
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err);
        })
}
const updatePrestamoAutomotor = (prestamo) => {
    const path = "/prestamos/modificar/automotor"
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.put(path, prestamo, config)
        .then((response) => {
            //console.log("prestamo service")
            //console.log(response.data)
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err);
        })
}


const fetchPrestamo = (id) => {
    const path = "/prestamos/obtenerFull";
    const params= "?idPrestamo="+id;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            //console.log("prestamoooo service");
            //console.log(response.data)
            //console.log(response.data)
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
const fetchAllCuotasMensuales = (id, estado) => {
    const path = "/prestamos/obtener/cuotasMensuales";
    const params= "?idPrestamo=" + id + "&estado=" + estado;
    const config = {
        headers: authHeader()
    };
    //console.log("estado: "+ estado)
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            console.log(response.data)
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
const fetchAllCuotasAnuales = (id, estado) => {
    const path = "/prestamos/obtener/cuotasAnuales";
    const params= "?idPrestamo=" + id + "&estado=" + estado;
    const config = {
        headers: authHeader()
    };
    //console.log("estado: "+ estado)
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            console.log(response.data)
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
const fetchAllMovimientosPrestamo = (id, tipoMov) => {
    const path = "/prestamos/obtener/movimientos";
    const params= "?idPrestamo=" + id + "&tipoMovimiento=" + tipoMov;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
const fetchAllPagosPrestamo = (id) => {
    const path = "/prestamos/obtener/pagos";
    const params= "?idPrestamo="+id;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
const fetchBuscar = (consulta) => {
    const path = "/prestamos/buscar";
    const params= "?consulta="+consulta;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            console.log(response.data)
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

const cambioEstadoPrestamo = (nuevoEstado) => {
    const path = "/prestamos/cambio_estado";
    const params ="?prestamoId="+nuevoEstado.prestamoId+"&estadoPrestamo="+nuevoEstado.estadoPrestamo+"&mensaje="+nuevoEstado.mensaje;
    const body = {}
    const config = {
        headers: authHeader()
    };
    console.log( path + params)
    return axiosPrivate.put(path + params, body, config )
        .then((response) => {
            //console.log("prestamo service")
            return response.data;
        })
        .catch((error)=>{
            //console.log("Error cambiando estado " + error)
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

const fetchAllPrestamosActivos = (idCliente) => {
    const path = "/prestamos/activos"
    const params = {
        idCliente: idCliente,
    }
    const config = {
        params,
        headers: authHeader()
    };
    return axiosPrivate.get(path, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
const fetchAllPrestamosActivosFull = (idCliente) => {
    const path = "/prestamos/activosFull"
    const params = {
        idCliente: idCliente,
    }
    const config = {
        params,
        headers: authHeader()
    };
    return axiosPrivate.get(path, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
export default {
    fetchAllPrestamos,
    addPrestamoAutomor,
    refinanciarPrestamoAutomor,
    updatePrestamoAutomotor,
    deletePrestamo,
    fetchPrestamo,
    fetchAllCuotasMensuales,
    fetchAllCuotasAnuales,
    fetchAllMovimientosPrestamo,
    fetchAllPagosPrestamo,
    fetchBuscar,
    cambioEstadoPrestamo,
    fetchAllPrestamosActivos,
    fetchAllPrestamosActivosFull,
};