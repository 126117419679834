import Box from "@mui/material/Box";
import InputComponent from "../../../components/input.component";
import SelectComponent from "../../../components/select.component";
import {Stack} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import ButtonComponent from "../../../components/button.component";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import {useDispatch, useSelector} from "react-redux";
import {getAllCiudades, getAllDepartamentos, getAllPaises, getAllTiposDoc} from "../../../store/slices/generico.slice";
import {expresiones as er} from "../../../common/expresionesRegulares";
import {addClientePersona} from "../../../store/slices/cliente.slice";
import {setearMensaje} from "../../../store/slices/snackbar.slice";
import DatePickerComponent from "../../../components/date.picker.component";
import {formatYYYYMMDD}  from "../../../common/fechaFormat";



const PersonaNuevaComponent = () => {


    const [documento, setDocumento] = useState({valor: "", valido: true});
    const [primerNombre, setPrimerNombre] = useState({valor: "", valido: true});
    const [segundoNombre, setSegundoNombre] = useState({valor: "", valido: true});
    const [primerApellido, setPrimerApellido] = useState({valor: "", valido: true});
    const [segundoApellido, setSegundoApellido] = useState({valor: "", valido: true});
    const [telefono, setTelefono] = useState({valor: "", valido: true});
    const [mail, setMail] = useState({valor: "", valido: true});
    const [direccion, setDireccion] = useState({valor: "", valido: true});

    const [tipoDocumento, setTipoDocumento] = useState({valor: "", valido: true});
    const [pais, setPais] = useState({valor: "UY", valido: true});
    const [departamento, setDepartamento] = useState({valor: "", valido: true});
    const [ciudad, setCiudad] = useState({valor: "", valido: true});

    const navigate = useNavigate();

    const [fechaNacimiento, setFechaNacimiento] = React.useState({valor: new Date(), valido: true});


    const { tiposDocList, paisesList, departamentosList, ciudadesList } = useSelector(state => state.genericos)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllTiposDoc()).then(() => {}).catch(()=>{});
        dispatch(getAllPaises()).then(() => {}).catch(()=>{});
    }, [dispatch])

    useEffect(() => {
        if(pais.valor !== "")
            dispatch(getAllDepartamentos(pais.valor)).then(() => {}).catch(()=>{});
    }, [pais])

    useEffect(() => {
        if (departamento.valor !== "")
            dispatch(getAllCiudades(departamento.valor)).then(() => {}).catch(()=>{});
    }, [departamento])

    const handleCancelar = (e) => {
        e.preventDefault();
        return navigate(-1);
    }
    const handleAceptar = (e) => {
        e.preventDefault();
        if(validarFormulario()){
            const personaNueva = {
                tipoDocumento: tipoDocumento.valor,
                documento: documento.valor,
                fechaNacimiento: formatYYYYMMDD(fechaNacimiento.valor),
                primerNombre: primerNombre.valor,
                segundoNombre: segundoNombre.valor,
                primerApellido: primerApellido.valor,
                segundoApellido: segundoApellido.valor,
                telefono: telefono.valor,
                mail: mail.valor,
                direccion: direccion.valor,
                pais: pais.valor,
                departamento: departamento.valor,
                ciudad: ciudad.valor,
            }

            dispatch(addClientePersona(personaNueva)).then((idCliente)=>{
                let mensaje = {
                    message: "Nuevo cliente creado correctamente",
                    severity: "success",
                }
                dispatch(setearMensaje(mensaje))
                navigate("/clientes/"+idCliente, {replace: true})
            }).catch(()=>{
            })

        }else{
            console.log("formulario no validado");
            let mensaje = {
                message: "El formulario está incompleto",
                severity: "warning",
            }
            dispatch(setearMensaje(mensaje))
        }
    }

    const validarFormulario = () => {
        let valido = true;
        if(
            primerNombre.valido === false ||
            segundoNombre.valido === false ||
            primerApellido.valido === false ||
            segundoApellido.valido === false ||
            telefono.valido === false ||
            mail.valido === false ||
            direccion.valido === false
        ){
            valido = false;
        }
        if(
            primerNombre.valor === "" ||
            primerApellido.valor === "" ||
            segundoApellido.valor === "" ||
            telefono.valor === "" ||
            mail.valor === "" ||
            direccion.valor === ""
        )
        {
            valido = false;
        }
        return valido;
    }




    return (
        <Box component="form"
             sx={{
                 '& > :not(style)': {m: 1, width: '100%'},
             }}
             noValidate
             autoComplete="off">
            <Box sx={ {flexGrow: 1}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}  lg={3} >
                        <SelectComponent label="Tipo Documento"
                                         items={tiposDocList}
                                         estado={tipoDocumento}
                                         setEstado={setTipoDocumento}
                                         required={true}
                                         sx={{'& > :not(style)': {width: '100%'}}}/>
                    </Grid>
                    <Grid item xs={12} lg={3} >
                        <InputComponent sx={{width: "100%"}}
                                        label="Documento"
                                        type="outlined"
                                        required={true}
                                        estado={documento}
                                        setEstado={setDocumento}
                                        leyendaError= "Debe ingresar un documento válido"
                                        expresionRegular={er.documento}
                                        tipo={"text"}
                                        inputProps={{maxLength:"8"}}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4} >
                        <DatePickerComponent
                            label="Fecha de nacimiento"
                            formato="dd/MM/yyyy"
                            estado={fechaNacimiento}
                            setEstado={setFechaNacimiento}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <InputComponent sx={{width: "100%"}}
                                        label="Primer Nombre"
                                        type="outlined"
                                        required={true}
                                        estado={primerNombre}
                                        setEstado={setPrimerNombre}
                                        leyendaError= "Debe ingresar un primer nombre"
                                        expresionRegular={er.nombre}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputComponent sx={{width: "100%"}}
                                        label="Segundo Nombre"
                                        type="outlined"
                                        required={false}
                                        estado={segundoNombre}
                                        setEstado={setSegundoNombre}
                                        leyendaError= "Debe ingresar un segundo nombre"
                                        expresionRegular={""}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputComponent sx={{width: "100%"}}
                                        label="Primer Apellido"
                                        type="outlined"
                                        required={true}
                                        estado={primerApellido}
                                        setEstado={setPrimerApellido}
                                        leyendaError= "El apellido debe tener menos de 30 caracteres"
                                        expresionRegular={er.nombre}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputComponent sx={{width: "100%"}}
                                        label="Segundo Apellido"
                                        type="outlined"
                                        required={true}
                                        estado={segundoApellido}
                                        setEstado={setSegundoApellido}
                                        leyendaError= "Debe ingresar un segundo apellido"
                                        expresionRegular={er.nombre}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputComponent sx={{width: "100%"}}
                                        label="Teléfono"
                                        type="outlined"
                                        required={true}
                                        estado={telefono}
                                        setEstado={setTelefono}
                                        leyendaError= "El télefono debe tener entre 8 y 9 digitos"
                                        expresionRegular={er.telefono}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <InputComponent sx={{width: "100%"}}
                                        label="E-Mail"
                                        type="outlined"
                                        required={true}
                                        estado={mail}
                                        setEstado={setMail}
                                        leyendaError= "El mail debe tener el siguiente formato: aaa@bbb.ccc"
                                        expresionRegular={er.correo}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <SelectComponent label="País"
                                         items={paisesList}
                                         required={true}
                                         estado={pais}
                                         setEstado={setPais}
                                         default={"UY"}
                                         sx={{'& > :not(style)': {width: '30ch'}}}/>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <SelectComponent label="Departamento"
                                         items={departamentosList}
                                         required={true}
                                         estado={departamento}
                                         setEstado={setDepartamento}
                                         sx={{'& > :not(style)': {width: '30ch'}}}/>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <SelectComponent label="Ciudad"
                                         items={ciudadesList}
                                         required={true}
                                         estado={ciudad}
                                         setEstado={setCiudad}
                                         sx={{'& > :not(style)': {width: '30ch'}}}/>
                    </Grid>
                    <Grid item xs={12} >
                        <InputComponent sx={{width: "100%"}}
                                        label="Dirección"
                                        type="outlined"
                                        required={true}
                                        estado={direccion}
                                        setEstado={setDireccion}
                                        leyendaError= "Debe ingresar una dirección"
                                        expresionRegular={er.texto255}
                                        multiline={true}
                                        maxRows={4}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Stack spacing={2} direction="row">
                <ButtonComponent label="Aceptar" type="contained" onClick={handleAceptar}/>
                <ButtonComponent label="Cancelar" type="outlined" onClick={handleCancelar}/>
            </Stack>
        </Box>

    )
};

export default PersonaNuevaComponent;