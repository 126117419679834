import React, {useEffect} from 'react';
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import {useStateContext} from "../../../../contexts/ContextProvider";
import Typography from "@mui/material/Typography";
import AutomotorModificarComponent from "./automotor.modificar.component";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPrestamo} from "../../../../store/slices/prestamo.slice";
import Grid from "@mui/material/Grid";
import {Fab} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PrestamoModificarComponent = (props) => {



    const theme = useTheme();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let {id} = useParams();
    const {open, setOpen, title, setTitle} = useStateContext();
    const {prestamo} = useSelector(state => state.prestamos)

    useEffect(() => {
        setTitle("Mofidicar Prestamo");
        dispatch(getPrestamo(id)).then(()=>{

        }).catch(()=>{

        });

    },[dispatch]);

    const handleAtras = () => {
        return navigate(-1);
    }

    //TODO: validaciones / carga de datos / chequeo de validaciones prvio a guardar.

    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
                elevation={3}>

                {prestamo && (<>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={11}  >
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                >
                                    <b> Modificar Prestamo #</b> {prestamo.id}
                                </Typography>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="#9C9897"
                                    noWrap
                                    marginLeft="2%"
                                >
                                    <b>{prestamo.tipoPrestamo}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}  sm={1} >
                                <Fab sx={{mb: 0, ml: 0}}
                                     title={"Volver atras"}
                                     size = "medium"
                                     color="primary"
                                     aria-label="add"
                                     onClick={handleAtras}
                                >
                                    <ArrowBackIcon/>
                                </Fab>
                            </Grid>
                            <Grid item xs={12} style={{paddingTop: '0px'}}  >
                                <hr />{/*LINEA HORIZONTAL*/}
                            </Grid>
                        </Grid>
                        {prestamo.tipoPrestamo === "AUTOMOTOR" &&
                            <AutomotorModificarComponent />
                        }
                        {prestamo.tipoPrestamo !== "AUTOMOTOR" &&
                            <Typography>
                                No existe el tipo de prestamo
                            </Typography>
                        }
                    </>
                )}
            </Paper>

        </Container>
    );
};

export default PrestamoModificarComponent;