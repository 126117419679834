import {useRoutes, Navigate} from "react-router-dom";
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";


export default function ThemeRoutes() {
    const defaultRoutes = {
        path:"*",
        element: <Navigate replace to= "/" />
    }
    return useRoutes([MainRoutes, LoginRoutes, defaultRoutes]);
}