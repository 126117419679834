import React from 'react';
import TextField from '@mui/material/TextField'

const InputNumericComponent =  (props) => {

    const onChange = (e) => {
        var value = e.target.value
        props.setEstado({...props.estado, valor: value})


    }
    const onBlur = (e) => {
        var value = e.target.value
        if(value === "")
            props.setEstado({...props.estado, valor: "0"})

    }
    const blur = (e) => {
        var value = e.target.value
        if(value === "0")
            props.setEstado({...props.estado, valor: ""})
    }

    const validacion = (e) => {
        if(props.expresionRegular){
            if(props.expresionRegular.test(props.estado.valor)){
                props.setEstado({...props.estado, valido: true})
                //console.log("VERDADERO");
            }
            else{
                props.setEstado({...props.estado, valido: false})
                    //console.log("FALSO");
            }
        }

    }
    return (
            <TextField
                id="outlined-basic"
                sx={props.sx}
                label={props.label}
                variant={props.type}
                required={props.required}
                value={props.formato? props.estado.valor.toLocaleString(props.formato): props.estado.valor}
                onChange={onChange}
                type={"number"}
                InputProps={{ inputProps: { min: 0 } }}
                onBlur={onBlur}
                onFocus={blur}
                error ={!(props.estado.valido)}
                helperText= {props.estado.valido ? "":props.leyendaError}
                disabled={props.disabled ? props.disabled :false}
            />
    )

}

export default InputNumericComponent