import axiosPrivate, {axiosError} from "../api/axios";
import authHeader from "./auth-header";

const fetchAllComunicaciones = (fechaDesde, fechaHasta, tipoComunicacion, prestamoId, usuario) => {
    const path = "/comunicacion/todos"
    const params = {
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
        tipoComunicacion: tipoComunicacion,
        prestamoId: prestamoId,
        usuario: usuario
    }
    const config = {
        params,
        headers: authHeader()
    };
    return axiosPrivate.get(path, config)
        .then((response) => {
            console.log("Comunicaciones service");
            console.log(response.data)
            console.log(response.data)
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

const addComunicacion = (nuevaComunicacion) => {
    const path = '/comunicacion/nueva'
    const config = {
        headers: authHeader()
    }
    return axiosPrivate.post(path, nuevaComunicacion, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err);
        })
}

export default {
    fetchAllComunicaciones,
    addComunicacion
};