import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function ScrollToTop(){
    const {pathname} = useLocation();

    useEffect(() =>{
        //console.log("scrollTOP previo")
        if(document.getElementById("main")) {
            document.getElementById("main").scrollTo({
                top: 0,
                left: 0,
                behavior: "instant"
            })
            //console.log("scrollTOP")
        }
    }, [pathname]);
    return null;
}