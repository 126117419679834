import React, {useCallback, useEffect, useState} from 'react'
import {useParams, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import {Fab, Stack, Switch} from "@mui/material";
import {expresiones as er} from "../../../common/expresionesRegulares";
import InputComponent from "../../../components/input.component";
import Box from "@mui/material/Box";
import ButtonComponent from "../../../components/button.component";
import Divider from "@mui/material/Divider";
import {setearMensaje} from "../../../store/slices/snackbar.slice";
import {addFormaPago, getFormaPago} from "../../../store/slices/forma.pago.slice"
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import TabPanel from "../../../components/tabpanel.component";

const FormaPagoNuevoComponent = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [nombre, setNombre] = useState({valor: "", valido:true});

    const [checkP1, setCheckP1] = useState( false)
    const [nombreP1, setNombreP1] = useState({valor: "", valido:true});

    const [checkP2, setCheckP2] = useState(false)
    const [nombreP2, setNombreP2] = useState( {valor: "", valido:true});



    const handleChangeSwitchP1 = (event) => {
        if (checkP1)
            setCheckP2(false)
        setCheckP1(event.target.checked)
    }
    const handleChangeSwitchP2 = (event) => {
        if (checkP1)
            setCheckP2(event.target.checked)
    }

    const handleAceptar = (e) => {
        e.preventDefault();
        if (validarFormulario()) {
                // CREAR
                const formaPago = {
                    nombre: nombre.valor,
                    nombreReferencia1: checkP1 ? nombreP1.valor: "",
                    nombreReferencia2: checkP2 ? nombreP2.valor: ""
                }

                dispatch(addFormaPago(formaPago)).then((formaPago) => {
                    navigate("/formas_pagos", {replace: true, });
                    const mensaje = {
                        message: "Forma Pago " + formaPago.nombre + " creada con éxito",
                        severity: "success",
                    }
                    dispatch(setearMensaje(mensaje));
                }).catch( () => {
                    const mensaje = {
                        mensaje: "Error al guardar forma de pago",
                        serverity: "error"
                    }
                    dispatch(setearMensaje(mensaje));
                })
        } else {
            const mensaje = {
                message: "El formulario está incompleto",
                severity: "warning",
            }
            dispatch(setearMensaje(mensaje));
        }
    }
    const handleCancelar = (e) => {
        e.preventDefault();
        return navigate(-1);
    }



    const validarFormulario = () => {
        let valido= true;

        if (
            nombre.valido === false ||
            (checkP1 === true && nombreP1.valido === false) ||
            (checkP2 === true && nombreP2.valido === false)
        ){
            valido = false
        }
        if(
            nombre.valor === "" ||
            (checkP1 === true && nombreP1.valor === null) ||
            (checkP2 === true && nombreP2.valor === null)
        )
        {
            valido = false;
        }
        return valido;
    }

    return (
        <Container maxWidth="lg" sx={{mt: 12, mb: 4}}>

            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',


                }}
                elevation={3}>


                <Box sx={{width: '100%'}}>
                    <Typography
                        component="h5"
                        variant="h5"
                        color="inherit"
                        noWrap
                    >
                        <> Nueva Forma Pago</>
                    </Typography>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>

                    </Box>
                    <TabPanel value={0} index={0}>


                    <Box component="form"
                         sx={{
                             '& > :not(style)': {m: 1, width: '100%'},
                         }}
                         noValidate
                         autoComplete="off">
                        <Box sx={ {flexGrow: 1}}>
                            <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                            <InputComponent sx={{width: "90%"}}
                                                            label="Nombre"
                                                            type="outlined"
                                                            required={true}
                                                            estado={nombre}
                                                            setEstado={setNombre}
                                                            leyendaError= "Debe ingresar un nombre"
                                                            expresionRegular={er.texto255}
                                                            tipo={"text"}
                                            />
                                    </Grid>
                                    <Grid item xs={12} lg={1}>
                                        <Switch
                                            checked={checkP1}
                                            sx={{marginTop: "10%"}}
                                            onChange={handleChangeSwitchP1}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={11}>
                                        <InputComponent sx={{width: "90%"}}
                                                        label="Nombre Parámetro 1"
                                                        type="outlined"
                                                        required={false}
                                                        estado={nombreP1}
                                                        setEstado={setNombreP1}
                                                        leyendaError= "Debe ingresar un nombre de parámetro"
                                                        expresionRegular={er.texto255}
                                                        disabled={!checkP1}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={1}>
                                        <Switch
                                            checked={checkP2}
                                            sx={{marginTop: "10%"}}
                                            onChange={handleChangeSwitchP2}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={11}>
                                        <InputComponent sx={{width: "90%"}}
                                                        label="Nombre Parámetro 2"
                                                        type="outlined"
                                                        required={false}
                                                        estado={nombreP2}
                                                        setEstado={setNombreP2}
                                                        leyendaError= "Debe ingresar un nombre de parámetro"
                                                        expresionRegular={er.texto255}
                                                        disabled={!checkP2}
                                        />
                                    </Grid>
                                    <Divider  sx={{ mt:'3ch', mb:'3ch'}}/>
                                    <Grid container spacing={3}>

                                    </Grid>

                                </Grid>
                        </Box>
                                <Stack spacing={2} direction="row">
                                    <ButtonComponent label="Aceptar" type="contained" onClick={handleAceptar}/>
                                    <ButtonComponent label="Cancelar" type="outlined" onClick={handleCancelar}/>
                                </Stack>
                         </Box>
                    </TabPanel>
                </Box>

            </Paper>
        </Container>
    )
}

export default FormaPagoNuevoComponent;