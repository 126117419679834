export const expresiones = {
    usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,30}$/, // Letras y espacios, pueden llevar acentos. Max 30 digitos
    nombreNoObligatorio: /^[a-zA-ZÀ-ÿ\s]{0,30}$/, // Letras y espacios, pueden llevar acentos. Max 30 digitos
    direccion: /^[a-zA-ZÀ-ÿ\s]{1,50}$/,// Letras y espacios, pueden llevar acentos. Max 50 digitos
    password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{8,9}$/,// 7 a 14 numeros.
    documento: /^[\s\S]{7,8}$/, // 7 a 8 digitos.
    importePrestado: /^\d{1,9}$/, // 1 a 9 numeros.
    importeFinanciado: /^\d{1,9}$/, // 1 a 9 numeros.
    valorCuota: /^\d{1,9}$/, // 1 a 9 numeros.
    marca: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    numericoConCero: /^\d{0,9}$/, // 0 a 9 numeros.
    textoNumeroConEspacios: /^[a-zA-ZÀ-ÿ0-9\s]{1,40}$/, // Letras, numeros y espacios, pueden llevar acentos.
    matricula: /^([A-Z]{3}[0-9]{1,4}$)|(^[A-Z]{1}[0-9]{1,6}$)/,
    texto255: /^[\s\S]{1,255}$/, // Letras, numeros y espacios, pueden llevar acentos.
    texto255Vacio: /^[\s\S]{0,255}$/, // Letras, numeros y espacios, pueden llevar acentos(acepta vacio).
    // numericoConDecimales:/^\d[0,9]+\.?$/, // Numeros y decimal con punto .
    numericoConDecimales: /^[0-9]+\.?[0-9]*$/,
    numericoMayorCero: /^\d{1,9}$/,
    //fecha: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/, // yyyy-MM-dd


}