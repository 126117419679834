import authHeader from "./auth-header";
import {axiosError, axiosPrivate} from "../api/axios";

const fetchAllMetodosPagos = (pagoId, fechaDesde, fechaHasta, formaPagoId, ubicacionEfectivo) => {
    const path = "/metodo_pago/todos"
    const params = "?pagoId=" + pagoId + "&fechaDesde=" + fechaDesde + "&fechaHasta=" + fechaHasta + "&formaPagoId=" + formaPagoId + "&ubicacionEfectivo=" + ubicacionEfectivo;
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path+params, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err)
        });
}

const putCambioUbicacionEfectivo = (ubicacion, ids) => {
    const path = "/metodo_pago/efectivo/cambio_ubicacion/masivo";
    const params = "?ubicacionEfectivo="+ubicacion;
    const config = {
        headers: authHeader()
    }
    return axiosPrivate.put(path+params, ids, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err)
        });
}

const exportAllMetodosPagos = (formato, pagoId, fechaDesde, fechaHasta, formaPagoId, ubicacionEfectivo) => {
    const path = "/reportes/detalle_metodos_pago"
    const params = "?formato="+formato+"&pagoId=" + pagoId + "&fechaDesde=" + fechaDesde + "&fechaHasta=" + fechaHasta + "&formaPagoId=" + formaPagoId + "&ubicacionEfectivo=" + ubicacionEfectivo;
    const config = {
        headers: authHeader(),
        responseType: "blob",
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
           const reporte = {
                data: response.data,
                type: response.headers['content-type'],
                filename: response.headers['content-disposition'].split('filename=')[1].replaceAll('"', "")
            }
            return reporte
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

export default  {
    fetchAllMetodosPagos,
    putCambioUbicacionEfectivo,
    exportAllMetodosPagos
}