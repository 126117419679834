import axiosPrivate, {axiosError} from "../api/axios";
import authHeader from "./auth-header";

const fetchAllDashboard = () => {
    const path = "/dashboard/obtener"
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path, config)
        .then((response) => {
            console.log("dashboard service");
            console.log(response.data)
            console.log(response.data)
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

export default {
    fetchAllDashboard,
};