import {createSlice} from "@reduxjs/toolkit";
import reporteService from "../../services/reporte.service";
import {clearMessage, setMessage,setearMensaje} from "./snackbar.slice"
import {errores} from "../../common/errores";
import {logout} from "./autenticacion.slice";

const initialState = {
    proyeccionIngresosList: [],
    saldos: null,
}

export const reporteSlice = createSlice({
    name: "reportes",
    initialState,
    reducers: {
        setProyeccionIngresosList: (state, action) => {
            state.proyeccionIngresosList = action.payload;
        },
        proyeccionIngresosListError: (state) =>{
            state.proyeccionIngresosList = [];
        },
        setSaldos: (state, action) => {
            state.saldos = action.payload;
        },
        saldosError: (state) =>{
            state.saldos = null;
        },
    }
});
const {setProyeccionIngresosList, proyeccionIngresosListError, setSaldos, saldosError} = reporteSlice.actions;
export default reporteSlice.reducer;

export const proyeccionIngresos = () => (dispatch) => {
    return reporteService.proyeccionIngresos().then(
        (data) => {
            dispatch(setProyeccionIngresosList(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            dispatch(proyeccionIngresosListError())
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const consultaSaldos = () => (dispatch) => {
    return reporteService.consultaSaldos().then(
        (data) => {
            dispatch(setSaldos(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            dispatch(saldosError())
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
