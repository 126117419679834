import React from 'react';
import Routes from "./routes";
import {Provider, useDispatch, useSelector} from "react-redux";
import store from "./store/store";
import {BrowserRouter, useNavigate} from "react-router-dom";
import {history} from "./helpers/history";
import {ContextProvider} from "./contexts/ContextProvider";
import {LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import { esES } from '@mui/x-data-grid';
import { esES } from '@mui/material/locale';
import { esES as coreEsES } from '@mui/material/locale';
import { SnackbarProvider} from 'notistack';
import {entornos} from "./config";
import ScrollToTop from "./helpers/scrollToTop";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import {ConfirmProvider} from "material-ui-confirm";

const theme = createTheme(
    {
        palette: {
            //primary: { main: '#1976d2' },
            primary: { main: entornos[process.env.REACT_APP_ENTORNO].color },
            //mode: "dark",
        },
    },
    esES, // x-data-grid translations
    //pickersEsES, // x-date-pickers translations
    coreEsES, // core translations
);

const App = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <BrowserRouter history={history}>
                        <ContextProvider >
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale} >
                                <SnackbarProvider maxSnack={4}>
                                    <ConfirmProvider>
                                        <ScrollToTop/>
                                        <Routes/>
                                    </ConfirmProvider>
                                </SnackbarProvider>
                            </LocalizationProvider>
                        </ContextProvider>
                    </BrowserRouter>
                </Provider>
            </ThemeProvider>
        </>
    );
};

export default App;