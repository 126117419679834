import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React from 'react'
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";


const FooterComponent = (props) => {
    return (
        <Paper
            elevation={2}
            sx={{
                pt:1,
                pb:1,
                position: '',
                bottom: 0,
                width: '100%',
                height: '5vh',
                zIndex: 9999}}
        >
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                <Link color="inherit" href="http://www.quapro.tech/">
                    QuaPro Technologies
                </Link>{' - '}
                {'Copyright © '}

                {new Date().getFullYear()}
                {'.'}

            </Typography>
        </Paper>
    );
}

export default FooterComponent