import React, {useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import {useStateContext} from "../../../contexts/ContextProvider";
import {Chip, Fab} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    bloquearCliente,
    desbloquearCliente,
    getClientePersona,
    getClientePrestamos
} from "../../../store/slices/cliente.slice";
import Typography from "@mui/material/Typography";
import GridComponent from "../../../components/grid.component";
import {GridActionsCellItem} from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuDeplegableComponent from "../../../components/menu.deplegable.component";
import BlockIcon from '@mui/icons-material/Block';
import AddIcon from "@mui/icons-material/Add";
import {setearMensaje} from "../../../store/slices/snackbar.slice";
import Button from "@mui/material/Button";
import {useCallback} from "react";
import TextboxComponent from "../../../components/textbox.component";
import {formatDDMMYYYY} from "../../../common/fechaFormat";
import {EstadoCliente} from "../../../common/enum/estadoCliente";
import {EstadoPrestamo} from "../../../common/enum/estadoPrestamo";

const PersonaViewComponent = (props) => {

    let {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {open, setOpen, title, setTitle} = useStateContext();
    const {cliente, prestamosClienteList} = useSelector(state => state.clientes)
    const [itemsMenu, setItemsMenu] = React.useState([]);

    useEffect(() => {
        setTitle("Cliente");
        dispatch(getClientePersona(id)).then(()=>{
        }).catch(()=>{});
        dispatch(getClientePrestamos(id)).then(()=>{}).catch(()=>{});
    },[dispatch]);

    useEffect(()=>{
        if(cliente!== null) {
            //console.log("hay cliente");
            setItemsMenu([
                {
                    accion: "Editar",
                    icono: <EditIcon/>,
                    onClick: modificarCliente(id),
                    activo: true
                },
                {
                    accion: (cliente.estado === EstadoCliente.ACTIVO) ? "Bloquear" : "Desbloquear",
                    icono: <BlockIcon/>,
                    onClick: (cliente.estado === EstadoCliente.ACTIVO) ? handleBloquearCliente : handleDesloquearCliente,
                    activo: true,
                },
                {
                    accion: "Prueba",
                    icono: <BlockIcon/>,
                    onClick: null,
                    activo: false,
                },
            ]);
        }
    },[cliente]);

    const { message } = useSelector(state => state.mensaje);

    const handleAtras = () => {
        return navigate(-1);
    }
    const verPrestamo = useCallback(
        (id) => () => {
            //console.log("El id es: "+ id);
            navigate("/prestamos/"+id);
        }
    );
    const modificarCliente = useCallback(
        (id) => () => {
            //console.log("El id es: "+ id);
            navigate("/clientes/modificar/"+id);
        }
    );
    const estadoPrestamo = (estado) => {
        //console.log("estadoPrestamo: " + estado)
        const dato = {
            value: estado,
            color: "secondary",
            variant: "outlined"
        }
        if (estado == "AL_DIA") {
            dato.value = EstadoPrestamo.AL_DIA
            dato.color = "success"
            dato.variant = "outlined"

        } else if (estado === "FINALIZADO") {
            dato.value = EstadoPrestamo.FINALIZADO
            dato.color = "success"
            dato.variant = "filled"
        }
        else if (estado === "DEUDOR_CONSENSUADO") {
            dato.value = EstadoPrestamo.DEUDOR_CONSENSUADO
            dato.color = "warning"
            dato.variant = "outlined"
        }
        else if (estado === "DEUDOR_GRAVE") {
            dato.value = EstadoPrestamo.DEUDOR_GRAVE
            dato.color = "warning"
            dato.variant = "filled"
        }
        else if (estado === "EN_JUICIO"){
            dato.value = EstadoPrestamo.EN_JUICIO
            dato.color = "error"
            dato.variant = "filled"
        }
        else if (estado === "CANCELADO") {
            dato.value = EstadoPrestamo.CANCELADO
            dato.color = "error"
            dato.variant = "outlined"
        }
        return (
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                sx={{fontSize: "10px", fontWeight: 'bold'}}
            />
        );
    };
    const columnsPrestamo = [
        {
            field: 'actions',
            type: 'actions',
            width: 50,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<VisibilityIcon />}
                    label="Ver"
                    onClick={verPrestamo(params.id)}

                />,
            ],
        },
        {   field: 'id',
            headerName: '#',
            width: 55,
            hide: true,
        },
        {   field: 'numeroPrestamo',
            headerName: '#',
            width: 80,
            valueGetter: (param) => (param.value ? param.value : param.row.id) + "-" + param.row.serie
        },
        {
            field: 'fechaAlta',
            headerName: 'Fecha Alta',
            type: 'date',
            width: 100,
            editable: false,
            valueGetter: ({ value }) => value && new Date(value+"T00:00:00")
        },
        {
            field: 'estadoPrestamo',
            headerName: 'Estado',
            width: 150,
            editable: false,
            renderCell: (param) => (
                estadoPrestamo(param.value)
            ),
        },
        {
            field: 'importePrestado',
            headerName: 'Importe',
            type: 'number',
            width: 90,
            editable: false,
            valueGetter: (param) => param.row.simboloMoneda+ " "+ param.value.toLocaleString("es-UY")
        },
        {
            field: 'saldoPendientePrestamo',
            headerName: 'Pendiente',
            type: 'number',
            width: 90,
            editable: false,
            valueGetter: (param) => param.row.simboloMoneda+ " "+ param.value.toLocaleString("es-UY")
        },
        {
            field: 'saldo',
            headerName: 'CTA CTE',
            type: 'number',
            width: 80,
            editable: false,
            valueGetter: (param) => param.row.simboloMoneda+ " "+ param.value.toLocaleString("es-UY")
        },
        {
            field: 'detalle',
            headerName: 'Detalle',
            width: 300,
            editable: false,
        },

    ];

    const handleBloquearCliente = (e) =>{
        e.preventDefault()
        const clienteBloquear ={
            idCliente: cliente.id,
        }
        dispatch(bloquearCliente(clienteBloquear)).then(()=>{}).catch(()=>{})
    }
    const handleDesloquearCliente = (e) =>{
        e.preventDefault();
        const clienteDesbloquear ={
            idCliente: cliente.id,
        }
        dispatch(desbloquearCliente(clienteDesbloquear)).then(()=>{}).catch(()=>{})
    }
    const handleNuevoPrestamo = (e) =>{

        //console.log("Nuevo prestamo");
        if(cliente.estado === EstadoCliente.ACTIVO){
            const params = {
                idCliente: cliente.id
            };
            navigate("/prestamos/nuevo", {state: params});
        }

        else {
            const mensaje ={
                message: "El cliente se encuentra bloqueado, no puede tener préstamos",
                severity: "warning"
            }
            dispatch(setearMensaje(mensaje));
        }
    }
    const estadoCliente = (estado) => {
        const dato = {
            value: estado,
            color: "string",
            variant: "outlined"
        }
        if (estado === EstadoCliente.ACTIVO) {
            dato.value = "ACTIVO"
            dato.color = "success"
            dato.variant = "outlined"

        } else if (estado === EstadoCliente.BLOQUEADO) {
            dato.value = "BLOQUEADO"
            dato.color = "error"
            dato.variant = "filled"
        }
        else if (estado === EstadoCliente.BAJA) {
            dato.value = "BAJA"
            dato.color = "error"
            dato.variant = "filled"
        }
        return dato;
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                        elevation={3}>

                        {cliente && (<>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={10}  >
                                    <Typography
                                        component="h1"
                                        variant="h4"
                                        color="inherit"
                                        noWrap
                                        marginLeft="2%"
                                    >
                                        <b>Cliente </b>{cliente.primerNombre} {cliente.segundoNombre} {cliente.primerApellido} {cliente.segundoApellido}
                                    </Typography>
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        color="#9C9897"
                                        noWrap
                                        marginLeft="2%"
                                    >
                                        <b>{cliente.tipoCliente}PERSONA</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}  sm={1} >
                                    <Fab sx={{mb: 0, ml: 0}}
                                         title={"Volver atrás"}
                                         size = "medium"
                                         color="primary"
                                         aria-label="add"
                                         onClick={handleAtras}
                                            >
                                        <ArrowBackIcon/>
                                    </Fab>
                                </Grid>
                                <Grid item xs={6}  sm={1} >

                                            <Fab sx={{mb: 0, ml: 0}}
                                                 size = "medium"
                                                 color="primary"
                                                 aria-label="add"
                                            >
                                                <MenuDeplegableComponent titulo={"Opciones"} items={itemsMenu}/>
                                            </Fab>

                                </Grid>


                                <Grid item xs={12} style={{paddingTop: '0px'}}  >
                                    <hr />{/*LINEA HORIZONTAL*/}
                                </Grid>

                                <Grid item xs={12} lg={4}  >
                                    <TextboxComponent
                                        titulo={{valor: "Tipo documento", negrita: true}}
                                        dato={{valor: cliente.tipoDocumento, negrita:true}}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4} >
                                    <TextboxComponent
                                        titulo={{valor: "Documento", negrita: true}}
                                        dato={{valor: cliente.documento, negrita:true}}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}  >
                                    <TextboxComponent
                                        titulo={{valor:"Estado", negrita: true}}
                                        etiqueta={{label: estadoCliente(cliente.estado).value,
                                                    color:estadoCliente(cliente.estado).color,
                                                    variant: estadoCliente(cliente.estado).variant,
                                                    size: "medium",
                                                    negrita: true}}
                                    />

                                </Grid>
                                <Grid item xs={12} lg={4} >
                                    <TextboxComponent
                                        titulo={{valor: "Fecha de nacimiento", negrita: true}}
                                        dato={{valor: formatDDMMYYYY(new Date(cliente.fechaNacimiento+"T00:00:00"))}}
                                    />
                                </Grid>
                                <Grid item xs={0} lg={8}/>
                                <Grid item xs={12} lg={4} >
                                    <TextboxComponent
                                        titulo={{valor: "País", negrita: true}}
                                        dato={{valor: cliente.paisNombre}}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4} >
                                    <TextboxComponent
                                        titulo={{valor: "Departamento", negrita: true}}
                                        dato={{valor: cliente.departamentoNombre}}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4} >
                                    <TextboxComponent
                                        titulo={{valor: "Ciudad", negrita: true}}
                                        dato={{valor: cliente.ciudadNombre}}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4} >
                                    <TextboxComponent
                                        titulo={{valor: "Teléfono", negrita: true}}
                                        dato={{valor: cliente.telefono}}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={8} >
                                    <TextboxComponent
                                        titulo={{valor: "Email", negrita: true}}
                                        dato={{valor: cliente.mail}}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} >
                                    <TextboxComponent
                                        titulo={{valor: "Dirección", negrita: true}}
                                        dato={{valor: cliente.direccion, align:'left', wrap: true}}
                                        //wrap={true}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <hr />{/*LINEA HORIZONTAL*/}
                                </Grid>
                                <Grid item xs={12} lg={11} style={{paddingTop: '0px', paddingBottom: '1%'}} >
                                    <Typography
                                        component="h1"
                                        variant="h5"
                                        color="inherit"
                                        noWrap
                                        marginLeft="2%"
                                        align="center"
                                    >
                                        <b>Prestamos</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}  sm={1} >
                                    <Fab sx={{mb: 2, ml: 0}} size = "medium" color="primary" aria-label="add" component= {Button} onClick={handleNuevoPrestamo} >
                                        <AddIcon   />
                                    </Fab>
                                </Grid>

                            </Grid>
                            <Paper
                                elevation={4}>
                                <GridComponent
                                    initialState={
                                        {sorting: {
                                                sortModel: [{ field: 'numeroPrestamo',
                                                    sort: 'desc' }],
                                            }
                                        }
                                    }
                                    rows={prestamosClienteList} columns={columnsPrestamo}  rowHeight={30}  pageSize={5} height={300} mensajeSinFilas={"El cliente no tiene prestamos"} />
                            </Paper>
                        </>
                        )}
                    </Paper>

        </Container>
    );
};

export default PersonaViewComponent;