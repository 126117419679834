import LoginComponent from "../pages/login/login.component";
import MinimalLayout from "../layout/MinimalLayout";


const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <LoginComponent />
        },

    ]
};
export default LoginRoutes;