export const formatYYYYMMDD = (fecha) =>{
    var mm = fecha.getMonth() + 1;
    var dd = fecha.getDate();

    return [fecha.getFullYear(),
        (mm>9 ? '' : '0') + mm,
        (dd>9 ? '' : '0') + dd
    ].join('-');
};

export const formatDDMMYYYY = (fecha) =>{
    var mm = fecha.getMonth() + 1;
    var dd = fecha.getDate();

    return [
        (dd>9 ? '' : '0') + dd,
        (mm>9 ? '' : '0') + mm,
        fecha.getFullYear()
    ].join('/');
};
export const formatHHMMSS = (fecha) =>{
    var hh = fecha.getHours();
    var mm = fecha.getMinutes();
    var ss = fecha.getSeconds();

    return [
        (hh>9 ? '' : '0') + hh,
        (mm>9 ? '' : '0') + mm,
        (ss>9 ? '' : '0') + ss,
    ].join(':');
};

export const meses = {
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre"
}
