import {Link, useNavigate} from 'react-router-dom'
import React, {useEffect} from 'react'
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useStateContext} from "../../contexts/ContextProvider";
import GridComponent from "../../components/grid.component";
import {GridActionsCellItem, GridColDef} from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {deletePrestamo, getAllPrestamos} from "../../store/slices/prestamo.slice"
import {Chip, Fab} from "@mui/material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import {useCallback} from "react";
import {setearMensaje} from "../../store/slices/snackbar.slice";
import InputComponent from "../../components/input.component";
import DatePickerComponent from "../../components/date.picker.component";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import SearchIcon from "@mui/icons-material/Search";
import {formatYYYYMMDD} from "../../common/fechaFormat";
import SelectComponent from "../../components/select.component";
import {EstadoPrestamo, EstadoPrestamoEquals} from "../../common/enum/estadoPrestamo";




const PrestamosComponent = () => {

    const navigate = useNavigate();
    const verPrestamo = useCallback(
        (id) => () => {
            navigate("/prestamos/"+id);
        }
    );
    const modificarPrestamo = useCallback(
        (id) => () => {
            navigate("/prestamos/modificar/"+id);
        }
    );
    const eliminarPrestamo = useCallback(
        (id) => () => {
            dispatch(deletePrestamo(id)).then(()=>{
                const mensaje = {
                    message: "Préstamo eliminado con éxito",
                    severity: "success",
                }
                dispatch(setearMensaje(mensaje))
                dispatch(getAllPrestamos()).then(()=>{}).catch(()=>{});

            }).catch(()=> {

            })
        }
    );
    const estadoPrestamo = (estado) => {
        //console.log("estadoPrestamo: " + estado)
        const dato = {
            value: estado,
            color: "secondary",
            variant: "outlined"
        }
        if (estado == "AL_DIA") {
            dato.value = EstadoPrestamo.AL_DIA
            dato.color = "success"
            dato.variant = "outlined"

        } else if (estado === "FINALIZADO") {
            dato.value = EstadoPrestamo.FINALIZADO
            dato.color = "success"
            dato.variant = "filled"
        }
        else if (estado === "DEUDOR_CONSENSUADO") {
            dato.value = EstadoPrestamo.DEUDOR_CONSENSUADO
            dato.color = "warning"
            dato.variant = "outlined"
        }
        else if (estado === "DEUDOR_GRAVE") {
            dato.value = EstadoPrestamo.DEUDOR_GRAVE
            dato.color = "warning"
            dato.variant = "filled"
        }
        else if (estado === "EN_JUICIO"){
            dato.value = EstadoPrestamo.EN_JUICIO
            dato.color = "error"
            dato.variant = "filled"
        }
        else if (estado === "CANCELADO") {
            dato.value = EstadoPrestamo.CANCELADO
            dato.color = "error"
            dato.variant = "outlined"
        }
        return (
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                sx={{fontSize: "10px", fontWeight: 'bold'}}
            />
        );
    };
    const columns = [
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<VisibilityIcon />}
                    label="Ver"
                    onClick={verPrestamo(params.id)}

                />,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Editar"
                    onClick={modificarPrestamo(params.id)}
                />,
                /*
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Borrar"
                    onClick={eliminarPrestamo(params.id)}
                />,

                 */
            ],
        },
        {   field: 'id',
            headerName: '#',
            width: 30,
            hide: true,
        },
        {   field: 'numeroPrestamo',
            headerName: '#',
            width: 80,
            valueGetter: (param) => (param.value ? param.value : param.row.id) + "-" + param.row.serie
        },
        {
            field: 'fechaAlta',
            headerName: 'Fecha Alta',
            width: 100,
            type: 'date',
            editable: false,
            valueGetter: ({ value }) => value && new Date(value+"T00:00:00")
        },
        {
            field: 'estadoPrestamo',
            headerName: 'Estado',
            width: 170,
            editable: false,
            renderCell: (param) => (
                estadoPrestamo(param.value)
            ),
        },
        {
            field: 'importeFinanciado',
            headerName: 'Financiado',
            type: 'number',
            width: 100,
            editable: false,
            valueGetter: (param) => param.row.simboloMoneda+ " "+ param.value.toLocaleString("es-UY")
        },
        {
            field: 'saldoPendientePrestamo',
            headerName: 'Pendiente',
            type: 'number',
            width: 100,
            editable: false,
            valueGetter: (param) => param.row.simboloMoneda+ " "+ param.value.toLocaleString("es-UY")
        },
        {
            field: 'detalle',
            headerName: 'Detalle',
            width: 300,
            editable: false,
        },
        {
            field: 'nombreCliente',
            headerName: 'Nombre Cliente',
            width: 300,
            editable: false,
        }
    ];

    const {open, setOpen, title, setTitle} = useStateContext();
    const { prestamosList } = useSelector(state => state.prestamos);
    const dispatch = useDispatch();
    const fechaHoy = new Date()
    const fechaAntes = new Date()
    fechaAntes.setDate(fechaHoy.getDate() -2)

    const [numeroPrestamo, setNumeroPrestamo] = React.useState({valor: "", valido: true});
    const [serie, setSerie] = React.useState({valor: "", valido: true});
    const [fechaDesde, setFechaDesde] = React.useState({valor: null, valido: true});
    const [fechaHasta, setFechaHasta] = React.useState({valor: null, valido: true});
    const [estado, setEstado] = React.useState({valor: ""});

    const buscar = (e) => {
        e.preventDefault();
        dispatch(getAllPrestamos("", numeroPrestamo.valor, serie.valor, fechaDesde.valor ? formatYYYYMMDD(fechaDesde.valor) : "", fechaHasta.valor ? formatYYYYMMDD(fechaHasta.valor) : "", estado.valor)).then(()=>{}).catch(()=>{});
    }
    const clear = (e) => {
        e.preventDefault();
        setNumeroPrestamo({valor:"", valido:true})
        setSerie({valor:"", valido:true})
        setFechaDesde({valor:null})
        setFechaHasta({valor:null})
        setEstado({valor:""})
    }

    useEffect(() => {
        dispatch(getAllPrestamos("", numeroPrestamo.valor, serie.valor,fechaDesde.valor ? formatYYYYMMDD(fechaDesde.valor) : "", fechaHasta.valor ? formatYYYYMMDD(fechaHasta.valor) : "", estado.valor)).then(()=>{}).catch(()=>{});
    }, [dispatch]);

    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: "100%",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}  sm={9.5} >
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                >
                                    <b>Préstamos</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}  sm={2.5} >
                                <Fab sx={{mb: 2, ml: 2}} size = "medium" color="primary" aria-label="add" component= {Link} to="/prestamos/nuevo" >
                                    <AddIcon   />
                                </Fab>
                                <Fab sx={{mb: 2, ml: 2}} size = "medium" color="primary" aria-label="add" onClick={clear} >
                                    <DeleteSweepIcon   />
                                </Fab>
                                <Fab sx={{mb: 2, ml: 1}} size = "medium" color="primary" aria-label="add" onClick={buscar} >
                                    <SearchIcon   />
                                </Fab>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} paddingLeft={2}>
                            <Grid item xs={12}  md={2} >
                                <InputComponent sx={{width: "100%"}}
                                                label={"Nº Préstamo"}
                                                type="outlined"
                                                estado={numeroPrestamo}
                                                setEstado={setNumeroPrestamo}
                                                tipo={"number"}
                                />
                            </Grid>
                            <Grid item xs={12}  md={1} >
                                <InputComponent sx={{width: "100%"}}
                                                label={"Serie"}
                                                type="outlined"
                                                estado={serie}
                                                setEstado={setSerie}
                                                upperCase={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={2.5} >
                                <DatePickerComponent
                                    label="Fecha desde"
                                    formato="dd/MM/yyyy"
                                    estado={fechaDesde}
                                    setEstado={setFechaDesde}
                                />
                            </Grid>
                            <Grid item xs={12} md={2.5} >
                                <DatePickerComponent
                                    label="Fecha hasta"
                                    formato="dd/MM/yyyy"
                                    estado={fechaHasta}
                                    setEstado={setFechaHasta}
                                />
                            </Grid>
                            <Grid item sx={12} md={3} >
                                <SelectComponent label="Estado"
                                                 items={Object.entries(EstadoPrestamo).map(([key, value]) => ({id: key, descripcion:value}))}
                                                 estado={estado}
                                                 setEstado={setEstado}

                                                 sx={{'& > :not(style)': {width: '100%'}}}/>
                            </Grid>
                            <Grid item xs={12}  md={2} >

                            </Grid>
                        </Grid>
                        <GridComponent
                            initialState={
                                {sorting: {
                                    sortModel: [{ field: 'numeroPrestamo',
                                                sort: 'desc' }],
                                    }
                                }
                            }
                            rows={prestamosList} columns={columns} rowHeight={30}  pageSize={20} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
export default PrestamosComponent
