import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {EstadoPrestamo} from "../../../../../common/enum/estadoPrestamo";
import SelectComponent from "../../../../../components/select.component";
import {Box, Chip, Typography} from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {expresiones as er} from "../../../../../common/expresionesRegulares";
import InputComponent from "../../../../../components/input.component";
import {setearMensaje} from "../../../../../store/slices/snackbar.slice";
import {cambioEstadoPrestamo} from "../../../../../store/slices/prestamo.slice";

const PopupCambioestadoPrestamoComponent = (props) => {

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
   // const [estadoNuevo, setEstadoNuevo] = React.useState({valor: ""});
    //const [observacionEstado, setObservacionEstado] = React.useState({valor: "", valido: true});

    const { open,
            setOpen,
            prestamo,
            estado,
            handleCambiarEstado,
            estadoNuevo,
        setEstadoNuevo,
        observacionEstado,
        setObservacionEstado

    } = props;

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            >
            <DialogTitle> <b> Cambio de Estado Prestamo </b> </DialogTitle>
            <Divider  sx={{ mt:'0ch', mb:'1ch'}}/>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={5} >
                        <Typography
                            align={"center"}
                            sx={{mt:'1ch'}}
                        >
                            <Chip
                                label = {estado(prestamo.estadoPrestamo).value}
                                color = {estado(prestamo.estadoPrestamo).color}
                                variant = {estado(prestamo.estadoPrestamo).variant}
                                size = "medium"
                                sx={{fontWeight: 'bold', paddingRight:"10px", paddingLeft:"10px", fontSize:13 }}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={1} >
                        <KeyboardDoubleArrowRightIcon sx={{mt:'1ch'}} />
                    </Grid>
                    <Grid item xs={12} lg={6} >
                        <SelectComponent label="Nuevo Estado"
                                         items={Object.entries(EstadoPrestamo).map(([key, value]) => ({id: key, descripcion: value})).filter((elemento) => ((prestamo.estadoPrestamo !== elemento.id) && (EstadoPrestamo.FINALIZADO !== elemento.id ) && (EstadoPrestamo.REFINANCIADO !== elemento.id )))}
                                         estado={estadoNuevo}
                                         setEstado={setEstadoNuevo}
                                         sx={{'& > :not(style)': {width: '100%' }}}
                                         />
                    </Grid>
                </Grid>
                <InputComponent sx={{width: "100%", marginTop: 3}}
                                label="Observaciones"
                                type="outlined"
                                required={false}
                                estado={observacionEstado}
                                setEstado={setObservacionEstado}
                                leyendaError= "Supero el máximo de caracteres posible"
                                expresionRegular={er.texto255Vacio}
                                multiline={true}
                                maxRows={3}
                                tipo={"text"}
                                inputProps={{maxLength:"255"}}
                />
            </DialogContent>
            <DialogActions >
                <Button variant={"contained"} onClick={handleCambiarEstado} disabled={estadoNuevo.valor ==="" ? true : false} >Confirmar</Button>
                <Button variant={"outlined"} onClick={handleClose}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    )

}

export default PopupCambioestadoPrestamoComponent