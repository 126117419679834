import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {useStateContext} from "../../contexts/ContextProvider";
import {useEffect, useState} from 'react';
import AppWidgetSummary from "../../components/dashboard/widgetSummary";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BackspaceIcon from '@mui/icons-material/Backspace';
import {useDispatch, useSelector} from "react-redux";
import {getAllDashboard} from "../../store/slices/dashboard.slice";
import CuotasVencidas from "../../components/dashboard/cuotas_vencidas";
import Grafica from "../../components/dashboard/grafica";






function DashboardContent() {
    const {open, setOpen, title, setTitle} = useStateContext();
    const dispatch = useDispatch();
    const [cantPrestamosNuevos, setCantPrestamosNuevos] = useState(0);
    const [cantPrestamosActivos, setCantPrestamosActivos] = useState(0);
    const [recaudadoTotal, setRecaudadoTotal] = useState(0);
    const [cuotasVencidas, setCuotasVencidas] = useState(0);
    const {prestamosActivos, prestamosNuevos, recaudado, cantCuotasVencidas, cuotasVencidasList, grafica} = useSelector(state => state.dashboard);

    useEffect(() => {
        setTitle("Dashboard");
    });

    useEffect(() =>{
        dispatch(getAllDashboard()).then(()=>{}).catch(() => {});
    },[dispatch]);

    useEffect(() =>{
        setCantPrestamosActivos(prestamosActivos)
        setCantPrestamosNuevos(prestamosNuevos);
        setRecaudadoTotal(recaudado);
        setCuotasVencidas(cantCuotasVencidas);
    }, [prestamosNuevos, recaudado, cantCuotasVencidas])


    return (
        <div>
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}  sm={3}>
                            <AppWidgetSummary title={"Préstamos nuevos"}
                                              subtitle={"(Últimos 7 días)"}
                                              color="warning"
                                              total={cantPrestamosNuevos}
                                              icon={PriceCheckIcon} />
                        </Grid>
                        <Grid item xs={12}  sm={3}>
                            <AppWidgetSummary title={"Préstamos Activos"}
                                              subtitle={"(Total)"}
                                              color="info"
                                              total={cantPrestamosActivos}
                                              icon={PriceCheckIcon} />
                        </Grid>

                        <Grid item xs={12}  sm={3}>
                            <AppWidgetSummary title={"Recaudado"}
                                              subtitle={"(Hoy)"}
                                              color="success"
                                              total={recaudadoTotal}
                                              icon={AttachMoneyIcon} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <AppWidgetSummary title={"Cuotas Vencidas"}
                                              subtitle={"(Totales)"}
                                              color="error"
                                              total={cuotasVencidas}
                                              icon={BackspaceIcon} />
                        </Grid>
                        {/* Chart */}
                        <Grid item xs={12} md={8}>
                            <Grafica
                                title="Resumen de pagos"
                                subheader="(Por día)"
                                type={"line"}
                                chartLabels={ grafica.fechas ? grafica.fechas : []}
                                chartData={[
                                    {
                                        name: 'Total Cuotas',
                                        type: 'column',
                                        fill: 'solid',
                                        data: grafica.importesCuotas ? grafica.importesCuotas : [],
                                    },
                                    {
                                        name: 'Total Pagos',
                                        type: 'area',
                                        fill: 'gradient',
                                        data: grafica.importesPagos ? grafica.importesPagos : [],
                                    },
                                    {
                                        name: 'Adelantos',
                                        type: 'line',
                                        fill: 'solid',
                                        data: grafica.importesAdelanto ? grafica.importesAdelanto : [],
                                    },
                                    {
                                        name: 'Cancelación por saldo',
                                        type: 'line',
                                        fill: 'solid',
                                        data: grafica.importesCancelacionSaldo ? grafica.importesCancelacionSaldo : [],
                                    },
                                    {
                                        name: 'Descuentos',
                                        type: 'line',
                                        fill: 'solid',
                                        data: grafica.importeDescuento ? grafica.importeDescuento : [],
                                    },
                                ]}
                            />
                        </Grid>
                        {/* Recent Deposits */}
                        <Grid item xs={12} md={4}>
                            <CuotasVencidas
                                list={cuotasVencidasList}
                                />
                        </Grid>
                    </Grid>

                </Container>
        </div>
    );
}

export default function DashboardComponent() {
    return <DashboardContent />;
}