import {createSlice } from "@reduxjs/toolkit"
import formaPagoService from "../../services/forma.pago.service"
import {setearMensaje} from "./snackbar.slice";
import {errores} from "../../common/errores";
import {logout} from "./autenticacion.slice";
import clienteService from "../../services/cliente.service";

const initialState = {
    formasPagosList: [],
    formaPago: null,
}

export const formaPagoSlice = createSlice({
    name: "formasPagos",
    initialState,
    reducers: {
        setFormaPagosList: (state, action) => {
            state.formasPagosList = action.payload;
        },
        formasPagosError: (state) => {
            state.formasPagosList = [];
        },
        setFormaPago:(state, action) => {
          state.formaPago = action.payload;
        },
        formaPagoError:(state,action) => {
            state.formaPago = null;
        },
    }
});


const {setFormaPagosList, formasPagosError, setFormaPago, formaPagoError} = formaPagoSlice.actions;
export default formaPagoSlice.reducer;


export const addFormaPago = (formaPago) => (dispatch) => {
    return formaPagoService.addFormaPago(formaPago)
        .then(
        (data) => {
            return Promise.resolve(data);
        },)
        .catch(
            (error) => {
                const mensaje = {
                    message: error.message,
                    severity: "error",
                }
                dispatch(setearMensaje(mensaje));

                if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                    dispatch(logout());
                }
                return Promise.reject();
            }
        );
}

export const getAllFormasPagos = () => (dispatch) => {
    return formaPagoService.fetchAllFormasPagos()
        .then((data) => {
            dispatch(setFormaPagosList(data));
            return Promise.resolve();
        },).catch(
            (error) => {
                dispatch(formasPagosError())
                const mensaje = {
                    message: error.message,
                    severity: "error",
                }
                dispatch(setearMensaje(mensaje));
                if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                    dispatch(logout());
                }
                return Promise.reject();
            }
        );
};

export const getFormaPago = (id) => (dispatch) => {
    return formaPagoService.fetchFormaPago(id).then(
        (data) => {
            dispatch(setFormaPago(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            dispatch(formasPagosError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));
            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};

export const modificarFormaPago = (formaPago) => (dispatch) => {
    return formaPagoService.modifFormaPago(formaPago).then(
        (data) => {
            return Promise.resolve();
        },).catch(
        (error) => {
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};