import React, {useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import {useStateContext} from "../../../contexts/ContextProvider";
import PropTypes from "prop-types";
import TabPanel from "../../../components/tabpanel.component";
import PagoNuevoComponent from "./pago.nuevo.component";
import AdelantoNuevoComponent from "./adelanto.nuevo.component";
import Typography from "@mui/material/Typography";
import {useLocation} from "react-router-dom";

const PagoComponent = (props) => {

    const [disabledPago, setDisabledPago] = React.useState(false);
    const [disabledAdelanto, setDisabledAdelanto] = React.useState(false);
    const [tabShow, setTabShow] = React.useState(0);
    const location = useLocation();





    const {open, setOpen, title, setTitle} = useStateContext();
    useEffect(() => {
        setTitle("Nuevo Cliente");
        if (location.state) {
            if (location.state.tipoPago === "CUOTA") {
                setTabShow(0);
                setDisabledAdelanto(true)
            } else if (location.state.tipoPago === "ADELANTO") {
                setTabShow(1);
                setDisabledPago(true)
            }
        }
    });
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setTabShow(newValue);
    };
    const handleChangeIndex = (index) => {
        setTabShow(index);
    };

    //TODO: validaciones / carga de datos / chequeo de validaciones prvio a guardar.

    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>

            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',


                }}
                elevation={3}>
                <Box sx={{ width: '100%' }}>
                    <Typography
                    component="h5"
                    variant="h5"
                    color="inherit"
                    noWrap
                >
                    Realizar Pago
                </Typography>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabShow} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Pago de cuotas" {...a11yProps(0)} disabled={disabledPago}/>
                            {
                                /*
                                    <Tab label="Ingreso a cuenta corriente" {...a11yProps(1)} disabled={disabledAdelanto}/>
                                */
                            }
                        </Tabs>
                    </Box>
                    <TabPanel value={tabShow} index={0}>
                        <PagoNuevoComponent/>
                    </TabPanel>
                    {
                        /*
                            <TabPanel value={tabShow} index={1} >
                                <AdelantoNuevoComponent/>
                            </TabPanel>
                        */
                    }

                </Box>
            </Paper>

        </Container>
    );
};

export default PagoComponent;

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}