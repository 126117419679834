import {createSlice} from "@reduxjs/toolkit";
import {clearMessage, setearMensaje} from "./snackbar.slice"
import {errores} from "../../common/errores";
import {logout} from "./autenticacion.slice";
import comunicacionService from "../../services/comunicacion.service";

const initialState = {
    comunicacionList: [],
    comunicacionPrestamoList: []
}

export const comunicacionSlice = createSlice({
    name: "comunicaciones",
    initialState,
    reducers: {
        setComunicacionList: (state, action) => {
            state.comunicacionList = action.payload;
        },
        comunicacionListError: (state) =>{
            state.comunicacionList = [];
        },
        setComunicacionPrestamoList: (state, action) => {
            state.comunicacionPrestamoList = action.payload;
        },
        comunicacionPrestamoListError: (state) =>{
            state.comunicacionPrestamoList = [];
        },

    }
});
const {setComunicacionList, comunicacionListError, setComunicacionPrestamoList, comunicacionPrestamoListError} = comunicacionSlice.actions;
export default comunicacionSlice.reducer;

export const getAllComunicaciones = (fechaDesde, fechaHasta, tipoComunicacion, prestamoId, usuario) => (dispatch) => {
    return comunicacionService.fetchAllComunicaciones(fechaDesde, fechaHasta, tipoComunicacion, prestamoId, usuario).then(
        (data) => {
            dispatch(setComunicacionList(data));
            dispatch(clearMessage());
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("ERROR DE COMUNICACIONES");
            dispatch(comunicacionListError())
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));
            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllCOMUNICACIONES")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const getAllComunicacionesPrestamo = (tipoComunicacion, prestamoId, usuario) => (dispatch) => {
    return comunicacionService.fetchAllComunicaciones(null, null, tipoComunicacion, prestamoId, usuario).then(
        (data) => {
            dispatch(setComunicacionPrestamoList(data));
            dispatch(clearMessage());
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("ERROR DE COMUNICACIONES");
            dispatch(comunicacionPrestamoListError())
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));
            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllCOMUNICACIONES")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};

export const addComunicacion = (nuevaComunicacion) => (dispatch) => {
    return comunicacionService.addComunicacion(nuevaComunicacion).then(
        (data) => {
            return Promise.resolve()
        },).catch(
        (error) => {
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                dispatch(logout());
            }
            return Promise.reject();
        }
    )
}
