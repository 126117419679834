import {useNavigate} from "react-router-dom";
import {Fab} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, {useEffect, useState} from "react";
import AppWidgetSummary from "../../components/dashboard/widgetSummary";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {useDispatch, useSelector} from "react-redux";
import {consultaSaldos} from "../../store/slices/reporte.slice";
import LoadingComponent from "../../components/loading.component";

const ReportesSaldosTotalesComponent = () => {

    const { saldos } = useSelector(state => state.reportes);
    const [capitalTotalFinanciado, setCapitalTotalFinanciado] = useState(0)
    const [capitalTotalPrestado, setCapitalTotalPrestado] = useState(0)
    const [capitalTotalInteres, setCapitalTotalInteres] = useState(0)
    const [saldoTotalPendiente, setSaldoTotalPendiente] = useState(0)
    const [saldoTotalIntereses, setSaldoTotalIntereses] = useState(0)
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate();
    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(consultaSaldos())
            .then(()=>{
            })
            .catch(()=>{});
    }, []);

    useEffect(() =>{
        if(saldos) {
            setCapitalTotalFinanciado(saldos.capitalTotal);
            setCapitalTotalPrestado(saldos.capitalTotalPrestado)
            setCapitalTotalInteres(saldos.capitalTotalInteres)
            setSaldoTotalPendiente(saldos.saldoTotalPendiente);
            setSaldoTotalIntereses(saldos.saldoTotalPendienteInteres)
            setLoading(false)
        }
    },[saldos])



    const handleAtras = () => {
        return navigate(-1);
    }

    return (
        <Container maxWidth="lg" sx={{ mt:12, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} >
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: "100%",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}  sm={11} >
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                >
                                    <b>Reporte de Saldos</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}  sm={1} >
                                <Fab sx={{mb: 0, ml: 0}}
                                     title={"Volver atras"}
                                     size = "medium"
                                     color="primary"
                                     aria-label="add"
                                     onClick={handleAtras}
                                >
                                    <ArrowBackIcon/>
                                </Fab>
                            </Grid>
                            <Grid item xs={12} style={{paddingTop: '0px'}} >
                                <hr  paddingTop={5}/>{/*LINEA HORIZONTAL*/}
                            </Grid>
                        </Grid>

                        {loading ? (
                            <LoadingComponent/>
                        ) : (<>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <AppWidgetSummary title={"Total Financiado"}
                                                          subtitle={"(Capital total financiado activo)"}
                                                          color="info"
                                                          total={"USD " + (capitalTotalFinanciado ? capitalTotalFinanciado.toLocaleString("es-UY") : "0")}
                                                          icon={AttachMoneyIcon}/>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <AppWidgetSummary title={"Total Prestado"}
                                                          subtitle={"(Capital total prestado activo)"}
                                                          color="warning"
                                                          total={"USD " + (capitalTotalPrestado ? capitalTotalPrestado.toLocaleString("es-UY") : "0")}
                                                          icon={AttachMoneyIcon}/>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <AppWidgetSummary title={"Capital Total Interés"}
                                                          subtitle={"(Interés total del capital prestado)"}
                                                          color="success"
                                                          total={"USD " + (capitalTotalInteres ? capitalTotalInteres.toLocaleString("es-UY") : "0")}
                                                          icon={AttachMoneyIcon}/>
                                    </Grid>
                                    <Grid item xs={0} sm={2}/>
                                    <Grid item xs={12} sm={4}>
                                        <AppWidgetSummary title={"Saldo Total a Cobrar"}
                                                          subtitle={"(Saldo total pendiente a cobrar a la fecha)"}
                                                          color="info"
                                                          total={"USD " + (saldoTotalPendiente ? saldoTotalPendiente.toLocaleString("es-UY") : "0")}
                                                          icon={AttachMoneyIcon}/>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <AppWidgetSummary title={"Saldo de Interés a Cobrar"}
                                                          subtitle={"(Saldo total de interés a cobrar a la fecha)"}
                                                          color="success"
                                                          total={"USD " + (saldoTotalIntereses ? saldoTotalIntereses.toLocaleString("es-UY") : "0")}
                                                          icon={AttachMoneyIcon}/>
                                    </Grid>
                                    <Grid item xs={0} sm={2}/>
                                </Grid>
                            </>)
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
export default ReportesSaldosTotalesComponent