import {createSlice} from "@reduxjs/toolkit";
import prestamoService from "../../services/prestamo.service";
import {clearMessage, setMessage,setearMensaje} from "./snackbar.slice"
import {errores} from "../../common/errores";
import {logout} from "./autenticacion.slice";
import dashboardService from "../../services/dashboard.service";

const initialState = {
    prestamosActivos: 0,
    prestamosNuevos: 0,
    recaudado: 0,
    cantCuotasVencidas: 0,
    cuotasVencidasList: [],
    list: [],
    grafica: [],
}

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setPrestamosActivos: (state, action) => {
            state.prestamosActivos = action.payload;
        },
        prestamosActivosError: (state) =>{
            state.prestamosActivos = 0;
        },
        setPrestamosNuevos: (state, action) => {
            state.prestamosNuevos = action.payload;
        },
        prestamosNuevosError: (state) =>{
            state.prestamosNuevos = 0;
        },
        setRecaudado:(state, action) => {
            state.recaudado = action.payload;
        },recaudadoError: (state) =>{
            state.recaudado = 0;
        },
        setCantCuotasVencidas: (state, action) => {
            state.cantCuotasVencidas = action.payload;
        },
        cantCuotasVencidasError: (state) =>{
            state.cantCuotasVencidas = 0;
        },
        setCuotasVencidasList: (state, action) => {
            state.cuotasVencidasList = action.payload;
        },
        cuotasVencidasListError: (state) =>{
            state.cuotasVencidasList = [];
        },
        setGrafica: (state, action) => {
            state.grafica = action.payload;
        },
        graficaError: (state) =>{
            state.grafica = [];
        },
    }
});
const {setPrestamosActivos,prestamosActivosError,
    setPrestamosNuevos,prestamosNuevosError,
    setRecaudado, recaudadoError,
    setCantCuotasVencidas, cantCuotasVencidasError,
    setCuotasVencidasList, cuotasVencidasListError,
setGrafica, graficaError} = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const getAllDashboard = () => (dispatch) => {
    return dashboardService.fetchAllDashboard().then(
        (data) => {
            dispatch(setPrestamosActivos(data.prestamosActivos));
            dispatch(setPrestamosNuevos(data.prestamosNuevos));
            dispatch(setRecaudado(data.recaudadoTotal));
            dispatch(setCantCuotasVencidas(data.cuotasVencidas))
            dispatch(setCuotasVencidasList(data.cuotasVencidasProximas))
            dispatch(setGrafica(data.grafica))
            return Promise.resolve();
        },).catch(
        (error) => {
            //console.log("ERROR DE PRESTAMOS");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));
            dispatch(prestamosActivosError());
            dispatch(prestamosNuevosError());
            dispatch(recaudadoError());
            dispatch(cantCuotasVencidasError());
            dispatch(cuotasVencidasListError());
            dispatch(graficaError());
            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllDashboard")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};