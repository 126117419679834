import React, {useCallback, useEffect, useMemo} from 'react'
import {useNavigate} from "react-router-dom";
import GridComponent from "../../../../components/grid.component";
import {GridActionsCellItem, GridColDef, renderActionsCell} from "@mui/x-data-grid";
import PaymentIcon from '@mui/icons-material/Payment';
import {useDispatch, useSelector} from "react-redux";
import {getAllCuotasAnuales, getAllCuotasMensuales, getPrestamo} from "../../../../store/slices/prestamo.slice";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SelectComponent from "../../../../components/select.component";
import {Chip} from "@mui/material";
import {formatDDMMYYYY} from "../../../../common/fechaFormat";
import {EstadoPrestamo} from "../../../../common/enum/estadoPrestamo";
import {EstadoCuota} from "../../../../common/enum/estadoCuota";



const CuotasPrestamoComponent = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {idPrestamo, idCliente} = props;
    const {cuotasMensualesList} = useSelector(state => state.prestamos);

    const pagarCuota = useCallback(
        (idCuota) => () => {
            const params = {
                idCliente: idCliente,
                idPrestamo,
                tipoPago: "CUOTA",
                idCuota: idCuota,
                tipoCuota: "MENSUAL"
            };
            navigate("/pagos/nuevo", {state: params});
        }
    );
    useEffect(() => {

        dispatch(getAllCuotasMensuales(idPrestamo, props.estadoCuotaMensual.valor)).then(()=>{

        }).catch(()=>{

        });

    },[dispatch, props.estadoCuotaMensual]);


    const estadoCuota = (estado, vencimiento) => {
        const venci = new Date(vencimiento +"T00:00:00")
        const hoy = new Date()
        hoy.setHours(0,0,0,0)
        const dato = {
            value: estado,
            color: "secondary",
            variant: "outlined"
        }
        if (estado === "PENDIENTE" && hoy > venci) {
            dato.value = "Vencida"
            dato.color = "error"
            dato.variant = "outlined"

        }
        else if (estado === "PENDIENTE" && hoy.getTime() === venci.getTime()) {
            dato.value = "Vence hoy"
            dato.color = "warning"
            dato.variant = "outlined"

        }else if (estado === "PENDIENTE") {
            dato.value = "Pendiente"
            dato.color = "info"
            dato.variant = "outlined"

        } else if (estado === "CANCELADA") {
            dato.value = "Cancelada"
            dato.color = "success"
            dato.variant = "filled"
        }
        else if (estado === "PARCIAL") {
            dato.value = "Parcial"
            dato.color = "warning"
            dato.variant = "outlined"
        }
        return (
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
            />
        );
    };
    const vencimientoCuota = (vencimiento, estado) => {
        const hoy = new Date()
        hoy.setHours(0,0,0,0)
        const venci = new Date(vencimiento +"T00:00:00")
        const dato = {
            value: formatDDMMYYYY(venci),
            color: "info",
            variant: "outlined",
            disabled: false
        }
        if(estado === "CANCELADA"){
            dato.color = "success"
            dato.variant = "outlined"
            dato.disabled = true
        }
        else{
            if(hoy < venci){
                dato.color = "success"
                dato.variant = "outlined"
            }
            else if(hoy > venci){
                dato.color = "error"
                dato.variant = "filled"
            }
            else if(hoy.getTime() === venci.getTime()){
                dato.color = "warning"
                dato.variant = "outlined"
            }
        }

        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
            />)
    };
    const moneda = (moneda) => {
        const dato = {
            value: moneda,
            color: "secondary",
            variant: "outlined"
        }
        if (moneda === "USD") {
            dato.color = "info"
            dato.variant = "outlined"

        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
            />)
    };

    const actionPagarRender = (estado, idCuota) => {
        return (
            <GridActionsCellItem
                icon={<PaymentIcon />}
                label="Ver"
                onClick={pagarCuota(idCuota)}
                disabled={estado === "PENDIENTE" || estado === "PARCIAL" ? false: true}

            />
        )
    }

    const columns = [
        {
            field: 'actions',
            type: 'actions',
            width: 100,
            getActions: (param) => [
                actionPagarRender(param.row.estado, param.row.id)
            ],
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 20,
            editable: false,
            hide: true,
            hideable: false
        },
        {
            field: 'numeroCuota',
            headerName: 'Cuota Nº',
            width: 80,
            editable: false,
        },
        {
            field: 'vencimiento',
            headerName: 'Vencimiento',
            type: 'date',
            width: 120,
            editable: false,
            //valueGetter: ({ value }) => value && new Date(value+"T00:00:00"),
            renderCell: (param) => (
                vencimientoCuota(param.value, param.row.estado)
            ),
        },
        {
            field: 'simboloMoneda',
            headerName: 'Moneda',
            width: 90,
            editable: false,
            renderCell: (param) => (
                moneda(param.value)
            ),
        },
        {
            field: 'estado',
            headerName: 'Estado',
            width: 120,
            editable: false,
            renderCell: (param) => (
                estadoCuota(param.value, param.row.vencimiento)
            ),
        },
        {
            field: 'importe',
            headerName: 'Valor',
            type: 'number',
            width: 100,
            editable: false,
            valueGetter: (param) => param.value ? "$ "+param.value.toLocaleString("es-UY"): null
        },
        {
            field: 'importeCancelado',
            headerName: 'Cancelado',
            type: 'number',
            width: 100,
            editable: false,
            valueGetter: (param) => {
                if(parseInt(param.value) > 0)
                    return `$ ${parseInt(param.value).toLocaleString("es-UY")}`
            }
        },
        {
            field: 'saldoCuota',
            headerName: 'Saldo',
            type: 'number',
            width: 100,
            editable: false,
            valueGetter: (param) => {
                const saldo = parseInt(param.row.importe) - parseInt(param.row.importeCancelado)
                if(parseInt(param.row.importe) - parseInt(param.row.importeCancelado) > 0)
                    return `$ ${saldo.toLocaleString("es-UY")}`
            }
        },

    ];

    return (
        <>
            <Grid container spacing={2} paddingLeft={2}style={{paddingBottom:"1%"}}>

                <Grid item xs={12} md={3} >
                    <SelectComponent label="Estado"
                                     items={Object.entries(EstadoCuota).map(([key, value]) => ({id: key, descripcion:value}))}
                                     required={true}
                                     estado={props.estadoCuotaMensual}
                                     setEstado={props.setEstadoCuotaMensual}
                                     sx={{'& > :not(style)': {width: '100%'}}}/>
                </Grid>
            </Grid>
            <GridComponent rows={cuotasMensualesList} columns={columns} rowHeight={30} pageSize={12}/>
        </>
    );
}
export default CuotasPrestamoComponent
