import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import {Chip, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Box from "@mui/material/Box";
import TextboxComponent from "../../../components/textbox.component";
import Grid from "@mui/material/Grid";
import {formatDDMMYYYY, formatYYYYMMDD} from "../../../common/fechaFormat";
import {emitirRecibo, pagoNuevo} from "../../../store/slices/pago.slice";
import {setearMensaje} from "../../../store/slices/snackbar.slice";
import {downloadFile} from "../../../common/downloadFile";
import {EstadoCuota} from "../../../common/enum/estadoCuota";


const PopupPagoNuevo = (props) => {


    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');

    const {cuotasList,
            cuotasSeleccion,
            observacion,
            subTotal,
            descuento,
            totalAPagar,
            prestamo,
            cliente,
            idMoneda,
            fechaPago,
            dispatch,
            navigate,
            metodosPagoList,
            simboloMoneda,
            ingresoCc,
            entregaCliente} = props;


    const handleClose = () => {
        props.setOpen(false);
    };
    const handleConfirmar = () => {
        //const cuotas = cuotasSeleccion;
        const nuevoPago ={
            idPrestamo: prestamo.id,
            idCliente : cliente.id,
            descripcion: observacion,
            cuotas: cuotasSeleccion,
            idMoneda : idMoneda,
            importeDescuento : descuento,
            entregaCliente : entregaCliente,
            fechaPago: formatYYYYMMDD(fechaPago),
            metodosPago: metodosPagoList
        }
        //console.log("NUEVOPAGO")
        //console.log(nuevoPago)
        dispatch(pagoNuevo(nuevoPago)).then((pago) => {
            navigate("/Prestamos/" + prestamo.id);
            //console.log(pago.id)
            const mensaje = {
                message: "Pago realizado con éxito. Recibo #" + pago.id,
                severity: "success",
            }
            dispatch(setearMensaje(mensaje));
            /*dispatch(emitirRecibo(pago.id)).then((reporte) => {
                downloadFile(reporte.data, reporte.type, reporte.filename)
            }).catch(() => {})*/
        }).catch(() => {})
    };

    const vencimientoCuota = (vencimiento, estado) => {
        //console.log("estadoCUOTA: " + vencimiento+ " estado: "+estado)
        const hoy = new Date()
        hoy.setHours(0,0,0,0)
        const venci = new Date(vencimiento +"T00:00:00")
        const dato = {
            value: formatDDMMYYYY(venci),
            color: "info",
            variant: "outlined",
            disabled: false
        }
        if(estado === EstadoCuota.CANCELADA){
            dato.color = "success"
            dato.variant = "outlined"
            dato.disabled = true
        }
        else{
            if(hoy < venci){
                dato.color = "success"
                dato.variant = "outlined"
            }
            else if(hoy > venci){
                dato.color = "error"
                dato.variant = "filled"
            }
            else if(hoy.getTime() === venci.getTime()){
                dato.color = "warning"
                dato.variant = "outlined"
            }
        }

        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
            />)
    };
    const tipoPago = (importeCuota, importeCancelado, importeAPagar) => {
        const saldo = parseInt(importeCuota) - parseInt(importeCancelado);
        const dato = {
            value: "Verificar cuota",
            color: "error",
            variant: "outlined"
        }
        if(parseInt(importeAPagar) < saldo){
            dato.value = "PARCIAL"
            dato.color = "warning"
            dato.variant = "outlined"
        }
        if(parseInt(importeAPagar) === saldo){
            dato.value = "TOTAL"
            dato.color = "success"
            dato.variant = "outlined"
        }

        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
            />)
    };

    return (
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={props.open}
                onClose={handleClose}
            >
                <DialogTitle><b>Resumen del pago</b></DialogTitle>
                <DialogContent>
                    <Typography>
                        <b>Fecha Pago:</b> {formatDDMMYYYY(fechaPago)}
                    </Typography>
                    <Typography>
                        <b>Cliente:</b> {cliente.tipoDocumento + " - " + cliente.documento + " - " + cliente.nombreCompleto}
                    </Typography>
                    <Typography>
                        <b>Préstamo:</b> {"#" + (prestamo.numeroPrestamo ? prestamo.numeroPrestamo : prestamo.id) + "-" + prestamo.serie+ " - "+prestamo.detalle}
                    </Typography>
                    <Typography>
                        <b>Observación:</b> {observacion}
                    </Typography>
                    <DialogContentText>
                        <Box sx={{ margin: 1 }}>
                            {cuotasList !== [] &&
                                <Table size="small" aria-label="purchases" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Cuota Nº</b></TableCell>
                                            <TableCell><b>Detalle</b></TableCell>
                                            <TableCell><b>Vencimiento</b></TableCell>
                                            <TableCell><b>Tipo pago</b></TableCell>
                                            <TableCell align="right"><b>Saldo</b></TableCell>
                                            <TableCell align="right"><b>Importe</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cuotasList.map((cuota, i) => (
                                                <TableRow key={i}>
                                                    <TableCell component="th" scope="row">
                                                        {cuota.numeroCuota}
                                                    </TableCell>
                                                    <TableCell>{cuota.tipo}</TableCell>
                                                    <TableCell>{vencimientoCuota(cuota.vencimiento, cuota.estado)}</TableCell>
                                                    <TableCell>{tipoPago(cuota.importe, cuota.importeCancelado, cuotasSeleccion.find(({id}) => id === cuota.id)?.importe)}</TableCell>
                                                    <TableCell align="right"> {cuota.simboloMoneda}  {(parseInt(cuota.importe) - parseInt(cuota.importeCancelado)).toLocaleString("es-UY")}</TableCell>
                                                    <TableCell align="right">{cuota.simboloMoneda} {cuotasSeleccion.find(({id}) => id === cuota.id)?.importe.toLocaleString("es-UY")}</TableCell>
                                                </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell colSpan={4}/>
                                            <TableCell colSpan={1}><b>Subtotal</b></TableCell>
                                            <TableCell align="right"><b>{simboloMoneda} {subTotal.toLocaleString("es-UY")}</b></TableCell>
                                        </TableRow>
                                        { ingresoCc > 0 &&
                                            <TableRow>
                                                <TableCell colSpan={4}/>
                                                <TableCell colSpan={1}><b>Ingreso Cuenta Corriente</b></TableCell>
                                                <TableCell align="right"><b>{simboloMoneda} {ingresoCc.toLocaleString("es-UY")}</b></TableCell>
                                            </TableRow>
                                        }
                                        {parseInt(descuento) !== 0 &&
                                            <TableRow>
                                                <TableCell colSpan={4}/>
                                                <TableCell colSpan={1}><b>Descuento</b></TableCell>
                                                <TableCell align="right"><b>{simboloMoneda} - {descuento.toLocaleString("es-UY")}</b></TableCell>
                                            </TableRow>
                                        }
                                        {/*
                                            <TableRow>
                                            <TableCell colSpan={2}>Total a pagar</TableCell>
                                            <TableCell align="right">{totalAPagar.toLocaleString("es-UY")}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                            <TableCell colSpan={2}>Entrega del cliente</TableCell>
                                            <TableCell align="right">{entregaCliente.toLocaleString("es-UY")}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                            <TableCell colSpan={2}>Adelanto de cuota</TableCell>
                                            <TableCell align="right">{adelanto.toLocaleString("es-UY")}</TableCell>
                                            </TableRow>
                                        */}
                                    </TableBody>
                                </Table>
                            }
                        </Box>
                    </DialogContentText>
                    <Grid container spacing={3}>
                        {/*
                        <Grid item xs={12} lg={4} >
                            <TextboxComponent
                                titulo={{valor:"Cancelació por saldo", negrita: false}}
                                dato={{valor: cancelacion.toLocaleString("es-UY")}}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4} >
                            <TextboxComponent
                                titulo={{valor:"Descuento", negrita: false}}
                                dato={{valor: descuento.toLocaleString("es-UY")}}
                            />
                        </Grid>
                        */}
                        <Grid item xs={0} lg={6}/>
                        <Grid item xs={12} lg={6} >
                            <TextboxComponent
                                titulo={{valor:"Pago Total", negrita: true}}
                                dato={{valor: simboloMoneda + " " + entregaCliente.toLocaleString("es-UY"), negrita: true}}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleConfirmar}>Confirmar pago</Button>
                    <Button variant={"outlined"} onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>
    );
}
export default  PopupPagoNuevo