import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import AuthVerify from "../common/auth-verify";

const ProtectedRoute = ({ children }) => {
    const {user, token } = AuthVerify();

    const location = useLocation();
    console.log("Token: " + token)
    if (!user || !token) {
        console.log("if en false redirect login")
        return <Navigate to="/login" replace state={{ from: location }} />;
    }
    return children;
};


/*
const ProtectedRoute = ({ isAuth, component, ...rest}) => {
    return (
        <Route
            {...rest}
                render={ (props) => {
                    if (isAuth){
                        return <component />
                    } else {
                        return (
                            <Navigate to = {{ pathname: "/login", state: { from: props.location }}} />
                        );
                    }
                }
            }
        >
            
        </Route>
    );
}

 */



export default ProtectedRoute;