import Box from "@mui/material/Box";
import {Chip, Fab, Stack, Switch} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import Typography from "@mui/material/Typography";
import {getAllClientes, getAllClientesPrestamosActivos, getClientePrestamos} from "../../../store/slices/cliente.slice";
import {getAllMonedas} from "../../../store/slices/generico.slice";
import SelectComponent from "../../../components/select.component";
import {expresiones as er} from "../../../common/expresionesRegulares";
import ButtonComponent from "../../../components/button.component";
import Divider from "@mui/material/Divider";
import DatePickerComponent from "../../../components/date.picker.component";
import {formatDDMMYYYY} from "../../../common/fechaFormat";
import GridComponent from "../../../components/grid.component";
import {
    getAllCuotasAnuales,
    getAllCuotasMensuales,
    getAllPrestamosActivos
} from "../../../store/slices/prestamo.slice";
import PopupPagoNuevo from "../../../pages/pagos/popup/popup.pago.nuevo.component";
import {setearMensaje} from "../../../store/slices/snackbar.slice";
import InputNumericComponent from "../../../components/input.numeric.component";
import {getAllFormasPagos, getFormaPago} from "../../../store/slices/forma.pago.slice";
import AddIcon from "@mui/icons-material/Add";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import TextboxComponent from "../../../components/textbox.component";
import InputComponent from "../../../components/input.component";
import {EstadoCuota} from "../../../common/enum/estadoCuota";
import {TipoCuota} from "../../../common/enum/tipoCuota";
import {FormaPagoDefault} from "../../../common/enum/formaPagoDefault";
import LoadingComponent from "../../../components/loading.component";





const PagoNuevoComponent = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();
    const [openPopup, setOpenPopup] = React.useState(false);

    const { clientesList, prestamosClienteList } = useSelector(state => state.clientes)
    const { cuotasMensualesList, cuotasAnualesList, prestamosList } = useSelector(state => state.prestamos)
    const { monedasList } = useSelector(state => state.genericos)
    const { formasPagosList } = useSelector(state => state.formasPagos)
    const { formaPago } = useSelector(state => state.formasPagos)

    const [prestamos, setPrestamos] = useState([]);
    const [moneda, setMoneda] = useState({valor: "1", valido: true});
    const [descuento, setDescuento] = useState({valor: "0", valido: true});
    const [importeFormaDePago, setImporteFormaDePago] = useState({valor: "0", valido: true});
    const [fechaPago, setFechaPago] = React.useState({valor: new Date(), valido: true});
    const [seleccionMensuales, setSeleccionMensuales] = React.useState([]);
    const [seleccionAnuales, setSeleccionAnuales] = React.useState([]);
    const [cuotasSeleccion, setCuotasSeleccion] = React.useState([]);
    const [checked, setChecked] = React.useState(false);
    const [clienteSeleccionado, setClienteSeleccionado] = React.useState(location.state ? "": null);
    const [prestamoSeleccionado, setPrestamoSeleccionado] = React.useState(location.state ? "": null);
    const [autocomClienteDisable, setAutocomClienteDisable] = React.useState(location.state ? true: false);
    const [autocomPrestamoDisable, setAutocomPrestamoDisable] = React.useState(location.state ? true: false);
    const [formaPagoSel, setFormaPagoSel] = useState({valor:"", valido:true});
    const [referenciaPago1, setReferenciaPago1] = useState({valor:"", valido:true});
    const [referenciaPago2, setReferenciaPago2] = useState({valor:"", valido:true});
    const [pagoFormasPagosList, setPagoFormasPagosList] = useState([]);
    const [descuentoCheck, setDescuentoCheck] = useState(false);
    const [observacionPago, setObservacionPago] = useState({valor: "", valido: true});
    const [loading, setLoading] = useState(true)
    const [hayPrestamo, setHayPrestamo] = useState(false);


    const handleChangeSwitch = (event) => {
        setChecked(event.target.checked);
    };

    const handleDescuentoChangeSwitch = (event) => {
        setDescuentoCheck(event.target.checked);
        setDescuento({valor: "0", valido: true});
    };

    useEffect(() => {
        setLoading(true)

        dispatch(getAllClientesPrestamosActivos()).then(() => {
            }).catch(() => {
        });
        dispatch(getAllPrestamosActivos()).then(() => {
            }).catch(() => {
        });
        dispatch(getAllMonedas()).then(() => {
            }).catch(() => {
        });
        dispatch(getAllFormasPagos()).then(() => {
            }).catch(() => {
        });
        setLoading(false);
        //console.log("location", location.state);
        if (location.state) {
            setHayPrestamo(true);
        }
    }, []);

    useEffect(() => {
        if(!hayPrestamo) {
            if (clienteSeleccionado) {
                dispatch(getAllPrestamosActivos(clienteSeleccionado.id)).then(() => {}).catch(() => {})
            } else {
                setPrestamoSeleccionado(null);
                dispatch(getAllPrestamosActivos()).then(() => {}).catch(() => {});
            }
        }
    }, [clienteSeleccionado]);

    useEffect(() => {
        if(!hayPrestamo){
            if(!clienteSeleccionado && prestamoSeleccionado){
                setClienteSeleccionado(clientesList.find(elemento => elemento.id === prestamoSeleccionado.clienteId))
            }
        }
    }, [prestamoSeleccionado])

    useEffect(() => {
        setPrestamos(prestamosList);
    }, [prestamosList]);

    useEffect(() => {
        if(location.state && hayPrestamo) {
            setClienteSeleccionado(clientesList.find(elemento => elemento.id === parseInt(location.state.idCliente)));
            setPrestamoSeleccionado(prestamosList.find(elemento => elemento.id === parseInt(location.state.idPrestamo)))
            if(location.state.tipoCuota === TipoCuota.MENSUAL)
                setSeleccionMensuales([parseInt(location.state.idCuota)])
            else if(location.state.tipoCuota === TipoCuota.ANUAL)
                setSeleccionAnuales([parseInt(location.state.idCuota)])
        }
    }, [hayPrestamo, clientesList]) //Agregue clientesList 03/10/23 para solucionar primer carga

    useEffect(() => {
        setPagoFormasPagosList([])
        setSeleccionMensuales([])
        setSeleccionAnuales([])
        setDescuento({valor: "0", valido: true});
        setDescuentoCheck(false);

        if(prestamoSeleccionado) {

            const estado =[EstadoCuota.PENDIENTE, EstadoCuota.PARCIAL];
            dispatch(getAllCuotasMensuales(prestamoSeleccionado.id, estado.join(','))).then(() => {}).catch(() => {})
            dispatch(getAllCuotasAnuales(prestamoSeleccionado.id, estado.join(','))).then(() => {}).catch(() => {})
        }

        if(location.state) {
            if(location.state.tipoCuota === TipoCuota.MENSUAL)
                setSeleccionMensuales([parseInt(location.state.idCuota)])
            else if(location.state.tipoCuota === TipoCuota.ANUAL)
                setSeleccionAnuales([parseInt(location.state.idCuota)])
        }
    }, [prestamoSeleccionado]);

    const totalMensual = useMemo(() => {
        let importeSum = 0;
        cuotasSeleccion.map(({id, importe}) => {
            if(seleccionMensuales.includes(id))
                importeSum += isNaN(parseInt(importe)) ? 0 : parseInt(importe);
        });
        return importeSum;
    }, [seleccionMensuales, cuotasSeleccion]);

    const totalAnual = useMemo(() => {
        let importeSum = 0;
        cuotasSeleccion.map( ({id, importe}) =>{
            if(seleccionAnuales.includes(id))
                importeSum += isNaN(parseInt(importe)) ? 0 : parseInt(importe);
        });
        return importeSum;
    }, [seleccionAnuales, cuotasSeleccion]);

    const subtotal = useMemo(() => {
        return totalMensual + totalAnual;
    }, [totalMensual, totalAnual]);

    const importeAPagar = useMemo(() => {
        return subtotal - (descuento.valor === "" ? 0 : parseInt(descuento.valor));
    }, [subtotal, descuento]);

    const entregaCliente = useMemo(() => {
        let sumFp = 0;
        pagoFormasPagosList.forEach(formaPago => sumFp += formaPago.importe);
        return sumFp;
    }, [pagoFormasPagosList])

    useEffect(() => {
        if (formaPagoSel.valor !== "") {
            setReferenciaPago1({valor:"", valido:true});
            setReferenciaPago2({valor:"", valido:true});
            dispatch(getFormaPago(formaPagoSel.valor)).then(() => {}).catch(() => {});
        }
    }, [formaPagoSel])

    const cuotasSeleccionadas = () =>{
        const cuotasMen = cuotasMensualesList.filter(x =>seleccionMensuales.indexOf(x.id) !== -1);
        const cuotasAnu = cuotasAnualesList.filter(x =>seleccionAnuales.indexOf(x.id) !== -1);
        return cuotasMen.sort().concat(cuotasAnu.sort());
    }

    const handleCancelar = (e) => {
        e.preventDefault();
        return navigate(-1);
    }
    const handleAceptar = (e) => {
        var correcto = true;
        e.preventDefault();

        const mensaje = {
            message: "",
            severity: "error"
        }
        if(!prestamoSeleccionado){
            correcto = false;
            mensaje.message ="Debe seleccionar un préstamo";
            dispatch(setearMensaje(mensaje))
        }
        if(prestamoSeleccionado && seleccionMensuales.length + seleccionAnuales.length === 0) {
            correcto = false;
            mensaje.message ="Debe seleccionar cuotas a pagar";
            dispatch(setearMensaje(mensaje))
        }
        if(importeAPagar < 0){
            correcto = false;
            mensaje.message ="El importe a pagar no puede ser negativo";
            dispatch(setearMensaje(mensaje))
        }
        if(importeAPagar !== entregaCliente){
            correcto = false;
            mensaje.message ="La entrega del cliente tiene diferencias con el importe a pagar";
            dispatch(setearMensaje(mensaje))
        }
        seleccionMensuales.forEach(idCuota => {
            const cuota = cuotasSeleccion.find(({id}) => id === idCuota);
            if(cuota){
                if(cuota.importe <= 0){
                    correcto = false;
                    mensaje.message ="Hay cuotas mensuales seleccionadas sin importe ingresado";
                    dispatch(setearMensaje(mensaje))
                }
            }else{
                correcto = false;
                mensaje.message ="Hay cuotas mensuales seleccionadas sin importe ingresado";
                dispatch(setearMensaje(mensaje))
            }
        });
        seleccionAnuales.forEach(idCuota => {
            const cuota = cuotasSeleccion.find(({id}) => id === idCuota);
            if(cuota){
                if(cuota.importe <= 0){
                    correcto = false;
                    mensaje.message ="Hay cuotas anuales seleccionadas sin importe ingresado";
                    dispatch(setearMensaje(mensaje))
                }
            }else{
                correcto = false;
                mensaje.message ="Hay cuotas anuales seleccionadas sin importe ingresado";
                dispatch(setearMensaje(mensaje))
            }
        })

        var sumImporteFormaPago = 0;
        pagoFormasPagosList.forEach(formaPago => sumImporteFormaPago += formaPago.importe);

        if (correcto && sumImporteFormaPago !== importeAPagar ) {
            correcto = false;
            mensaje.message ="La entrega del cliente tiene diferencias con el importe a pagar";
            dispatch(setearMensaje(mensaje))
        }
        if(correcto)
            setOpenPopup(true);
    }

    const vencimientoCuota = (vencimiento, estado) => {
        const hoy = new Date()
        hoy.setHours(0,0,0,0)
        const venci = new Date(vencimiento +"T00:00:00")
        const dato = {
            value: formatDDMMYYYY(venci),
            color: "info",
            variant: "outlined",
            disabled: false
        }
        if(estado === EstadoCuota.CANCELADA){
            dato.color = "success"
            dato.variant = "outlined"
            dato.disabled = true
        }
        else{
            if(hoy < venci){
                dato.color = "success"
                dato.variant = "outlined"
            }
            else if(hoy > venci){
                dato.color = "error"
                dato.variant = "filled"
            }
            else if(hoy.getTime() === venci.getTime()){
                dato.color = "warning"
                dato.variant = "outlined"
            }
        }
        return(
            <Chip
                label = {dato.value}
                color = {dato.color}
                variant = {dato.variant}
                size = "small"
                disabled={dato.disabled}
                sx={{fontSize: 10}}
            />)
    };
    const referenciasMetodosPago = (nomRef1, numRef1, nomRef2, numRef2) => {

        return(
            <Box>
                {nomRef1 &&
                    <Typography sx={{ fontSize: 10 }} >
                        {nomRef1+": "+numRef1}
                    </Typography>
                }
                {nomRef2 &&
                    <Typography sx={{ fontSize: 10 }} >
                        {nomRef2+": "+numRef2}
                    </Typography>
                }
            </Box>
            )
    };

    const handleAddFormaPago = () => {
        if ((importeFormaDePago.valido && parseInt(importeFormaDePago.valor) > 0) &&
         referenciaPago1.valido && referenciaPago2.valido)
        {
                const auxFormasPagosList = [...pagoFormasPagosList];
                let ids = pagoFormasPagosList.length;
                if (formaPago.id === 1 && auxFormasPagosList.find(e => e.formaPagoId === 1)) {
                    var index = auxFormasPagosList.findIndex(e => e.formaPagoId === 1)
                    auxFormasPagosList[index].importe += parseInt(importeFormaDePago.valor)
                } else if (formaPago.id === 2 && auxFormasPagosList.find(e => e.formaPagoId === 2)) {
                    var index = auxFormasPagosList.findIndex(e => e.formaPagoId === 2)

                } else {
                    auxFormasPagosList.push({
                        id: ids + 1,
                        formaPagoId: formaPago.id,
                        nombre: formaPago.nombre,
                        nombreReferencia1: formaPago.nombreReferencia1 ? formaPago.nombreReferencia1 : null,
                        nombreReferencia2: formaPago.nombreReferencia2 ? formaPago.nombreReferencia2 : null,
                        numeroReferencia1: (formaPago != null && formaPago.nombreReferencia1 && referenciaPago1 ? referenciaPago1.valor : null),
                        numeroReferencia2: (formaPago != null && formaPago.nombreReferencia2 && referenciaPago2 ? referenciaPago2.valor : null),
                        importe: importeFormaDePago && parseInt(importeFormaDePago.valor)
                    });
                }
                setPagoFormasPagosList(auxFormasPagosList);
                setImporteFormaDePago({valor: "0", valido: true})
                setReferenciaPago1({valor: "", valido: true});
                setReferenciaPago2({valor: "", valido: true});

        }
    }
    const deleteFormaPago = useCallback(
        (id) => () => {
            const auxFormasPagosList = [...pagoFormasPagosList];
            setPagoFormasPagosList(pagoFormasPagosList.filter( (param) => param.id !== id ));
        }
    );

    const updateCuotasSeleccion = (cuotaId, importe, nCuota, impMax) => {
        const cuotas = cuotasSeleccion.filter(({id}) => id !== cuotaId);
        if(importe > impMax){
            const mensaje = {
                message: `El importe ingresado en la cuota ${nCuota} supera el saldo`,
                severity: "warning"
            }
            dispatch(setearMensaje(mensaje))
        }
        setCuotasSeleccion([...cuotas, {id: cuotaId, importe: importe < 0 ? "" : importe}]);

    }

    const columns = [
        {
            field: 'numeroCuota',
            headerName: 'Cuota',
            width: 60,
            editable: false,
            sortable: false,
        },
        {
            field: 'vencimiento',
            headerName: 'Vencimiento',
            type: 'date',
            width: 100,
            editable: false,
            sortable: false,
            //valueGetter: ({ value }) => value && new Date(value+"T00:00:00"),
            renderCell: (param) => (
                vencimientoCuota(param.value, param.row.estado)
            ),
        },
        {
            field: 'importe',
            headerName: 'Valor',
            type: "number",
            width: 95,
            editable: false,
            sortable: false,
            valueGetter: (param) => param.row.simboloMoneda+ " "+ param.value.toLocaleString("es-UY")
        },
        {
            field: 'importeCancelado',
            headerName: 'Saldo',
            type:"number",
            width: 90,
            editable: false,
            sortable: false,
            valueGetter: (param) => param.row.simboloMoneda+ " "+ (parseInt(param.row.importe) - parseInt(param.value)).toLocaleString("es-UY")
        },
        {
            field: 'importeAPagar',
            headerName: '',
            width: 100,
            editable: false,
            sortable: false,
            cellClassName:param => {},
            renderCell: ({row}) => {
                if(seleccionMensuales.includes(row.id) || seleccionAnuales.includes(row.id)){
                    const value = cuotasSeleccion.find(({id}) => id === row.id)?.importe;
                    const impMaximo = row.importe - row.importeCancelado;
                    return <TextField
                                size={"small"}
                                value={value}
                                type={"number"}
                                InputProps={{ inputProps: { min: 0 } }}
                                sx={{m: 0, p:0, background: '#E9F6D8'}}
                                fullWidth
                                onChange={(event) => {
                                    updateCuotasSeleccion(row.id, event.target.value, row.numeroCuota, impMaximo);
                        }}
                    />
                }
            }
        },
    ];

    const formaPagoColumns = [
        {
            field: 'nombre',
            headerName: 'Forma de pago',
            width: 200,
            editable: false,
        },
        {
            headerName: 'Descripción',
            width: 250,
            editable: false,
            renderCell: (param) => (
                referenciasMetodosPago(
                    param.row.nombreReferencia1,
                    param.row.numeroReferencia1,
                    param.row.nombreReferencia2,
                    param.row.numeroReferencia2)
            ),
        },
        {
            field: 'importe',
            headerName: 'Importe',
            width: 90,
            editable: false,
            valueGetter: (param) => "$"+ param.value.toLocaleString("es-UY")
        },
        {
            field: 'actions',
            type: 'actions',
            width: 40,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Borrar"
                    onClick={deleteFormaPago(params.id)}
                />,
            ],
        }
    ]

    return (

            <Box component="form"
                 sx={{
                     '& > :not(style)': {m: 1, width: '100%'},
                 }}
                 noValidate
                 autoComplete="off">
                {loading ? (
                    <LoadingComponent/>
                ) : (<>
                <Box sx={{flexGrow: 1}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}  lg={8} >

                        </Grid>
                        <Grid item xs={12}  lg={8} >

                            <Autocomplete
                                id="combo-box-demo"
                                onChange={(event, value) => setClienteSeleccionado(value)}
                                options={clientesList}
                                loading={true}
                                getOptionLabel={(option) => option.tipoDocumento + " - " + option.documento + " - " + option.nombreCompleto}
                                style={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Cliente"  />}
                                disabled={autocomClienteDisable}
                                value={clienteSeleccionado}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3} >
                            <DatePickerComponent
                                label="Fecha de pago"
                                formato="dd/MM/yyyy"
                                estado={fechaPago}
                                setEstado={setFechaPago}
                                disabled={!checked}
                            />
                         </Grid>
                        <Grid item xs={12} lg={1} >
                             <Switch
                                checked={checked}
                                sx={{marginTop: "20%"}}
                                onChange={handleChangeSwitch}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={12}  lg={8} >
                            <Autocomplete
                                id="combo-box-demo"
                                onChange={(event, value) => setPrestamoSeleccionado(value)}
                                options={prestamos}
                                loading={true}
                                getOptionLabel={(option) => "#" + (option.numeroPrestamo ? option.numeroPrestamo : option.id) + "-" + option.serie + " - "+option.detalle}
                                style={{ width: "100%" }}
                                renderInput={(params) => prestamos.length === 0 ? <TextField {...params} label="No tiene préstamos activos"  /> : <TextField {...params} label="Préstamo"  />}
                                disabled={autocomPrestamoDisable}
                                value={prestamoSeleccionado}
                            />
                        </Grid>


                    </Grid>

                    <Divider  sx={{ mt:'3ch', mb:'3ch'}}/>
                    {prestamoSeleccionado &&
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Typography
                                    component="h6"
                                    variant="h6"
                                    noWrap
                                    marginLeft="5%"
                                    //align={"center"}
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    Cuotas Mensuales
                                </Typography>
                                <GridComponent rows={cuotasMensualesList}
                                               columns={columns}
                                               rowHeight={30}
                                               pageSize={24}
                                               disableToolbar={true}
                                               height={350}
                                               checkboxSelection={true}
                                               seleccion={seleccionMensuales}
                                               setSeleccion={setSeleccionMensuales}
                                               disableColumnMenu={true}
                                />
                                <TextboxComponent
                                    width="100%"
                                    height="12%"
                                    marginTop="5%"
                                    titulo={{valor: "Total Cuotas Mensuales", negrita: true}}
                                    dato={{valor: "$"+totalMensual.toLocaleString("es-UY") , negrita:true}}
                                />

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography
                                    component="h6"
                                    variant="h6"
                                    noWrap
                                    marginLeft="5%"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    Cuotas Anuales
                                </Typography>
                                <GridComponent rows={cuotasAnualesList}
                                               columns={columns}
                                               rowHeight={30}
                                               pageSize={24}
                                               disableToolbar={true}
                                               height={350}
                                               checkboxSelection={true}
                                               seleccion={seleccionAnuales}
                                               setSeleccion={setSeleccionAnuales}
                                               disableColumnMenu={true}
                                />
                                <TextboxComponent
                                    width="100%"
                                    height="12%"
                                    marginTop="5%"
                                    label="Total Cuotas Anuales"
                                    titulo={{valor: "Total Cuotas Anuales", negrita: true}}
                                    dato={{valor: "$"+totalAnual.toLocaleString("es-UY") , negrita:true}}
                                />
                            </Grid>

                        </Grid>
                    }
                    <Divider  sx={{ mt:'3ch', mb:'0ch'}}/>
                    <Grid container spacing={3} sx={{ mt:'0ch', mb:'3ch'}} >
                        <Grid item xs={12} md={4}>
                            <TextboxComponent
                                width="100%"
                                titulo={{valor: "Subtotal", negrita: true}}
                                dato={{valor: "$"+subtotal.toLocaleString("es-UY") , negrita:true}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <InputNumericComponent sx={{width: "100%"}}
                                                   label="Importe descuento"
                                                   type="outlined"
                                                   required={descuentoCheck}
                                                   formato={"es-UY"}
                                                   estado={descuento}
                                                   setEstado={setDescuento}
                                                   disabled={!descuentoCheck}
                                                   leyendaError= "El importe solo puede tener números"
                                                   expresionRegular={er.importePrestado}
                            />
                        </Grid>
                        <Grid item xs={12} md={2} >
                            <Switch
                                checked={descuentoCheck}
                                sx={{marginTop: "5%"}}
                                onChange={handleDescuentoChangeSwitch}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                    </Grid>
                    <Divider  sx={{ mt:'3ch', mb:'3ch'}}/>

                    <Grid container spacing={4} >
                        <Grid item xs={12} md={3}>
                            <TextboxComponent
                                width="100%"
                                titulo={{valor: "Importe a Pagar", negrita: true}}
                                dato={{valor: "$"+importeAPagar.toLocaleString("es-UY") , negrita:true}}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextboxComponent
                                width="100%"
                                titulo={{valor: "Entrega del cliente", negrita: true}}
                                dato={{valor: "$"+entregaCliente.toLocaleString("es-UY") , negrita:true}}
                            />

                        </Grid>


                        <Grid item xs={12} md={2} >
                            <SelectComponent label="Moneda"
                                             items={monedasList}
                                             required={true}
                                             disabled={true}
                                             estado={moneda}
                                             setEstado={setMoneda}
                                             sx={{'& > :not(style)': {width: '20ch'}}}/>
                        </Grid>
                    </Grid>

                    <Divider  sx={{ mt:'3ch', mb:'1ch'}}/>
                    {importeAPagar > 0 &&
                        <Grid container spacing={3} >
                            <Grid item xs={12} md={12}>
                            <Typography
                                component="h6"
                                variant="h6"
                                noWrap
                                marginLeft="0%"
                                marginBottom="1ch"
                                sx={{fontWeight: 'bold'}}
                                //align={"center"}
                            >
                                Metodos de Pago
                            </Typography>
                            </Grid>

                        <Grid item xs={12} md={4}>
                        <SelectComponent label="Forma de Pago"
                            items={formasPagosList.map(({id, nombre}) => ({id: id, descripcion:nombre}))}
                            required={true}
                            disabled={false}
                            estado={formaPagoSel}
                            setEstado={setFormaPagoSel}
                            sx={{'& > :not(style)': {width: '100%'}}}/>
                        </Grid>

                    {formaPago != null && formaPago.nombreReferencia1 &&
                        <Grid item xs={12} md={2.5}>
                        <InputComponent sx={{width: "100%"}}
                        label={formaPago.nombreReferencia1}
                        type="outlined"
                        required={true}
                        estado={referenciaPago1}
                        setEstado={setReferenciaPago1}
                        leyendaError="El campo no puede ser vacío"
                        expresionRegular={er.texto255}
                        />
                        </Grid>
                    }
                    {formaPago != null && formaPago.nombreReferencia2 &&
                        <Grid item xs={12} md={2.5}>
                        <InputComponent sx={{width: "100%"}}
                        label={formaPago.nombreReferencia2}
                        type="outlined"
                        required={true}
                        estado={referenciaPago2}
                        setEstado={setReferenciaPago2}
                        formato={"es-UY"}
                        leyendaError= "El campo no puede ser vacío"
                        expresionRegular={er.texto255}
                        />
                        </Grid>
                    }
                        <Grid item xs={12} md={2} >
                        <InputNumericComponent sx={{width: "100%"}}
                        label="Importe"
                        type="outlined"
                        required={true}
                        estado={importeFormaDePago}
                        setEstado={setImporteFormaDePago}
                        formato={"es-UY"}
                        leyendaError= "El importe solo puede tener numeros"
                        expresionRegular={er.numericoMayorCero}
                        />
                        </Grid>

                        <Grid item xs={12}  md={1} >
                            <Fab sx={{mb: 2, ml: 0}}
                            size = "medium"
                            color="primary"
                            aria-label="add"
                            //disabled={parseInt(saldoFormaPago.valor) === 0 ? true : false}
                            onClick={handleAddFormaPago}
                            >
                                <AddIcon   />
                            </Fab>
                        </Grid>

                    {pagoFormasPagosList &&
                        <Grid item xs={12} md={10}>

                            <GridComponent rows={pagoFormasPagosList}
                                columns={formaPagoColumns}
                                rowHeight={30}
                                pageSize={4}
                                disableToolbar={true}
                                height={231}
                                checkboxSelection={false}
                            />
                        </Grid>
                    }

                        </Grid>
                    }
                    <InputComponent sx={{width: "100%", marginTop: 3}}
                                    label="Observaciones"
                                    type="outlined"
                                    required={false}
                                    estado={observacionPago}
                                    setEstado={setObservacionPago}
                                    leyendaError= "Supero el máximo de caracteres posible"
                                    expresionRegular={er.texto255Vacio}
                                    multiline={true}
                                    maxRows={3}
                                    tipo={"text"}
                                    inputProps={{maxLength:"255"}}
                    />
                </Box>
                <Stack spacing={2} direction="row" paddingTop={2}>
                    <ButtonComponent label="Aceptar" type="contained" onClick={handleAceptar}/>
                    <ButtonComponent label="Cancelar" type="outlined" onClick={handleCancelar}/>
                </Stack>
                {prestamoSeleccionado && clienteSeleccionado &&
                    <PopupPagoNuevo open={openPopup}
                                    setOpen={setOpenPopup}
                                    cuotasList={cuotasSeleccionadas()}
                                    cuotasSeleccion={cuotasSeleccion}
                                    subTotal={subtotal}
                                    descuento={descuento.valor}
                                    observacion={observacionPago.valor}
                                    totalAPagar={importeAPagar.valor}
                                    dispatch={dispatch}
                                    prestamo={prestamoSeleccionado}
                                    cliente={clienteSeleccionado}
                                    idMoneda={moneda.valor}
                                    fechaPago={fechaPago.valor}
                                    navigate={navigate}
                                    metodosPagoList={pagoFormasPagosList}
                                    simboloMoneda={monedasList.find(x => x.id === parseInt(moneda.valor)).descripcion}
                                    entregaCliente={entregaCliente}
                    />
                }
                </>)
                }
            </Box>


    )
};

export default PagoNuevoComponent;