import React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {styled, useTheme} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {useStateContext} from "../../contexts/ContextProvider";
import SearchComponent from "./headerContent/search.component";
import HeaderMenuComponent from "./headerContent/header.menu.component";
import Typography from "@mui/material/Typography";
import {entornos} from "../../config";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const menuId = () => {}
const handleProfileMenuOpen = () => {}
const handleMobileMenuOpen = () => {}
const mobileMenuId = () => {}


const HeaderComponent = () => {
    const {open, setOpen, title} = useStateContext();
    const env = process.env.REACT_APP_ENTORNO;
    const entorno = entornos[env].nombre !== "Produccion" ? entornos[env].nombre : null;

    const toggleDrawer = () => {
        setOpen(!open);
    };
    const theme = useTheme();
    return (
            <AppBar position="absolute" open={open}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <SearchComponent />
                {entorno && <Typography
                    mr={0}
                    ml={1}
                    variant={"h4"}
                    color={theme.palette.secondary.main}>
                    <b>{entorno.toUpperCase()}</b>
                </Typography>
                }

                    <HeaderMenuComponent />

            </Toolbar>

        </AppBar>
    )
}

export default HeaderComponent