import {createSlice} from "@reduxjs/toolkit";
import prestamoService from "../../services/prestamo.service";
import {clearMessage, setMessage,setearMensaje} from "./snackbar.slice"
import {errores} from "../../common/errores";
import {logout} from "./autenticacion.slice";

const initialState = {
    prestamosList: [],
    prestamo: null,
    cuotasMensualesList: [],
    cuotasAnualesList: [],
    movimientosList: [],
    pagosList: []
}

export const prestamoSlice = createSlice({
    name: "prestamos",
    initialState,
    reducers: {
        setPrestamosList: (state, action) => {
            //console.log("accion setPrestamosList")
            //console.log ("setPrestamosList - State: "+state.prestamosList)
            //console.log("setPrestamosList - payload: "+action.payload)
            state.prestamosList = action.payload;
        },
        prestamosError: (state) =>{
            state.prestamosList = [];
        },
        setPrestamo:(state, action) => {
            state.prestamo = action.payload;
        },prestamoError: (state) =>{
            state.prestamo = null;
        },
        setCuotasMensualesList: (state, action) => {
            state.cuotasMensualesList = action.payload;
        },
        cuotasMensualesError: (state) =>{
            state.cuotasMensualesList = [];
        },
        setCuotasAnualesList: (state, action) => {
            state.cuotasAnualesList = action.payload;
        },
        cuotasAnualesError: (state) =>{
            state.cuotasAnualesList = [];
        },
        setMovimientosList: (state, action) => {
            state.movimientosList = action.payload;
        },
        movimientosError: (state) =>{
            state.movimientosList = [];
        },
        setPagosList: (state, action) => {
            state.pagosList = action.payload;
        },
        pagosError: (state) =>{
            state.pagosList = [];
        },
    }
});
const {setPrestamosList, prestamosError, setPrestamo, prestamoError,
    setCuotasMensualesList, cuotasMensualesError, setCuotasAnualesList, cuotasAnualesError,
    setMovimientosList, movimientosError, setPagosList, pagosError} = prestamoSlice.actions;
export default prestamoSlice.reducer;

export const getAllPrestamos = (prestamoId, numeroPrestamo, serie, fechaDesde, fechaHasta, estadoPrestamo) => (dispatch) => {
    return prestamoService.fetchAllPrestamos(prestamoId, numeroPrestamo, serie, fechaDesde, fechaHasta, estadoPrestamo).then(
        (data) => {
            dispatch(setPrestamosList(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            //console.log("ERROR DE PRESTAMOS");
            dispatch(prestamosError())
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const getPrestamo = (id) => (dispatch) => {
    return prestamoService.fetchPrestamo(id).then(
        (data) => {

            dispatch(setPrestamo(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            //console.log("ERROR DE CLIENTESSSS");
            dispatch(prestamoError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const getAllCuotasMensuales = (idPrestamo, estado) => (dispatch) => {
    return prestamoService.fetchAllCuotasMensuales(idPrestamo, estado).then(
        (data) => {
            dispatch(setCuotasMensualesList(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            //console.log("ERROR DE Cuotas Mensuales");
            dispatch(cuotasMensualesError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllCuotasMensaules")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const getAllCuotasAnuales = (idPrestamo, estado) => (dispatch) => {
    return prestamoService.fetchAllCuotasAnuales(idPrestamo, estado).then(
        (data) => {
            dispatch(setCuotasAnualesList(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            //console.log("ERROR DE Cuotas Anuales");
            dispatch(cuotasAnualesError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllCuotasAnuales")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const getAllMovimientosPrestamo = (idPrestamo, tipoMov) => (dispatch) => {
    return prestamoService.fetchAllMovimientosPrestamo(idPrestamo, tipoMov).then(
        (data) => {
            dispatch(setMovimientosList(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            //console.log("ERROR DE Cuotas Anuales");
            dispatch(movimientosError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllCuotasAnuales")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};

export const addPrestamoAutomotor = (prestamo) => (dispatch) => {
    return prestamoService.addPrestamoAutomor(prestamo).then(
        (data) => {
            return Promise.resolve(data);
        },).catch(
        (error) => {
            //console.log("error AL INSERTAR NUEVO PRESTAMO");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};

export const refinanciarPrestamoAutomotor = (prestamo) => (dispatch) => {
    return prestamoService.refinanciarPrestamoAutomor(prestamo).then(
        (data) => {
            return Promise.resolve(data);
        },).catch(
        (error) => {
            //console.log("error AL INSERTAR NUEVO PRESTAMO");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const updatePrestamoAutomotor = (prestamo) => (dispatch) => {
    return prestamoService.updatePrestamoAutomotor(prestamo).then(
        (data) => {
            dispatch(setPrestamo(data))
            return Promise.resolve();
        },).catch(
        (error) => {
            //console.log("error AL INSERTAR NUEVO PRESTAMO");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const deletePrestamo = (idPrestamo) => (dispatch) => {
    //console.log("deleteeee");
    return prestamoService.deletePrestamo(idPrestamo).then(
        (data) => {
            return Promise.resolve();
        },).catch(
        (error) => {
            //console.log("error AL INSERTAR NUEVO PRESTAMO");
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                //console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};

export const getAllPagosPrestamo = (idPrestamo) => (dispatch) => {
    return prestamoService.fetchAllPagosPrestamo(idPrestamo).then(
        (data) => {
            dispatch(setPagosList(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("ERROR DE Cuotas Anuales");
            dispatch(pagosError());
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllCuotasAnuales")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};

export const cambioEstadoPrestamo = (nuevoEstado) => (dispatch) => {
    return prestamoService.cambioEstadoPrestamo(nuevoEstado).then(
        (data) => {
            return Promise.resolve();
        },).catch(
        (error) => {
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
}
export const getAllPrestamosActivos = (idCliente) => (dispatch) => {
    return prestamoService.fetchAllPrestamosActivos(idCliente).then(
        (data) => {
            dispatch(setPrestamosList(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("ERROR DE PRESTAMOS");
            dispatch(prestamosError())
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
export const getAllPrestamosActivosFull = (idCliente) => (dispatch) => {
    return prestamoService.fetchAllPrestamosActivosFull(idCliente).then(
        (data) => {
            dispatch(setPrestamosList(data));
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("ERROR DE PRESTAMOS");
            dispatch(prestamosError())
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchAllClientes")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};