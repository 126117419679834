export const EstadoPrestamo = Object.freeze({
    AL_DIA:"Al DIA",
    DEUDOR_CONSENSUADO: "DEUDOR CONSENSUADO",
    DEUDOR_GRAVE: "DEUDOR GRAVE",
    EN_JUICIO: "EN JUICIO",
    CANCELADO:"CANCELADO",
    FINALIZADO: "FINALIZADO",
    REFINANCIADO: "REFINANCIADO"
})

export function EstadoPrestamoEquals (estadoPrestamoValue, key) {
    const keyEstadoPrestamo = Object.entries(EstadoPrestamo).filter(([key, value]) => value === estadoPrestamoValue)[0]
    //console.log("funcion")
    //console.log(keyEstadoPrestamo[0] + "KEY: "+ key)
    if(keyEstadoPrestamo === key)
        return true;
    else
        return false;
}