export const entornos =
    {
        desarrollo: {
            nombre: "Desarrollo",
            color: "#d37c5b",
            api: "",
            port: "",
        },
        testing: {
            nombre: "Testing",
            color: "#318562" ,
            api: "",
            port: "",
        },
        produccion: {
            nombre: "Produccion",
            color: "#1976d2",
            api: "",
            port: "",
        },
}