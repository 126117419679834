export const downloadFile = (data, type, name) =>{

    const body = new Uint8Array(data)
    const blob = new Blob([data], {
        type: type,
    });
    const a = document.createElement('a');
    //a.window.URL.createObjectURL(blob);
    //console.log("BLOB")
    //console.log(blob)
    const url = window.URL.createObjectURL(blob)
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    //document.body.removeChild(a);

}