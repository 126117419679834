export const errores = {
    BAD_REQUEST: "Solicitud invalida",
    UNAUTHORIZED: "No esta autenticado",
    FORBIDDEN: "No tiene permisos para la solicitud",
    NOT_ACCEPTABLE:"Error en el contenido de la solicitud",
    ERROR_RED: "Error de conexion",
    ERROR_SERVIDOR: "Error en el servidor",
    SESION_EXPIRADA: "La sesion ha expirado",
    METHOD_NOT_ALLOWED: "Error en metodo de solicitud"
};
