
import * as React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {Chip} from "@mui/material";
import {expresiones as er} from "../common/expresionesRegulares";
import InputComponent from "./input.component";


function TextboxComponent(props) {

    const {titulo, dato, etiqueta, height, width, marginTop} = props;

    return (
        <Paper
            sx={{
                height: height ? height : "100%",
                width: width ? width: "100%",
                marginTop: marginTop ? marginTop : "0%"
            }}
            elevation={4}>
            <Typography
                color="inherit"
                noWrap
                align={titulo.align? titulo.align: "center"}
                sx={titulo.negrita ?{fontWeight: 'bold' }:{}}>
                {titulo.valor}
            </Typography>
            {dato &&
                <Typography
                    color="#9C9897"
                    component="h6"
                    variant= {dato.variant ? dato.variant : "h6"}
                    noWrap={ dato.wrap ?  !dato.wrap : true}
                    paddingTop={dato.pt? dato.pt: ""}
                    paddingBottom={dato.pb? dato.pb: ""}
                    marginLeft="2%"
                    align={dato.align? dato.align: "center"}
                    sx={dato.negrita ?{fontWeight: 'bold' }:{}}>
                    {dato.valor}
                </Typography>
            }
            {etiqueta &&
                <Typography
                    align={"center"}
                >
                    <Chip
                        label = {etiqueta.label}
                        color = {etiqueta.color}
                        variant = {etiqueta.variant}
                        size ={etiqueta.size}
                        sx={etiqueta.negrita ?{fontWeight: 'bold', paddingRight:"10px", paddingLeft:"10px", fontSize:etiqueta.fontSize?etiqueta.fontSize:20 }:{paddingRight:"10px", paddingLeft:"10px", fontSize:etiqueta.fontSize?etiqueta.fontSize:20 }}
                    />
                </Typography>
            }
        </Paper>
    );
}
export default TextboxComponent;
