import {useNavigate} from "react-router-dom";
import Link from '@mui/material/Link';
import {Checkbox, Chip, Fab} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import React from "react";
import {Favorite, FavoriteBorder} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Box from '@mui/material/Box';

const ReportesComponent = () => {

    const navigate = useNavigate();
    const handleAtras = () => {
        return navigate(-1);
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: "100%",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}  sm={11} >
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                >
                                    <b>Reportes</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}  sm={1} >
                                <Fab sx={{mb: 0, ml: 0}}
                                     title={"Volver atras"}
                                     size = "medium"
                                     color="primary"
                                     aria-label="add"
                                     onClick={handleAtras}
                                >
                                    <ArrowBackIcon/>
                                </Fab>
                            </Grid>
                            <Grid item xs={12} style={{paddingTop: '0px'}} >
                                <hr  paddingTop={5}/>{/*LINEA HORIZONTAL*/}
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <Paper
                                    >

                                    <Typography
                                        color="inherit"
                                        noWrap
                                        align={"center"}
                                        sx={{fontWeight: 'bold' }}>
                                        Reporte 1
                                    </Typography>
                                    <Typography
                                        color="inherit"
                                        variant="body2"
                                        align={"center"}
                                    >
                                        Descripcion
                                    </Typography>
                                    <Typography
                                        align={"right"}
                                    >
                                        <Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />}/>
                                        <IconButton
                                            sx={{position: "relative"}}
                                            color="primary"
                                            label="Emitir reporte"
                                            component="button"
                                            onClick={() => {}}>
                                            <PictureAsPdfIcon />
                                        </IconButton>
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <Paper
                                >
                                    <Grid container spacing={0}>
                                        <Grid item xs={11} >
                                            <Typography
                                                color="inherit"
                                                noWrap
                                                align={"center"}
                                                sx={{fontWeight: 'bold' }}>
                                                Reporte 2
                                            </Typography>
                                            <Typography
                                                color="inherit"
                                                variant="body2"
                                                align={"center"}
                                            >
                                                Descripcion
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} >
                                            <Typography>
                                                <Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />}/>
                                            </Typography>
                                            <Typography>
                                                <IconButton
                                                    color="primary"
                                                    label="Emitir reporte"
                                                    component="button"
                                                    onClick={() => {}}>
                                                    <PictureAsPdfIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <Paper
                                >

                                    <Typography
                                        color="inherit"
                                        noWrap
                                        align={"center"}
                                        sx={{fontWeight: 'bold' }}>
                                        Reporte 3
                                    </Typography>
                                    <Typography
                                        color="inherit"
                                        variant="body2"
                                        align={"center"}
                                    >
                                        Descripcion
                                    </Typography>
                                    <Typography
                                        align={"right"}
                                    >
                                        <Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />}/>
                                        <IconButton
                                            sx={{position: "relative"}}
                                            color="primary"
                                            label="Emitir reporte"
                                            component="button"
                                            onClick={() => {}}>
                                            <PictureAsPdfIcon />
                                        </IconButton>
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <Paper
                                >
                                    <Grid container spacing={0}>
                                        <Grid item xs={11} >
                                            <Typography
                                                color="inherit"
                                                noWrap
                                                align={"center"}
                                                sx={{fontWeight: 'bold' }}>
                                                Reporte 4
                                            </Typography>
                                            <Typography
                                                color="inherit"
                                                variant="body2"
                                                align={"center"}
                                            >
                                                Descripcion
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} >
                                            <Typography>
                                                <Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />}/>
                                            </Typography>
                                            <Typography>
                                                <IconButton
                                                    color="primary"
                                                    label="Emitir reporte"
                                                    component="button"
                                                    onClick={() => {}}>
                                                    <PictureAsPdfIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
export default ReportesComponent