import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import BaseOptionChart from './baseOptionalChart.js';

// ----------------------------------------------------------------------

Grafica.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartData: PropTypes.array.isRequired,
    chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function Grafica({ title, subheader, type, height, chartLabels, chartData, ...other }) {
    const chartOptions = merge(BaseOptionChart(), {
        plotOptions: {  bar: { columnWidth: '25%' },
                        radialBar: {
                            dataLabels: {
                                total: {
                                    show: true,
                                    label: 'PENDIENTE',
                                    fontSize: '16px',
                                    fontFamily: undefined,
                                    fontWeight: 600,
                                    formatter: (y) => {
                                        if (typeof y !== 'undefined') {
                                            return Math.round(y.globals.series[1] * 100 / (y.globals.series[0] + y.globals.series[1])) + '%';
                                        }
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '20px',
                                    fontFamily: undefined,
                                    fontWeight: 400,
                                    color: undefined,
                                    offsetY: 0,
                                    formatter: (y) => {
                                        if (typeof y !== 'undefined') {
                                            return `$${y.toLocaleString("ES-uy")}`;
                                        }
                                        return y
                                    }

                                }
                            }
                        },
        },
        fill: { type: chartData.map((i) => i.fill) },
        labels: chartLabels,
        xaxis: { type: 'datetime' },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `$${y.toLocaleString("ES-uy")}`;
                    }
                    return y;
                },
            },
        },
    });

    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader} />

            <Box sx={{ p: 2, pb: 1 }} dir="ltr">
                <ReactApexChart type={type} series={chartData} options={chartOptions} height={height ? height : 350} />
            </Box>
        </Card>
    );
}