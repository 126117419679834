import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import {useStateContext} from "../../../../contexts/ContextProvider";
import {Fab} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    bloquearCliente,
    desbloquearCliente,
    getClientePersona,
    getClientePrestamos
} from "../../../../store/slices/cliente.slice";
import Typography from "@mui/material/Typography";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import PersonaModificarComponent from "./persona.modificar.component";
import {TipoCliente} from "../../../../common/enum/tipoCliente";

const PersonaViewComponent = (props) => {

    let {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {open, setOpen, title, setTitle} = useStateContext();
    const {cliente} = useSelector(state => state.clientes)

    useEffect(() => {
        setTitle("Cliente");
        dispatch(getClientePersona(id)).then(()=>{}).catch(()=>{});
    },[dispatch]);

       const handleAtras = () => {
        return navigate(-1);
    }





    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                        elevation={3}>

                        {cliente && (<>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={10}  >
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    color="inherit"
                                    noWrap
                                    marginLeft="2%"
                                >
                                    <b> Modificar Cliente #</b> {cliente.id}
                                </Typography>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="#9C9897"
                                    noWrap
                                    marginLeft="2%"
                                >
                                    <b>{cliente.tipoCliente}PERSONA</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}  sm={1} >
                                <Fab sx={{mb: 0, ml: 0}}
                                     title={"Volver atras"}
                                     size = "medium"
                                     color="primary"
                                     aria-label="add"
                                     onClick={handleAtras}
                                        >
                                    <ArrowBackIcon/>
                                </Fab>
                            </Grid>
                            <Grid item xs={12} style={{paddingTop: '0px'}}  >
                                <hr />{/*LINEA HORIZONTAL*/}
                            </Grid>
                        </Grid>
                                {cliente.tipo === TipoCliente.PERSONA &&
                                    <PersonaModificarComponent />
                                }
                                {cliente.tipo === TipoCliente.EMPRESA &&
                                    <Typography>
                                        EMPRESA
                                    </Typography>
                                }
                                {cliente.tipo !== TipoCliente.PERSONA && cliente.tipo !== TipoCliente.EMPRESA &&
                                    <Typography>
                                        No existe el tipo de cliente
                                    </Typography>
                                }
                              </>
                        )}
                    </Paper>

        </Container>
    );
};

export default PersonaViewComponent;