import axios from "axios";
import {axiosError, axiosPrivate} from "../api/axios";
import authHeader from "./auth-header";



const login = (username, password) => {
    const path = "/login"
    const user = {
        username: username,
        password: password
    }
    return axiosPrivate.post(path, user)
          .then((response) => {
                  if (response.data && response.headers["x-custom-token"]) {
                      localStorage.setItem("user", JSON.stringify(response.data));
                      localStorage.setItem("token", response.headers["x-custom-token"])
                  }
            return response.data;
          }).catch((error)=>{
              let err = axiosError(error);
              if(error.response && error.response.status == 401)
                  err.message = error.response.data.mensaje;

              return Promise.reject(err);
          });
};

const cambiarContrasenia = (usuario) => {
    const path = "/usuario/modificarContrasenia";
    const config = {
        headers: authHeader(),
    };
    return axiosPrivate.put(path, usuario, config )
        .then((response) => {
            console.log(response.data)
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
};

const logout = () => {
  localStorage.clear();
};

export default {
      login,
      logout,
    cambiarContrasenia,
};