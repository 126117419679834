import {createSlice} from "@reduxjs/toolkit";
import prestamoService from "../../services/prestamo.service";
import {clearMessage, setMessage,setearMensaje} from "./snackbar.slice"
import {errores} from "../../common/errores";
import {logout} from "./autenticacion.slice";

const initialState = {
    buscarList: [],
    buscarPrestamosList: [],
    buscarClientesList :[],

}

export const buscarSlice = createSlice({
    name: "buscar",
    initialState,
    reducers: {
        setBuscarList: (state, action) => {
            console.log("accion setBuscarList")
            console.log ("setBuscarList - State: "+state.prestamosList)
            console.log("setBuscarList - payload: "+action.payload)
            state.buscarList = action.payload;
            state.buscarPrestamosList = action.payload.filter(param => param.tipo === "PRESTAMO");
            state.buscarClientesList = action.payload.filter(param => param.tipo === "CLIENTE");
        },
        buscarError: (state) => {
            state.buscarList = [];
            state.buscarClientesList = [];
            state.buscarPrestamosList = [];
        }
    }
});
const {setBuscarList, buscarError } = buscarSlice.actions;
export default buscarSlice.reducer;

export const getBuscar = (consulta) => (dispatch) => {
    return prestamoService.fetchBuscar(consulta).then(
        (data) => {
            dispatch(setBuscarList(data));
            dispatch(clearMessage());
            return Promise.resolve();
        },).catch(
        (error) => {
            console.log("ERROR DE BUSCAR");
            dispatch(buscarError())
            const mensaje = {
                message: error.message,
                severity: "error",
            }
            dispatch(setearMensaje(mensaje));

            if(error.tipo === errores.UNAUTHORIZED || error.tipo === errores.ERROR_RED || error.tipo === errores.SESION_EXPIRADA){
                console.log("logout desde fetchbuscar")
                dispatch(logout());
            }
            return Promise.reject();
        }
    );
};
