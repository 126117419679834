import axiosPrivate, {axiosError} from "../api/axios";
import authHeader from "./auth-header";

const API_URL = "http://localhost:9090/";

const emitirRecibo = (idPago) => {
    const path = "/reportes/pagos/emitirRecibo";
    const params= "?idPago=" + idPago + "&formato=PDF";
    const config = {
        headers: authHeader(),
        responseType: "blob",
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            //console.log("REPORTE service");
            //console.log(response)
            //console.log(response.headers['content-disposition'].split('filename=')[1].replaceAll('"', ""))
            const reporte = {
                data: response.data,
                type: response.headers['content-type'],
                filename: response.headers['content-disposition'].split('filename=')[1].replaceAll('"', "")
            }
            return reporte
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
const proyeccionIngresos = () => {
    const path = "/reportes/proyeccion_ingresos";
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path, config)
        .then((response) => {
            //console.log("Reporte service");
            //console.log(response.data)
            //console.log(response.data)
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err);
        });
}
const consultaSaldos = () => {
    const path = "/reportes/saldos";
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path, config)
        .then((response) => {
            console.log("Reporte service");
            console.log(response.data)
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

export default {
    emitirRecibo,
    proyeccionIngresos,
    consultaSaldos,
};