import axiosPrivate, {axiosError} from "../api/axios"
import authHeader from "./auth-header"

const fetchAllFormasPagos = () => {
    const path = "/metodo_pago/forma_pago/todas"
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err)
        });
}

const addFormaPago = (formaPago) => {
    const path = "/metodo_pago/forma_pago"
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.post(path, formaPago, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err)
        })
}

const modifFormaPago = (formaPago) => {
    const path = "/metodo_pago/forma_pago"
    const config = {
        headers: authHeader()
    };
    return axiosPrivate.put(path, formaPago, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let err = axiosError(error);
            return Promise.reject(err)
        })
}

const fetchFormaPago = (idFormaPago) => {
    const path = '/metodo_pago/forma_pago'
    const params = "?id="+idFormaPago;
    const  config = {
        headers: authHeader()
    };
    return axiosPrivate.get(path+params,config )
        .then((response) => {
            return response.data;
        })
        .catch((error)=>{
            let err = axiosError(error);
            return Promise.reject(err);
        });
}

export default {
    fetchAllFormasPagos,
    addFormaPago,
    fetchFormaPago,
    modifFormaPago
};